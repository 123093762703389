<template>
    <div>
    </div>
</template>
<script>
export default {
    props:["publicNotice",'init_status'],
    data(){
        return{
            lockReconnect:false, // 是否真正建立连接
            hearttimeout:5000, // 3秒一次心跳
            timeoutObj:null, // 心跳倒计时
            serverTimeoutObj:null, // 心跳超时倒计时
            timeoutnum:null, // 断开，重连倒计时
            timer:"",
            socket:"",
            // path:"wss://agora.lighos.com/ws",         // 轻会议测试websocket地址
            path:"wss://api.meeting.qingtv.com/ws",   // 轻会议正式websocket地址

            client_id:"",
            meetinfo:{},
            token:"",
        }
    },
    watch:{
      publicNotice(newv){
        if(newv){
          this.socket.send(JSON.stringify(this.publicNotice))
        }
      },
    },
    created(){
      this.token = window.localStorage.getItem('token') ? JSON.parse(window.localStorage.getItem('token')) : '';
      this.meetinfo = window.localStorage.getItem('meetinfo') ? JSON.parse(window.localStorage.getItem('meetinfo')) : '';
    },
    mounted(){
      this.init();
    },
    methods:{
    // websocket的方法 
      init(){
          if(typeof(WebSocket)==="undefined"){
              alert("您的浏览器不支持socket");
          }else{
              let _this=this
              _this.socket = new WebSocket(_this.path); // 实例化 websocket
              _this.socket.onopen = function(){
                  console.log("socket连接成功")
                  _this.timer = setInterval(()=>{
                      let obj = { type:"pc_ping" }
                     _this.socket.send(JSON.stringify(obj));
                  },10000)
              }; //监听socket连接
              _this.socket.onerror = function(){
                    _this.reconnect();
              };
              _this.socket.onclose = function (e){
                      // _this.reconnect();
                      // console.log('websocket close')
              }
              _this.socket.onmessage = _this.getMessage; //接受服务器返回的数据
          }
      },
      //  websocket重连
      reconnect(){
          let that = this;
          if(that.lockReconnect){
                return
          };
          that.lockReconnect = true;
          // 一直重连，设置重连延迟避免请求过多
          that.timeoutnum && clearTimeout(that.timeoutnum);
          that.timeoutnum = setTimeout(()=>{
              that.init();
              that.lockReconnect = false;
          })
      },
      // 接收服务器消息
      getMessage(msg){
          let data = JSON.parse(msg.data);
          switch(data.type){
            case "onConnect":  // 加入
              this.$emit("client_id",data.client_id);
              this.client_id = data.client_id;
            return;
            case "web_cast_join":  // 加入
            return;
            case "videoTrack_play":   // 开启摄像头
              this.$emit("scoket_videoTrack_play",data.data);
            return;
            case "videoTrack_setEnabled": // 关闭摄像头
              this.$emit("scoket_videoTrack_setEnabled",data.data);
            return;
            case "audioTrack_setEnabled_true": //开启麦克风
              this.$emit("scoket_audioTrack_setEnabled_true",data.data);
            return;
            case "audioTrack_setEnabled_false": //关闭麦克风
              this.$emit("scoket_audioTrack_setEnabled_false",data.data);
            return;
            case "audioTrack_open_consent": // 同意开启麦克风
              this.$emit("scoket_audioTrack_open_consent",data.data);
            return;
            case "audioTrack_open_refuse": // 拒绝开启麦克风
              this.$emit("scoket_audioTrack_open_refuse",data.data);
            return;
            case "videoTrack_open_consent":  // 同意开启摄像头
            this.$emit("scoket_videoTrack_open_consent",data.data);
            return;
            case "videoTrack_open_refuse": // 拒绝开启摄像头
              this.$emit("scoket_videoTrack_open_refuse",data.data);
            return;
            case "main_screen_true": // 主屏显示
              this.$emit("scoket_main_screen_true",data.data);
            return;
            case "main_screen_false": // 还原显示
              this.$emit("scoket_main_screen_false",data.data);
            return;
            case "web_meeting_share": // 屏幕共享
              this.$emit("scoket_web_meeting_share",data.message);
            return;
            case "web_meeting_share_out": // 取消共享
              this.$emit("scoket_web_meeting_share_out",data.message);
            return;
            case "set_live_status":   // 设为直播画面
              this.$emit("scoket_set_live_status",data.data);
            return;
            case "remove_participants":// 移除参会者
              this.$emit("scoket_remove_participants",data.data);
            return;
            case "over_live":         //  主持人结束直播
              this.$emit("scoket_over_live")
            return;
            case "waiting":         //  进入等待室，等待主持同意
              this.$emit("scoket_waiting",data.message);
            return;
            case "waiting_allow":         //  主持同意等待室入会
            console.log(data)
              this.$emit("scoket_waiting_allow",data.message);
            return;
            case "waiting_remove":         //  主持移除等待室人员
              this.$emit("scoket_waiting_remove",data.message);
            return;
          }
      },
    },
    // 页面销毁时调用
    destroyed(){
        this.socket.close()
        clearInterval(this.timer)      // 页面销毁时清除 ping 定时器
        clearInterval(this.timeoutnum) // 断开，重连倒计时
        clearInterval(this.timeoutObj) // 心跳倒计时
    }
}
</script>