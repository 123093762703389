<template>
  <!-- onContextMenu="return false" onSelectStart="return false"   禁止鼠标右键 -->
  <div class="home" @click="cancel($event)" onContextMenu="return false" onSelectStart="return false" v-loading="shareloading || viewLoading"
    element-loading-background="rgba(255, 255, 255, 0.3)"
    :element-loading-text="shareloading && uid != this.share_uid? share_uesrname + shareText: viewLoading? device_switch_text: ''">
    <div class="home-header" v-show="!fullScreenMode">
      <div class="flex" style="justify-content: space-between;">
        <p class="flex" style="width:320px;">
          会议号：{{ authInfo.meeting_id }}
          <img class="head-share-icon" id="share-channel-btn" @click="shareChannelShow = !shareChannelShow"
            :src="  shareChannelShow ? require('../assets/SVG/active_fenxiang.svg') : require('../assets/SVG/fenxiang.svg') " />
        </p>
        <p>{{ authInfo.meeting_title }}</p>
        <div class="flex" style="justify-content: flex-end;width:320px;height:45px;overflow: hidden;" >
          <transition name="headerRightfade">
            <div class="flex" v-show="headerRightShow">
              <!-- <p><span style="padding-right:10px;">LIVE：00:00:00</span> <span>REC：00:00:00</span></p> -->
              <p class="wlyc">
                网络延迟：<span>{{ RTCStats.RTT ? RTCStats.RTT : 0 }} ms</span>
              </p>
              <!-- <p>丢包率：0%</p> -->
              <div class="head-r">
                <p class="flex">
                  <img src="../assets/netUp.svg" /><span >{{ RTCStats.SendBitrate ? parseInt(RTCStats.SendBitrate / 1000) : 0 }} kbps</span>
                </p>
                <p class="flex">
                  <img src="../assets/netDown.svg" /><span>{{ RTCStats.RecvBitrate ? parseInt(RTCStats.RecvBitrate / 1000) : 0 }} kbps</span>
                </p>
              </div>
            </div>
          </transition>
          <img class="head-r-hint" @click="headerRightShow = !headerRightShow" :src=" !headerRightShow ? require('../assets/icon/hint.svg') : require('../assets/icon/hint_active.svg') " />
        </div>
      </div>
    </div>
    <el-collapse-transition>
      <div id="subscribe-Video" v-show="!fullScreenMode" :class="{ homeTopShow: 'home-top', homeRightShow: 'home-right' }"
        :style="{ width: attendeesDomShow ? 'calc(100% - 310px)' : '',margin: attendeesDomShow ? '0 0 0 310px' : ''}">
        <subscribeVideo
          :channel_id="options.channel"
          :subscribeUser="subscribeUser"
          :homeRightShow="homeRightShow"
          :homeGalleryShow="homeGalleryShow"
          :homeTopShow="homeTopShow"
          :screenUid="screenUid"
          :fixedUid="fixedUid"
          :fullScreenUid="fullScreenUid"
          :openAudioUid="openAudioUid"
          :closeAudioUid="closeAudioUid"
          :openVideoUid="openVideoUid"
          :closeVideoUid="closeVideoUid"
          :largeVolumeUid="largeVolumeUid"
          :liveUid="liveUid"
          :liveStatus="liveStatus"
          :adminType="meetinfo.identity"
          :uid="uid"
          :userList="userList"
          :share_uid="share_uid"
          :streamShareUid="streamShareUid"
          :isShare="isShare"
          :attendeesDomShow="attendeesDomShow"
          :screenWidth="screenWidth"
          :screenHeight="screenHeight"
          :subscribeSet="subscribeSet"
          @set_live_status="set_live_status"
          @subscribeSet="getsubscribeSet"
          @publicNotice="getpublicNotice"
          @setScreenFocus="setScreenFocus"
          @setScreenOut="setScreenOut"
        ></subscribeVideo>
      </div>
    </el-collapse-transition>
    <div class="home-bottom" id="homeBottom"
      :style="{ height: fullScreenMode ? screenHeight + 'px' : homeTopShow ? 'calc(100% - 205px)' : 
      subscribeUser.length == 0 ? 'calc(100% - 45px)' : 'calc(100% - 45px)',
      width: fullScreenMode ? screenWidth + 'px' : homeRightShow ? 'calc(100% - 290px)' : '' }"
      @dblclick="isShare && fullScreenMode ? unfullScreen() : fullScreenMode ? setScreenOut(subscribeUser[fullScreenIndex], 2) : '' "
    >
      <div id="videoMain" class="video-main"
        :style="{
          width: videoMainWidth + 'px',
          height: videoMainHeight + 'px',
          margin:
          attendeesDomShow && homeRightShow && !fullScreenMode
            ? '-2px  auto  0 310px'
            : homeRightShow
            ? '-2px  auto 0'
            : attendeesDomShow && !fullScreenMode
            ? '-2px  auto  0 310px'
            : subscribeUser.length == 0
            ? '0  auto 0px'
            : ''
        }"
      >
      <!-- attendeesDomShow ? '320px' :  -->
        <div class="share-uesrname" :style="{ left: '10px' }" 
          v-if="isShare == 1 && !shareloading && isShowShareName">
          <span>{{ share_uesrname }}的共享</span>
          <span style="margin:0 10px;" v-if="streamShareUid != uid && meetinfo.identity == 1">|</span>
          <el-tooltip content="关闭他的共享" placement="bottom" effect="light" :open-delay="200">
          <span style="cursor: pointer;" v-if="streamShareUid != uid && meetinfo.identity == 1" @click="cancel_screen_sharing(1)"><img style="width:16px;" src="../assets/SVG/closeShare.png" alt=""></span>
          </el-tooltip>
        </div>
        <div class="live-status" style="right:10px;" v-if="liveStatus == 1 && screenUid == '' && fixedUid == '' && !isShare" @click="setLiveCoverage(uid)" >
          <img :src=" localLiveStatus == 1 ? require('../assets/SVG/hong.svg') : require('../assets/SVG/bai.svg') "/>
        </div>
        <p class="videos-bg-name">{{ meetinfo.name }}</p>
        <div
          class="full-screen-btn"
          v-show="homeTopShow || homeRightShow"
          v-if="!fullScreenMode"
          @click="(fullScreenMode = true), fullScreen()"
        ></div>
        <div
          class="unfull-screen-btn"
          v-show="homeTopShow || homeRightShow"
          v-else
          @click="isShare ? unfullScreen() : setScreenOut(subscribeUser[fullScreenIndex], 2)"
        ></div>
      </div>
    </div>
    
    <div class="home-fix" v-show="!fullScreenMode"
      @mouseover="menuStatusShow == 1 ? controlField = true : ''"
      @mouseenter="menuStatusShow == 1 ? fixMouseEnter() : ''"
      @mouseleave="menuStatusShow == 1 ? fixMouseLeave() : controlField = true"
      :style="{ width: homeRightShow ? 'calc(100% - 290px)' : '', zIndex: homeGalleryShow ? 2222 : '' }"
    >
      <transition name="controlFieldfade">
        <div class="home-fix-center" v-show="controlField">
          <el-tooltip content="摄像头" placement="bottom" effect="light" :open-delay="200">
            <div v-if="role != 'watch'" id="camera-list-btn">
              <div class="icon-count">
                <!-- cameraListDomShow ? require('../assets/SVG/active_camera.svg') :  -->
                <img class="icon-img" @click="openCloseView()" :src=" localCameraShow ? require('../assets/SVG/cameraOpen.svg') : require('../assets/SVG/cameraClose.svg') " />
                <img style="float: right;width:10px;height: 100%;margin-top: 20px;"
                  @click=" (cameraListDomShow = !cameraListDomShow), (MicListDomShow = leaveMeetShow = viewDomShow = false)"
                  :src=" !cameraListDomShow ? require('../assets/SVG/up.svg') : require('../assets/SVG/active_up.svg') "
                />
              </div>
              <!-- <div class="icon-txt" style='padding-right:8px'>摄像头</div> -->
            </div>
          </el-tooltip>
          <div class="center-line"></div>
          <el-tooltip content="麦克风" placement="bottom" effect="light" :open-delay="200">
            <div style="text-align: center;" id="mic-list-btn" v-if="role != 'watch'" >
              <div class="icon-count">
                <!-- MicListDomShow ? require('../assets/SVG/active_Mic.svg') :  -->
                <img class="icon-img" style="height: 22px;margin-top: 12px" @click="openCloseAudio()"
                  :src=" localAudioShow ? homeVolumeLevel <= 1
                  ? require('../assets/SVG/1.svg')
                  : homeVolumeLevel <= 2
                  ? require('../assets/SVG/2.svg')
                  : homeVolumeLevel <= 3
                  ? require('../assets/SVG/3.svg')
                  : homeVolumeLevel <= 4
                  ? require('../assets/SVG/4.svg')
                  : homeVolumeLevel <= 5
                  ? require('../assets/SVG/5.svg')
                  : homeVolumeLevel <= 6
                  ? require('../assets/SVG/6.svg')
                  : homeVolumeLevel <= 7
                  ? require('../assets/SVG/7.svg')
                  : homeVolumeLevel <= 8
                  ? require('../assets/SVG/8.svg')
                  : homeVolumeLevel <= 9
                  ? require('../assets/SVG/9.svg')
                  : homeVolumeLevel >= 10
                  ? require('../assets/SVG/10.svg')
                  : require('../assets/SVG/MicOpen.svg')
                  : require('../assets/SVG/MicClose.svg')"
                />
                <img style="float: right;width:10px;height: 100%;margin-top: 19px;margin-right: 5px"
                  @click=" (MicListDomShow = !MicListDomShow), (cameraListDomShow = leaveMeetShow = viewDomShow = false)"
                  :src=" !MicListDomShow ? require('../assets/SVG/up.svg') : require('../assets/SVG/active_up.svg')"
                />
              </div>
              <!-- <div class="icon-txt" style='padding-right:14px;'>麦克风</div> -->
            </div>
          </el-tooltip>
          <div class="center-line"></div>
          <el-tooltip content="参会者" placement="bottom" effect="light" :open-delay="200">
            <div style="text-align: center;" @click="participantClick(),get_participant_list(1),participant_waiting_room(1)">
              <div class="icon-count">
                <img class="icon-img" style="margin-left: 2px"
                  :src=" attendeesDomShow ? require('../assets/SVG/active_peples.svg') : require('../assets/SVG/peples.svg') "
                />
              </div>
              <!-- <div class="icon-txt" :style="attendeesDomShow ? 'color:#0099ff;' : ''">参会者</div> -->
            </div>
          </el-tooltip>
          <div class="center-line"></div>
          <el-tooltip :content="ScreenShare ? '停止共享' : '屏幕共享'" placement="bottom" effect="light" :open-delay="200" >
            <div style="text-align: center;" v-if="role != 'watch'"
              @click="streamShareUid != uid && isShare  ? '' : !ScreenShare ? createScreenTrack() : cancel_screen_sharing(1) " >
              <div class="icon-count">
                <img class="icon-img" style="margin-left: 7px" :src="streamShareUid != uid && isShare  ? require('../assets/SVG/closeShare.png') : ScreenShare ? require('../assets/SVG/active_share.svg') : require('../assets/SVG/share.svg') "/>
              </div>
              <!-- <div class="icon-txt" :style="ScreenShare ? 'color:#0099ff;padding-left:7px' : 'padding-left:7px'">{{ ScreenShare ? "停止共享" : "屏幕共享"}}</div> -->
            </div>
          </el-tooltip>
          <div class="center-line"></div>
          <el-tooltip content="设置" placement="bottom" effect="light" :open-delay="200">
            <div style="text-align: center;" @click="setDialogVisible = !setDialogVisible" >
              <div class="icon-count">
                <img class="icon-img" :src=" setDialogVisible ? require('../assets/SVG/active_set.svg') : require('../assets/SVG/set.svg')" />
              </div>
              <!-- <div class="icon-txt" :style="setDialogVisible ? 'color:#0099ff;' : ''">设置</div> -->
            </div>
          </el-tooltip>
          <div class="center-line"></div>
          <el-tooltip content="视图模式" placement="bottom" effect="light" :open-delay='200'>
          <div style="text-align: center;" id="view-list-btn" @click="isShare ? '' : homeTopShow ? qiehuan(3) : qiehuan(1),MicListDomShow = cameraListDomShow = leaveMeetShow = false">
            <div class="icon-count">
              <img class="icon-img"  :src="isShare ? require('../assets/SVG/jin_view.svg') : homeTopShow? require('../assets/SVG/top_view.svg') : require('../assets/SVG/view.svg')" />
            </div>
            <!-- <div class="icon-txt" :style="viewDomShow ? 'color:#0099ff;' : ''">视图模式</div> -->
          </div>
          </el-tooltip>
          <div class="center-line"></div>
          <el-tooltip :content="live_btn_text" placement="bottom" effect="light"  :open-delay="200" >
            <div style="text-align: center;" v-if="meetinfo.identity == 1" @click="liveing()" >
              <div class="icon-count">
                <img class="icon-img" :src=" live_btn_text == '开始直播' ? require('../assets/SVG/live.svg') : require('../assets/SVG/active_live.svg') " />
              </div>
              <!-- <div class="icon-txt" :style="live_btn_text == '开始直播' ? '' : 'color:#0099ff;'">{{live_btn_text}}</div> -->
            </div>
          </el-tooltip>
          <div class="center-line" v-if="meetinfo.identity == 1"></div>
          <el-tooltip content="退出" placement="bottom" effect="light" :open-delay="200">
            <div style="text-align: center;" id="leave-sel-btn"
              @click=" meetinfo.identity == 1 ? (leaveMeetShow = !leaveMeetShow) : leaveMeeting(), 
              (cameraListDomShow = MicListDomShow = viewDomShow = false)"
            >
              <div class="icon-count">
                <img class="icon-img" :src=" leaveMeetShow ? require('../assets/SVG/active_exit.svg') : require('../assets/SVG/exit.svg') " />
              </div>
              <!-- <div class="icon-txt" :style="leaveMeetShow ? 'color:#0099ff;' : ''">退出</div> -->
            </div>
          </el-tooltip>
          <!--    摄像头列表选择开始 deviceId-->
          <el-collapse-transition>
            <div class="cameraListDom camera-dom" id="camera-list" v-show="cameraListDomShow" style="left:0%;" >
              <ul>
                <li :class=" currentCameraDeviceId == item.deviceId ? 'active' : '' "
                  v-for="(item, index) in Cameras" :key="index"
                  @click="setCurrentCameraDeviceId(item.deviceId)"
                >
                  {{ item.label }}
                </li>
              </ul>
              <div class="popper__arrow" style="right:82.5%;"></div>
            </div>
          </el-collapse-transition>
          <!--    摄像头列表选择结束-->

          <!--    麦克风列表选择开始-->
          <el-collapse-transition>
            <div class="cameraListDom camera-dom" id="mic-list" v-show="MicListDomShow" style="left:2%;" >
              <ul>
                <li :class="currentMicDeviceId == item.deviceId ? 'active' : ''" v-for="(item, index) in Microphones" :key="index" @click="setCurrentMicDeviceId(item.deviceId)" >
                  {{ item.label }}
                </li>
              </ul>
              <div class="popper__arrow" style="right:61%;"></div>
            </div>
          </el-collapse-transition>
          <!--    麦克风列表选择结束-->

          <!--    退出选择-->
          <el-collapse-transition>
            <div class="leave-select" id="leave-sel" v-show="leaveMeetShow" style="left:62%;" >
              <p style="font-size: 18px;margin-top:30px;">退出活动</p>
              <button style="background: #1C2C3B;" @click="out_meeting('leave')"> 离开 </button>
              <button style="background: #EF5350;" @click="out_meeting('over')"> 结束 </button>
              <div class="popper__arrow"></div>
            </div>
          </el-collapse-transition>
          <!--   退出选择结束 -->

          <!--   视图模式选择 -->
          <!-- <el-collapse-transition>
            <div class="view-select-dom" id="view-list" v-show="viewDomShow" :style="meetinfo.identity == 1 ? 'left:51%' : 'left:60%'" >
              <div @click="qiehuan(3)">
                <p>画廊视图</p>
                <img src="../assets/SVG/hlview.svg" alt="" />
              </div>
              <div @click="qiehuan(1)">
                <p>主讲模式1</p>
                <img src="../assets/SVG/topview.svg" alt="" />
              </div>
              <div @click="qiehuan(2)">
                <p>主讲模式2</p>
                <img src="../assets/SVG/rightview.svg" alt="" />
              </div>
              <div class="popper__arrow"></div>
            </div>
          </el-collapse-transition> -->
          <!--   视图选择结束 -->
        </div>
      </transition>
    </div>
    <img v-if="userList_left != 0" v-show="!fullScreenMode" src="../assets/SVG/side_push.svg"
      :style=" userList_left < 0 ? 'position: absolute;top:45%;cursor: pointer;left:0px;transform:rotate(180deg);' : '' "
      @click=" userList_left == 0 ? (userList_left = -310) : (userList_left = 0),attendeesDomShow = !attendeesDomShow"
    />
    <!--    参会者列表选择开始 -->
    <transition name="userListfade">
      <div class="cameraListDom" :style="{ height: 'calc(100% - 45px)' }" v-show="attendeesDomShow && !fullScreenMode">
        <div class="cameraListDom-top">
          <p>参会者（{{ meetinfo.identity == 1 ? attendeeTotal + waiting_room_total : attendeeTotal }}）</p>
          <span @click=" (attendeesDomShow = false), (user_list_page = 1), jsvideoMainWidth()">x</span>
        </div>
        <div class="cameraListDom-content" v-infinite-scroll="userlistScroll" ref="scroll" >
          <ul v-show="meetinfo.identity == 1 && meetinfo.limit_type == 3" :style="waiting_room_total == 0 || !waiting_room_total ? 'height:0;' : 'height:30%;'">
            <li style="color:#fff;font-size:16px;">等待室({{waiting_room_total ? waiting_room_total : 0 }})</li>
            <li class="user-item flex" v-for="(item,index) in waiting_room_list" :key="index">
              <p class="user-header" :style="{ background: item.bg_color }">{{ item.name_sub }}</p>
              <p class="user-name">{{item.name}}</p>
              <p class="flex handle">
                <span style="color:#0099FF;" @click="waiting_room_adopt(item.id)">准入</span>
                <span style="color:#DD2424;" @click="waiting_room_remove(item.id)">移除</span>
              </p>
            </li>
          </ul>
          <img class="side_push" src="../assets/SVG/side_push.svg"
            :style=" userList_left < 0 ? 'right:-13px;transform:rotate(180deg);' : '' "
            @click=" userList_left == 0 ? (userList_left = -310) : (userList_left = 0), attendeesDomShow = !attendeesDomShow,jsvideoMainWidth()" />
          <ul :style="meetinfo.identity == 1 && meetinfo.limit_type == 3 ? waiting_room_total > 0 || waiting_room_total ? 'height:65%' : 'height:95%;' : 'height:95%;'">
            <li style="color:#fff;font-size:16px;">会议中({{attendeeTotal}})</li>
            <li class="user-item flex" v-for="(item, index) in userList" :key="index" v-show="item.uid != share_uid" >
              <p class="user-header" :style="{ background: item.bg_color }">{{ item.name_sub }}</p>
              <p class="user-name">
                <span v-if="item.admin == 1" style="background:#0099ff;">主持</span>
                <span v-if="item.admin == 2" style="background:#00cc99;">嘉宾</span>
                {{ item.username }}
              </p>
              <p class="flex user-set">
                <span v-show=" liveStatus == 1 && meetinfo.identity == 1 && liveUid == item.uid " style="color:#ef5350;" >LIVE</span >
                <img style="width: 22px;margin:0 6px;"
                  v-if=" meetinfo.identity == 1 ? true : item.admin == 2 && item.uid == uid ? true : false "
                  @click="openCloseRemoteView(item, index)"
                  :src=" userListSet[index].VideoShow == 1 ? require('../assets/SVG/cameraOpen.svg') : require('../assets/SVG/cameraClose.svg')"
                />
                <img style="width: 13px;margin:0 6px;" v-if=" meetinfo.identity == 1 ? true : item.admin == 2 && item.uid == uid ? true : false"
                  @click="openCloseRemoteAudio(item, index)"
                  :src=" userListSet[index].AudioShow == 1 ? require('../assets/SVG/MicOpen.svg') : require('../assets/SVG/MicClose.svg') "
                />
                <!-- <img style="width: 18px;margin:0 6px;"  :src="userListSet[index].Hands == 1 ? require('../assets/SVG/hand.svg') : require('../assets/SVG/closehand.svg')" /> -->
                <img style="width: 15px;margin:0 6px;" :id="`userlist-more-dialog-btn-${index}`"
                  v-if=" meetinfo.identity == 1 ? true : item.admin == 2 && item.uid == uid ? true : false "
                  @click="moreSet(item, index)"
                  :src=" !userListSet[index].More ? require('../assets/SVG/more.svg') : require('../assets/SVG/active_more.svg')"
                />
              </p>
              <transition name="morefade">
                <div class="more-dialog" :id="`userlist-more-dialog-${index}`" v-show="userListSet[index].More">
                  <p v-if=" meetinfo.identity == 1 ? true : item.admin == 2 && item.uid == uid ? true : false " @click="openCloseRemoteView(item, index)">
                    <span v-if="userListSet[index].VideoShow != 1">开启</span><span v-else>关闭</span>摄像头
                  </p>
                  <p v-if=" meetinfo.identity == 1 ? true : item.admin == 2 && item.uid == uid ? true : false " @click="openCloseRemoteAudio(item, index)" >
                    <span v-if="userListSet[index].AudioShow != 1">开启</span><span v-else>关闭</span>麦克风
                  </p>
                  <p v-if="meetinfo.identity == 1 && item.uid != uid" @click="removeRemoteParticipants(item, index)" > 移除参会者 </p>
                  <p v-if="meetinfo.identity == 1" @click="setLiveCoverage(item.uid, index)" >
                    <span v-if="userListSet[index].live == 1">取消</span><span v-else>设为</span>直播
                  </p>
                  <!-- <p v-if="meetinfo.identity == 1 && item.uid != uid">设为主持人</p>
                  <p v-if="meetinfo.identity == 1 && item.uid != uid">设为观众</p> -->
                  <p v-show="isShare != 1" v-if="meetinfo.identity == 1" @click="mainRemoteScreenTrue(item, index)" >
                    <span v-if="userListSet[index].BlowUp == 0">取消</span>焦点视图
                  </p>
                  <p v-show="isShare != 1  && item.uid != uid" v-if=" meetinfo.identity != 1 ? true : item.admin == 2 && item.uid != uid ? true : false "
                    @click=" fixedUid != item.uid ? setScreenFocus(item, 1) : setScreenOut(item, 1) ">
                    <span v-if="fixedUid == item.uid">取消</span>选定视图
                  </p>
                  <p v-show="isShare != 1 && item.uid != uid && !homeGalleryShow" v-if=" meetinfo.identity != 1 ? true : item.admin == 2 && item.uid != uid ? true : false"
                    @click=" fullScreenUid != item.uid ? setScreenFocus(item, 2) : setScreenOut(item, 1) ">
                    <span v-if="fullScreenUid == item.uid">取消</span>全屏显示
                  </p>
                  <div class="popper__arrow"></div>
                </div>
              </transition>
            </li>
          </ul>
        </div>
      </div>
    </transition>
    <!--    参会者列表选择结束-->

    <!--    分享窗口 -->
    <!-- <el-collapse-transition> -->
    <transition name="sharefade">
      <div class="shareChannelDom fadeIn" id="share-channel" v-if="shareChannelShow"  v-show="!fullScreenMode" >
        <p>会议名称：<span>{{ authInfo.meeting_title }}</span></p>
        <p>会议时间：<span>{{ authInfo.start_at }}</span></p>
        <p>会议号码：<span>{{ authInfo.meeting_id }}</span></p>
        <p>会议密码：<span>{{ authInfo.guest_pwd }}</span></p>
        <p>嘉宾链接：<span>{{ authInfo.att_link }}</span></p>
        <!-- <p>观看地址：<span>{{ authInfo.watch_url }}</span></p>
        <div style="display:flex;margin-left:21px;">
          <span style="width:75px;">扫码观看：</span>
          <span style="width:100px;height:100px;background:#fff;">
            <qriously :value="authInfo.watch_url" :size="100" />
          </span>
        </div> -->
        <button class="copy-info" style="cursor: pointer;"
          :data-clipboard-text="
            '会议名称：' +
            authInfo.meeting_title +
            '\r\n会议时间：' +
            authInfo.start_at +
            '\r\n会议号码：' +
            authInfo.meeting_id +
            '\r\n会议密码：' +
            authInfo.guest_pwd +
            '\r\n嘉宾链接：' +
            authInfo.att_link
          "
          :disabled="copyTimeNum != 0"
          :style="copyTimeNum != 0 ? 'background:#ccc;color:#fff' : ''"
          @click="copyInfo"
        ><span v-if="copyTimeNum == 0">复制链接</span><span v-else>{{copyTimeNum}}s</span></button>
        <!-- '\r\n观看地址：' +
            authInfo.att_link -->
      </div>
    </transition>
    <!-- </el-collapse-transition> -->
    <!--   分享窗口结束 -->

    <div class="set-dia">
      <el-dialog
        title="提示"
        :visible.sync="setDialogVisible"
        :destroy-on-close="false"
        top="25vh"
        width="720px"
        @closed="setDialogVisible = false"
      >
        <setOptions
          :setDialogVisible="setDialogVisible"
          :setRtc="setRtc"
          :adminType="meetinfo.identity"
          @changeLiveSet="getchangeLiveSet"
          @closeSetDialog="closeSetDialog"
        ></setOptions>
      </el-dialog>
    </div>

    <!-- 绑定轻直播弹窗 -->
    <div>
      <el-dialog title="轻直播" width="550px"  top="25vh" :visible.sync="dialogStudioVisible">
        <div v-if="!bindingShow">
          <p style="font-size:18px;margin-top:0;">频道id：</p>
          <el-input v-model="studio_id" placeholder="请输入频道id"></el-input>
          <div slot="footer" class="dialog-footer" style="display: flex;justify-content: flex-end;margin-top:20px;">
            <el-button @click="dialogStudioVisible = false">取 消</el-button>
            <el-button type="primary" @click="get_live_studio_push_url()">确 定</el-button>
          </div>
        </div>
        <section class="bind_account_wrap" v-else>
          <div class="bind_account_main">
            <div class="bind_account_main_progress">
              <div v-for="(item,index) in progressTitle" :key="item.component" :class="index<=nowProgress? 'bamp_node  pass': 'bamp_node'">
                <div class="bamp_serialnumber">{{ index + 1 }}</div>
                <span class="bamp_title">{{ item.title }}</span>
              </div>
              <div class="bamp_line" />
            </div>
            <div class="bind_account_main_form">
              <div v-if="progressTitle[nowProgress].component === 'success'">
                <div class="bamf_success_wrap">
                  <i class="bamf_success_wrap_icon el-icon-success" />
                  <p class="bamf_success_wrap_text">绑定成功</p>
                </div>
              </div>
              <el-form v-else ref="ruleForm" :rules="rules" :model="ruleForm" label-width="130px" class="demo-ruleForm">
                <template v-if="progressTitle[nowProgress].component === 'phone'">
                  <div>
                    <el-form-item label="确认手机号：" prop="mobile" class="bamf_region_select">
                      <el-select v-model="ruleForm.region" class="bamf_region_number">
                        <el-option label="+86" value="86" />
                      </el-select>
                      <el-input style="width:67%" v-model.trim="ruleForm.mobile" class="bamf_region_phone" placeholder="请输入11位数字号码" />

                      <el-button @click="sendMsg" class="bamf_region_phone_sub" type="text">{{ codetext }}</el-button>
                    </el-form-item>
                    <el-form-item label="验证码输入：" prop="code">
                      <el-input style="width:90%" v-model="ruleForm.code" />
                    </el-form-item>
                  </div>
                </template>
                <template v-else-if="progressTitle[nowProgress].component === 'password'">
                  <div>
                    <el-form-item label="昵称:">
                      <el-input v-model.trim="userInfo.name" disabled />
                    </el-form-item>
                    <el-form-item label="邮箱:">
                      <el-input v-model.trim="userInfo.email" disabled />
                    </el-form-item>
                    <el-form-item label="手机号:">
                      <el-input v-model.trim="userInfo.mobile" disabled />
                    </el-form-item>
                    <el-form-item label="企业/机构名称：">
                      <el-input v-model.trim="userInfo.company" disabled />
                    </el-form-item>
                  </div>
                </template>
                <el-form-item class="bamf_submit">
                  <el-button
                    @click="lastTo"
                    v-show="progressTitle[nowProgress].component === 'password'"
                    v-loading.fullscreen.lock="loadding"
                    type="primary"
                  >上一步</el-button>
                  <el-button
                    @click="submitForm"
                    v-loading.fullscreen.lock="loadding"
                    type="primary"
                  >下一步</el-button>
                </el-form-item>

              </el-form>
            </div>
          </div>
        </section>
      </el-dialog>
    </div>
    <broadcast
      :publicNotice="publicNotice"
      @client_id="getClientId"
      @scoket_videoTrack_play="scoket_videoTrack_play"
      @scoket_videoTrack_setEnabled="scoket_videoTrack_setEnabled"
      @scoket_audioTrack_setEnabled_true="scoket_audioTrack_setEnabled_true"
      @scoket_audioTrack_setEnabled_false="scoket_audioTrack_setEnabled_false"
      @scoket_audioTrack_open_consent="scoket_audioTrack_open_consent"
      @scoket_audioTrack_open_refuse="scoket_audioTrack_open_refuse"
      @scoket_videoTrack_open_consent="scoket_videoTrack_open_consent"
      @scoket_videoTrack_open_refuse="scoket_videoTrack_open_refuse"
      @scoket_main_screen_true="scoket_main_screen_true"
      @scoket_main_screen_false="scoket_main_screen_false"
      @scoket_web_meeting_share="scoket_web_meeting_share"
      @scoket_web_meeting_share_out="scoket_web_meeting_share_out"
      @scoket_remove_participants="scoket_remove_participants"
      @scoket_over_live="scoket_over_live"
      @scoket_waiting="scoket_waiting"
    ></broadcast>
  </div>
</template>

<script>
import { mapState } from "vuex";
import merge from "webpack-merge";
import Clipboard from "clipboard"; // 复制
import AgoraRTC from "agora-rtc-sdk-ng";
import subscribeVideo from "../components/subscribeVideo.vue";
import LiveTranscoding from "../js/liveConfig.js";
import liveUserConfig from "../js/liveUserConfig.js";
import broadcast from "../components/broadcast";
import setOptions from "../components/setOptions";
export default {
  name: "Home",
  computed: {
    ...mapState(["browserCompatibility"])
  },
  data() {
    return {
      /** 绑定轻直播 */
      bindingShow:false,
      ruleForm: {
        region: '86',
        mobile: '',
        code: ''
      },
      codebtnshake: false,
      countdown: null,
      codetext: '发送验证码',
      userInfo: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        company: ''
      },
      progressTitle: [
        {
          title: '填写手机号码',
          isPass: true,
          component: 'phone'
        },
        {
          title: '确认信息',
          isPass: false,
          component: 'password'
        },
        {
          title: '完成',
          isPass: false,
          component: 'success'
        }
      ],
      nowProgress: 0,
      loadding: false,
      rules: {
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码'
          }
        ]
      },
      /** */
      headerRightShow: false, // header右侧信息显示
      fullScreenMode: false, // 是否全屏显示
      viewLoading: true, // 页面loading
      videoMainWidth: 0, //主要视频区域的宽度
      videoMainHeight: 0, //主要视频区域的高度
      screenWidth: 0, //浏览器宽度
      screenHeight: 0, //浏览器高度
      homeTopShow: false, //是否显示头部区域（远端用户区域）
      homeRightShow: false,
      homeGalleryShow: false,
      rtc: {
        client: null, //用来放置本地客户端
        audioTrack: null, //用来放置本地音频轨道
        videoTrack: null //用来放置本地视频轨道
      },
      setRtc: {},
      options: {
        appId: "03e71cc59f4c4fc2964b9dade06a7eab", //替换自己项目的appId 
        channel: "", //传入目标频道名
        token:"", //token鉴权
        role: "host", //频道角色 可设为 "audience" 观众不可以发送音视频 或 "host" 主播可以发送音视频
        uid: ""
      },
      Cameras: [], //本地客户端摄像头列表
      Microphones: [], //本地客户端麦克风列表
      PlaybackDevices: [], //本地客户端播放设备列表
      device_switch_text: "", //设备切换提示文字
      uid: null, //本地用户的uid
      localCameraShow: true, //本地摄像头的现实状态
      localAudioShow: true, //本地音频的现实状态
      cameraListShow: false, //底部摄像头上拉列表现实
      MicListShow: false, //底部麦克风上拉列表现实
      viewDomShow: false, //底部视图模式选择
      setDialogVisible: false, //设置弹窗
      subscribeUser: [], //订阅用户数组
      joinStatus: null, //是否加入成功
      dialogStudioVisible:false,
      studio_id:"",
      pushRtmp: "", //推流地址
      cameraListDomShow: false, //显示摄像头列表状态
      MicListDomShow: false, //显示麦克风列表状态
      leaveMeetShow: false, //显示退出弹窗状态
      attendeesDomShow: false, //参会者列表
      attendeeTotal:0,
      shareChannelShow: false, //分享频道弹窗
      isLive: false, //是否是直播状态
      RTCStats: {}, //获取当前通话的统计信息
      currentCameraDeviceId: "", //当前使用的摄像头设备ID
      currentMicDeviceId: "", //当前使用的麦克风设备ID
      currentPlaybackDevices: "", //当前使用的扬声器设备ID
      ScreenShare: false, //屏幕共享状态
      screenClient: null, //共享用户客户端
      localScreenTrack: null, //共享屏幕视频轨道
      role: "host", //用户权限
      meetinfo: {},
      token: "",
      publicNotice: {}, //公共广播
      screenUid: "", //主屏显示uid
      fixedUid: "", //本地焦点视图uid
      openAudioUid: "", //开启音频uid
      closeAudioUid: "", //关闭音频uid
      openVideoUid: "", //开启摄像头uid
      closeVideoUid: "", //关闭摄像头uid
      CameraUse: 0, //使用摄像头设备
      MicUse: 0, //使用麦克风设备
      SpeakerUse: 0, //使用扬声器设备
      userList_left: 0,
      userList: [], //参会者列表
      user_list_page: 1,
      user_list_last_pages: 1,
      userListSet: [], //参会者列表用户设置
      authInfo: {}, //分享信息
      // 加入状态 本地加入和声网加入
      join_status: {
        auth_info_status: 0,
        client_join: 0
      },
      joinTimer: "", // 入会定时器 轮询本地和声网加入状态
      shareTimer: "", // 共享切换主屏定时器
      shareingTimer: "",
      share_uid: "", // 共享uid
      streamShareUid:"",  // 共享人uid
      share_uesrname: "", // 共享用户名
      isShowShareName: false, // 显示共享用户名
      isShare: "", // 共享状态
      shareloading: false,
      shareText: "",
      share_info: {}, // 分享详情
      live_btn_text: "开始直播",
      liveSetInfo: {}, // 直播设置
      subscribeSet: true, // 收起远端用户菜单
      VoiceIncentive: 0, // 语音激励状态  1关闭 0开启
      largeVolumeUid: "", // 语音激励声音大的用户uid
      liveUid: "", // 直播画面用户uid
      liveStatus: 0, // 开始直播状态
      localLiveStatus: 0,

      live_share_view: 0, // 共享直播布局  0 左右中 1 左中右 2 上中下 3 上下中
      live_default_view: 0, // 普通直播布局  0 主讲  1 画廊

      controlField: true,
      homeVolumeLevel: 0,
      homeInterval1: "",
      fixTimer:"",
      fullScreenUid: "",      // 全屏显示uid
      fullScreenIndex: null,  // 全屏显示项索引
      menuStatusShow:1,       // 控制栏显示状态 1自动隐藏 2常驻
      mirrorStatus:0,         // 1 镜像 2 非镜像

      copyTimeNum:0,
      copyTimer:null,
      subscribeTimer:null,
      DevTimer:null,

      waiting_room_list:[],   // 等待室列表
      waiting_room_total:null,  // 等待室人数

      dual_stream:null,       // 双流字段
    };
  },
  created() {
    // 获取检测使用设备
    this.$route.query.CameraUse ? (this.CameraUse = this.$route.query.CameraUse) : "";
    this.$route.query.MicUse ? (this.MicUse = this.$route.query.MicUse) : "";
    this.$route.query.SpeakerUse ? (this.SpeakerUse = this.$route.query.SpeakerUse) : "";
    // 获取登录 摄像头 和 麦克风 状态
    this.$route.query.Camera == 1 || this.$route.query.Camera == undefined ? (this.localCameraShow = true) : (this.localCameraShow = false);
    this.$route.query.Mic == 1 || this.$route.query.Mic == undefined ? (this.localAudioShow = true) : (this.localAudioShow = false);
    console.log(this.$route.query.Camera,this.localCameraShow)
    //获取本地浏览器宽高
    this.screenWidth = document.body.clientWidth;
    this.screenHeight = document.body.clientHeight;

    if(this.$route.fullPath.indexOf("?c=") == -1){  // 非主持一键登录
      window.localStorage.removeItem("OLkey");
      if ( !window.localStorage.getItem("uid") || !window.localStorage.getItem("meetinfo") || !window.localStorage.getItem("token")) {
        console.log("get uid / get meetinfo / get token");
        this.$router.push({ path: "/error" });
        return;
      }

      this.uid = JSON.parse(window.localStorage.getItem("uid"));
      this.meetinfo = JSON.parse(window.localStorage.getItem("meetinfo"));
      this.token = JSON.parse(window.localStorage.getItem("token"));
      
      this.options.token = this.meetinfo.agora_token;
      this.options.channel = this.meetinfo.meeting_id;
      this.options.uid = this.meetinfo.uid;
      this.options.role = this.meetinfo.identity == 1 ? "host" : this.meetinfo.identity == 2 ? "host" : this.meetinfo.identity == 3 ? "audience" : "";
      this.role = this.meetinfo.identity == 1 ? "host" : this.meetinfo.identity == 2 ? "host" : this.meetinfo.identity == 3 ? "audience" : "";

      this.init();
      this.onKeyDown();
    }
    
  },
  mounted() {
    this.joinTimer = setInterval(() => {
      if ( this.join_status.auth_info_status == 1 &&  this.join_status.client_join == 1 ) {
        this.clientJoin();
        clearInterval(this.joinTimer);
        return;
      }
    }, 100);

    this.jsvideoMainWidth();

    const that = this;
    window.onresize = () => {
      return (() => {
        that.screenWidth = document.body.clientWidth;
        that.screenHeight = document.body.clientHeight;
      })();
    };

    if(this.menuStatusShow == 1){
      setTimeout(() => {
        this.controlField ? (this.controlField = false) : "";
      }, 30000);
    }else{
      this.controlField = true;
    }

    var _this = this;
    window.onbeforeunload = function(e) {
    // e = e || window.event;
      if(_this.$route.path == '/host'){
        // 兼容IE8和Firefox 4之前的版本
        if (e) {
          e.returnValue = "此操作将离开会议";
        }
        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        window.localStorage.setItem('unloadStatus',1);
        return "此操作将离开会议";
      }
    }

  },
  watch: {
    screenWidth(newv) {
      this.jsvideoMainWidth();
    },
    screenHeight(newv) {
      this.jsvideoMainWidth();
    },
    homeTopShow(newv) {
      this.jsvideoMainWidth();
    },
    $route() {
      if (this.CameraUse != this.$route.query.CameraUse) {
        this.CameraUse = this.$route.query.CameraUse;
        this.setCurrentCameraDeviceId(
          this.Cameras[this.$route.query.CameraUse].deviceId
        );
      }
      if (this.MicUse != this.$route.query.MicUse) {
        this.MicUse = this.$route.query.MicUse;
        this.setCurrentMicDeviceId(
          this.Microphones[this.$route.query.MicUse].deviceId
        );
      }
      // if(this.SpeakerUse != this.$route.query.SpeakerUse)
    }
  },
  components: {
    subscribeVideo,
    broadcast,
    setOptions
  },
  methods: {
    lastTo() {
      this.nowProgress > 0 ? (this.nowProgress -= 1) : this.nowProgress
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.nowProgress < 1) {
            this.loadding = true
            const reqData = {
              mobile: this.ruleForm.mobile,
              code: this.ruleForm.code
            }
            // 验证直播账号
            this.$store.dispatch('verify_live_admin_info', reqData).then((res) => {
                if (res.data.code === 200) {
                  this.userInfo = res.data.data;
                  this.nowProgress = this.nowProgress + 1
                  this.$set(this.ruleForm, 'code', '')
                  clearInterval(this.countdown)
                  this.countdown = null
                  this.codetext = '发送验证码'
                  this.loadding = false;
                }else{
                  this.loadding = false;
                  this.$message({
                    message:res.data.error,
                    type:"error"
                  })
                }
              }).catch((err) => {  });
          } else {
            this.loadding = true
            const data = {
              id: this.userInfo.id,
              meeting_id: this.options.channel
            }
            // bindingShow
            // 绑定直播账号
            this.$store.dispatch('bind_live_admin', data).then((res) => {
                if (res.data.code === 200) {
                  this.nowProgress = this.nowProgress + 1;
                  this.bindingShow = false;
                  this.$message({
                    message: res.data.message,
                    type: "success"
                  });
                }else{
                  this.$message({
                    message:res.data.error,
                    type:"error"
                  })
                }
                this.loadding = false
              }).catch((err) => { });
          }
        } else {
          return false
        }
      })
    },
    sendMsg() {
      const mobileRep =
        /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(
          this.ruleForm.mobile
        )
      if (!mobileRep) {
        this.$message.error('请输入正确的手机号！')
      } else {
        let timer = 60
        this.codebtnshake = true
        this.countdown && clearInterval(this.countdown)
        this.countdown = setInterval(() => {
          if (timer === 0) {
            clearInterval(this.countdown)
            this.codetext = '发送验证码'
            this.codebtnshake = false
            return
          }
          this.codetext = `${timer} s`
          timer = timer - 1
        }, 1000)
        this.$store.dispatch('send_sms_captcha', { type: 4, mobile: this.ruleForm.mobile })
          .then((res) => {
            if(res.data.code == 200){

            }else{
              this.$message({
                message:res.data.message,
                type:"error"
              })
            }
          })
      }
    },
    
    /** 获取主持一键入会信息 */
    host_join_info(fast_code){
      this.$store.dispatch("host_join_info",{
        fast_code:fast_code,
      }).then(res=>{
        if(res.data.code == 200){
          this.meetinfo = res.data.data;
          this.options.token = this.meetinfo.agora_token;
          this.options.channel = this.meetinfo.meeting_id;
          this.options.uid = this.meetinfo.uid;
          this.options.role = this.meetinfo.identity == 1 ? "host" : this.meetinfo.identity == 2 ? "host" : this.meetinfo.identity == 3 ? "audience" : "";
          this.role = this.meetinfo.identity == 1 ? "host" : this.meetinfo.identity == 2 ? "host" : this.meetinfo.identity == 3 ? "audience" : "";
          this.uid = this.meetinfo.uid;
          window.localStorage.setItem('meetinfoId',this.meetinfo.id);
          this.init();
          this.get_auth_info();
        }else{
          this.viewLoading = false;
          this.$message({
            message:res.data.message,
            type:"error"
          })
        }
       }).catch(()=>{ })
    },

    /** 快捷键配置 */
    onKeyDown() {
      let that = this;
      document.onkeydown = function(e) {
        let motion = window.event;
        // e.altKey === true 判断是否有alt按键。
        // e.ctrlKey === true 判断是否有ctrl按键
        // if (motion.altKey && motion.keyCode == 112) homeTopShow ? qiehuan(3) : ''; // alt+f1 切换演讲者视图
        // if (motion.altKey && motion.keyCode == 113) !homeTopShow ? qiehuan(1) : ''; // alt+f2 切换画廊视图
        // if (motion.altKey && motion.keyCode == 83 && this.meetinfo.identity == 1){  // alt+s  开始/停止共享
        //     !that.isShare ? that.createScreenTrack() : that.cancel_screen_sharing(1);
        // }; 
        if (motion.altKey && motion.keyCode == 86) that.openCloseView(); // alt+v  开/关摄像头
        if (motion.ctrlKey && motion.altKey && motion.keyCode == 65)
          that.openCloseAudio(); // ctrl+alt+a  开/关麦克风
        if (motion.ctrlKey && motion.altKey && motion.keyCode == 70) {
          // ctrl+alt+f  全屏当前画面
          if (!that.fullScreenMode) {
            that.fullScreenMode = true;
            that.fullScreen();
          } else {
            that.setScreenOut(that.subscribeUser[that.fullScreenIndex], 2);
          }
        }
        if (motion.ctrlKey && motion.altKey && motion.keyCode == 76 && that.meetinfo.identity == 1) { // ctrl+alt+l  开启直播
          if(that.pushRtmp  == "" && !that.isLive){
            that.dialogStudioVisible = true;
          }else{
            !that.isLive ? that.pushStart() : "";
            !that.isLive ? (that.isLive = true) : "";
          }
        }
        if (motion.ctrlKey && motion.altKey && motion.keyCode == 67 && that.meetinfo.identity == 1) {  // ctrl+alt+c  结束直播
          that.isLive ? that.pushStop() : "";
          that.isLive ? (that.isLive = false) : "";
        }; 
        // if (motion.ctrlKey && motion.altKey && motion.keyCode == 68) that.fixMouseLeave(); // ctrl+alt+d  强制隐藏菜单
      };
    },

    /**  接收 组件 直播设置配置 */
    getchangeLiveSet(data) {
      console.log(data)
      data.logoSwitch ? data.logoUrl != "" ? (LiveTranscoding.watermark.url = data.logoUrl) 
      : (LiveTranscoding.watermark.url = "https://images.innocomn.com/064c6202009101316542788.png")
      : (LiveTranscoding.watermark.url = "https://images.innocomn.com/fe261202105171000211194.png");
      if (data.resolution == "720p_3") LiveTranscoding.videoBitrate = 1000;
      if (data.resolution == "480p_3") LiveTranscoding.videoBitrate = 700;
      if (data.resolution == "360p_3") LiveTranscoding.videoBitrate = 400;
      this.mirrorStatus = data.mirrorStatus;
      this.menuStatusShow = data.menuStatus;
      this.menuStatusShow == 1 ? this.controlField = false : this.controlField = true;
      let viewShow = window.localStorage.getItem("homeViewShow") ? window.localStorage.getItem("homeViewShow") : 0;
      let arr = this.subscribeUser.filter(item=> item.uid != this.uid);
      setTimeout(()=>{
        this.rtc.videoTrack.stop();
        if(arr.length ==0){
          this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror: data.mirrorStatus == 1 ? true : false });
        }else{
          viewShow != 1 || this.isShare ? this.rtc.videoTrack.play(document.getElementById(this.uid), { fit: "contain", mirror: data.mirrorStatus == 1 ? true : false }) 
          : this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror: data.mirrorStatus == 1 ? true : false });
        }
      },500)

      this.liveSetInfo = data;
      data.backgroundColor != null  ? (LiveTranscoding.backgroundColor = parseInt(data.backgroundColor))  : "";

      if (data.backgroundImage != null) {
        LiveTranscoding.backgroundImage = {
          width: LiveTranscoding.width,
          height: LiveTranscoding.height,
          url: data.backgroundImage,
          x: 0,
          y: 0
        };
      } else {
        delete LiveTranscoding.backgroundImage;
      }
      if (data.logoPosition == 0) {
        LiveTranscoding.watermark.x = 20;
        LiveTranscoding.watermark.y = 20;
      }
      if (data.logoPosition == 1) {
        LiveTranscoding.watermark.x = 1140;
        LiveTranscoding.watermark.y = 20;
      }
      if (data.logoPosition == 2) {
        LiveTranscoding.watermark.x = 20;
        LiveTranscoding.watermark.y = 580;
      }
      if (data.logoPosition == 3) {
        LiveTranscoding.watermark.x = 1140;
        LiveTranscoding.watermark.y = 580;
      }
      this.live_share_view = data.liveShareView;
      this.live_default_view = data.liveDefaultView;
      this.streamingConfig();
      this.rtc.client.setLiveTranscoding(LiveTranscoding).then(() => {
        console.log("set live transcoding success");
      });
    },
    closeSetDialog(data) {
      this.setDialogVisible = data;
    },
    copyInfo() {
      let clipboard = new Clipboard(".copy-info");
      clipboard.on("success", e => {
        this.$message({
          message: "复制成功！",
          type: "success"
        });
        this.copyTimeNum = 5;
        this.copyTimer = setInterval(()=>{
          this.copyTimeNum -= 1;
          if(this.copyTimeNum == 0) clearInterval(this.copyTimer)
        },1000)
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制
        this.$toast("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    cancel(event) {
      if (this.cameraListDomShow) {
        var vt = document.getElementById("camera-list");
        var vt2 = document.getElementById("camera-list-btn");
        if (!vt.contains(event.target) && !vt2.contains(event.target))
          this.cameraListDomShow = false;
      }
      if (this.MicListDomShow) {
        var vt = document.getElementById("mic-list");
        var vt2 = document.getElementById("mic-list-btn");
        if (!vt.contains(event.target) && !vt2.contains(event.target))
          this.MicListDomShow = false;
      }
      if (this.viewDomShow) {
        var vt = document.getElementById("view-list");
        var vt2 = document.getElementById("view-list-btn");
        if (!vt.contains(event.target) && !vt2.contains(event.target))
          this.viewDomShow = false;
      }
      if (this.leaveMeetShow) {
        var vt = document.getElementById("leave-sel");
        var vt2 = document.getElementById("leave-sel-btn");
        if (!vt.contains(event.target) && !vt2.contains(event.target))
          this.leaveMeetShow = false;
      }
      if (this.shareChannelShow) {
        var vt = document.getElementById("share-channel");
        var vt2 = document.getElementById("share-channel-btn");
        if (!vt.contains(event.target) && !vt2.contains(event.target))
          this.shareChannelShow = false;
      }
      if (this.attendeesDomShow) {
        this.userList.forEach((item, index) => {
          var vt = document.getElementById("userlist-more-dialog-" + index);
          var vt2 = document.getElementById(
            "userlist-more-dialog-btn-" + index
          );
          if ( !vt.contains(event.target) && !vt2.contains(event.target) && this.userListSet[index].More ) this.userListSet[index].More = false;
          return;
        });
      }
      if (this.subscribeSet) {
        var vt = document.getElementById("subscribe-Video");
        if (!vt.contains(event.target)) this.subscribeSet = false;
      }
    },
    getsubscribeSet(data) {
      this.subscribeSet = data;
    },
    // 接收通知 设为直播画面
    set_live_status(data) {
      if (this.meetinfo.identity == 1) {
        console.log("Set or cancel live screen", data);
        this.localLiveStatus = 0;
        if (data == window.localStorage.getItem("liveUid") && !this.isShare) {
          data == this.uid ? (this.localLiveStatus = 0) : "";
          this.userListSet.forEach(item =>
            item.uid == data ? (item.live = 0) : ""
          );
          window.localStorage.removeItem("liveUid");
          this.liveUid = "";
          this.streamingConfig();
        } else {
          data == this.uid ? (this.localLiveStatus = 1) : "";
          this.userListSet.forEach(item =>
            item.uid == data ? (item.live = 1) : (item.live = 0)
          );
          window.localStorage.setItem("liveUid", data);
          this.liveUid = data;
          this.streamingConfig();
        }
      }
    },
    // 接收通知 开启摄像头
    scoket_videoTrack_play(data) {
      this.closeVideoUid = "";
      console.log("Turn on the camera", data.uid, this.uid);
      if ( window.localStorage.getItem("screenUid") && window.localStorage.getItem("screenUid") != this.uid && data.uid == this.uid ) {
        // console.log('焦点开启摄像头')
        this.$nextTick(() => {
          this.rtc.videoTrack.play(document.getElementById(this.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
        })
      } else if ( window.localStorage.getItem("fixedUid") && window.localStorage.getItem("fixedUid") != this.uid && data.uid == this.uid ) {
        // console.log('选定开启摄像头')
        this.$nextTick(() => {
          this.rtc.videoTrack.play(document.getElementById(this.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
        })
      } else if ( this.isShare || window.localStorage.getItem("oldUid") == this.uid ) {
        // console.log('共享开启摄像头')
        this.$nextTick(() => {
          this.rtc.videoTrack.play(document.getElementById(this.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
        })
      } else if (data.uid == this.uid  ) {
        // console.log('开启摄像头')
        this.$nextTick(() => {
          window.localStorage.getItem("homeViewShow") == 3 ? this.rtc.videoTrack.play(document.getElementById(this.uid), { fit: "contain" }) 
          : this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
        })
      }
      if (data.uid == this.uid && data.type !=0 ) {
        let obj = {
          type: "agora_public_notice",
          meeting_id: this.options.channel,
          data: {
            type: "",
            data: {
              uid: data.uid
            }
          }
        };
        this.$confirm('主持人请求打开您的摄像头，是否允许', '打开摄像头', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showClose:false,
          closeOnClickModal:false,
          // type: 'info'
        }).then(() => {
          obj.data.type = 'videoTrack_open_consent';
          this.publicNotice = obj;
        }).catch(() => { 
          obj.data.type = 'videoTrack_open_refuse';
          this.publicNotice = obj;
        })
      }
      if(data.uid != this.uid && data.type ==0 ) {
        this.openVideoUid = data.uid;
        this.userList.forEach((item, index) => {
          if (item.uid == data.uid) this.userListSet[index].VideoShow = 1;
        });
      }
      if(data.uid == this.uid && data.type ==0 ){
        this.openVideoUid = data.uid;
        this.rtc.videoTrack.setEnabled(true);
        this.localCameraShow = true;
        this.$router.push({
          query: merge(this.$route.query, { Camera: 1 })
        });
        this.userList.forEach((item, index) => {
          if (item.uid == data.uid) this.userListSet[index].VideoShow = 1;
        });
      };
      data.uid == this.uid && this.video_change(data.uid, 1);
    },
    // 接收通知 关闭摄像头
    scoket_videoTrack_setEnabled(data) {
      this.closeVideoUid = data.uid;
      this.openVideoUid = "";
      if ( this.screenUid == data.uid || this.fixedUid == data.uid || data.uid == window.localStorage.getItem("IncentiveOldUid") ) {
        this.screenUid == data.uid ? (this.screenUid = "") : "";
        this.fixedUid == data.uid ? (this.fixedUid = "") : "";
        window.localStorage.removeItem("IncentiveOldUid");
        this.subscribeUser.forEach((item, index) => {
          if (this.uid == item.uid) {
            this.screenUid = "";
            this.subscribeUser.splice(index, 1);
          }
        });
        this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
      }
      if (data.uid == this.uid) {
        this.rtc.videoTrack.stop();
        this.rtc.videoTrack.setEnabled(false);
        this.localCameraShow = false;
        this.$router.push({
          query: merge(this.$route.query, { Camera: 0 })
        });
      }
      this.userList.forEach((item, index) => {
        if (item.uid == data.uid) this.userListSet[index].VideoShow = 0;
      });
      if (this.meetinfo.identity == 1) {
        this.video_change(data.uid, 0);
        this.streamingConfig();
      }
    },
    // 接收通知同意开启摄像头
    scoket_videoTrack_open_consent(data){
      this.openVideoUid = data.uid;
      this.video_change(data.uid, 1);
      if(data.uid == this.uid){
        this.rtc.videoTrack.setEnabled(true);
        this.localCameraShow = true;
        this.$router.push({
          query: merge(this.$route.query, { Camera: 1 })
        });
      }
      this.userList.forEach((item, index) => {
        if (item.uid == data.uid){
          this.userListSet[index].VideoShow = 1;
          this.meetinfo.identity == 1 ? this.streamingConfig() : '';
        } 
      });
    },
    // 接收通知拒绝开启摄像头
    scoket_videoTrack_open_refuse(data){
      this.userList.forEach(item=>{
        if(data.uid == item.uid && data.uid != this.uid && this.meetinfo.identity == 1){
          this.$message(item.username + '拒绝开启摄像头！');
        }
      })
    },
    // 接收通知同意开启麦克风
    scoket_audioTrack_open_consent(data){
      this.openAudioUid = data.uid;
      if(data.uid == this.uid){
        this.rtc.audioTrack.setEnabled(true);
        this.localAudioShow = true;
        this.$router.push({
          query: merge(this.$route.query, { Mic: 1 })
        });
        this.scene_change(data.uid, 1);
      }
      this.userList.forEach((item, index) => {
        if (item.uid == data.uid) this.userListSet[index].AudioShow = 1;
      });
    },
    // 接收通知拒绝开启麦克风
    scoket_audioTrack_open_refuse(data){
      this.userList.forEach(item=>{
        if(data.uid == item.uid && data.uid != this.uid && this.meetinfo.identity == 1){
          this.$message(item.username + '拒绝开启麦克风！');
        }
      })
    },
    // 接收通知 开启麦克风
    scoket_audioTrack_setEnabled_true(data) {
      this.closeAudioUid = "";
      if (data.uid == this.uid && data.type != 0) {
        let obj = {
          type: "agora_public_notice",
          meeting_id: this.options.channel,
          data: {
            type: "",
            data: {
              uid: data.uid
            }
          }
        };
        this.$confirm('主持人请求打开您的麦克风，是否允许', '打开麦克风', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showClose:false,
          closeOnClickModal:false,
        }).then(() => {
          obj.data.type = 'audioTrack_open_consent';
          this.publicNotice = obj;
          this.rtc.audioTrack.setEnabled(true);
          this.localAudioShow = true;
          this.$router.push({
            query: merge(this.$route.query, { Mic: 1 })
          });
        }).catch(() => {
          obj.data.type = 'audioTrack_open_refuse';
          this.publicNotice = obj;
        })
      }
      if(data.uid != this.uid && data.type ==0 ) {
        this.openAudioUid = data.uid;
        this.userList.forEach((item, index) => {
          if (item.uid == data.uid) this.userListSet[index].AudioShow = 1;
        });
      }
      if(data.uid == this.uid && data.type ==0 ){
        this.openAudioUid = data.uid;
        this.rtc.audioTrack.setEnabled(true);
        this.localAudioShow = true;
        this.$router.push({
          query: merge(this.$route.query, { Mic: 1 })
        });
        this.userList.forEach((item, index) => {
          if (item.uid == data.uid) this.userListSet[index].AudioShow = 1;
        });
      } 
      data.uid == this.uid && this.scene_change(data.uid, 1);
    },
    // 接收通知 关闭麦克风
    scoket_audioTrack_setEnabled_false(data) {
      this.closeAudioUid = data.uid;
      this.openAudioUid = "";
      if (data.uid == this.uid) {
        this.rtc.audioTrack.setEnabled(false);
        this.localAudioShow = false;
        this.$router.push({
          query: merge(this.$route.query, { Mic: 0 })
        });
      }
      this.userList.forEach((item, index) => {
        if (item.uid == data.uid) this.userListSet[index].AudioShow = 0;
      });
      this.meetinfo.identity == 1 ? this.scene_change(data.uid, 0) : "";
    },
    // 接收通知 还原显示
    scoket_main_screen_false(data) {
      this.VoiceIncentive = 0;
      this.screenUid = "";
      window.localStorage.removeItem("screenUid");
      if (data.uid != this.uid) {
        this.subscribeUser.forEach((item, index) => {
          if (this.uid == item.uid && !this.isShare) {
            this.screenUid = "";
            this.subscribeUser.splice(index, 1);
            window.localStorage.removeItem("screenUid");
            return;
          }
        });
        this.subscribeUser.forEach(item => {
          if (item.uid == data.uid && item.hasVideo) {
            this.rtc.videoTrack.stop();
            this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
            item.videoTrack.stop();
            this.setRemoteVideoStreamType(item.uid, 1);
            this.$nextTick(() => {
              item.videoTrack.play(document.getElementById(data.uid), { fit: "contain", mirror: false });
            })
          }
        });
        this.userList.forEach((item, index) => {
          if (item.uid == data.uid) {
            this.userListSet[index].BlowUp = 1;
          }
        });
        if (this.meetinfo.identity == 1) {
          this.focus_change(data.uid, 0);
          if (this.live_default_view == 1) this.streamingConfig(); // 直播为焦点布局 切换主讲
        }
      }
      this.streamingConfig();
    },
    // 接收通知 主屏显示
    scoket_main_screen_true(data) {
      this.VoiceIncentive = 1;
      window.localStorage.removeItem("fixedUid");
      this.subscribeUser.forEach((item, index) => {
        if (this.uid == item.uid) {
          this.setRemoteVideoStreamType(window.localStorage.getItem("screenUid"), 1);
          this.screenUid = "";
          this.subscribeUser.splice(index, 1);
          window.localStorage.removeItem("screenUid");
          this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
        } else if(item.hasVideo){
          item.videoTrack.stop();
          item.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror: false });
        }
      });
      window.localStorage.setItem("screenUid", data.uid);
      if (window.localStorage.getItem("homeViewShow") == 3) this.qiehuan(1);
      if (data.uid != this.uid) {
        this.subscribeUser.forEach(item => {
          if (item.uid == data.uid && item.hasVideo) {
            this.screenUid = data.uid;
            this.subscribeUser.push({ uid: this.uid });
            this.rtc.videoTrack.stop(); 
            this.$nextTick(() => {
              this.rtc.videoTrack.play(document.getElementById(this.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
            })
            item.videoTrack.stop();
            this.setRemoteVideoStreamType(item.uid, 0);
            item.videoTrack.play("videoMain", { fit: "contain", mirror: false });
          } else if(item.hasVideo) {
            this.setRemoteVideoStreamType(item.uid, 1);
            item.videoTrack.stop();
            this.$nextTick(() => {
              item.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror: false });
            })
          }
        });
      }
      this.userList.forEach((item, index) => {
        if (item.uid == data.uid) {
          this.userListSet[index].BlowUp = 0;
        }
      });
      if (this.meetinfo.identity == 1) {
        this.get_participant_list(1);
        this.focus_change(data.uid, 1);
        if (this.live_default_view == 1) this.streamingConfig(); // 直播为焦点布局 切换主讲
      }

      this.streamingConfig();
    },
    // 设置本地焦点视图
    setScreenFocus(data, index) {
      // console.log(data, index);
      let ITEM = data.item ? data.item : data;
      if (this.meetinfo.identity == 1) this.get_participant_list(1);
      if (ITEM.uid == this.uid) {
        // 判断选定自己视图
        this.subscribeUser.forEach(item => {
          if (item.uid != ITEM.uid && item.hasVideo) {
            item.videoTrack.stop();
            this.$nextTick(() => {
              item.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror: false });
            })
          } else {
            if (window.localStorage.getItem("homeViewShow") == 3 && data.type == 0) this.qiehuan(1);
            window.localStorage.setItem("IncentiveOldUid", "");
            this.subscribeUser = this.subscribeUser.filter( i => i.uid != this.uid );
            if (data.type == 0 || index == 1) {
              this.fixedUid = "";
              window.localStorage.removeItem("fixedUid");
            } else {
              this.fullScreenUid = "";
              window.localStorage.removeItem("fullScreenUid");
            }
            this.rtc.videoTrack.stop();
            this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
          }
        });
      } else {
        if ( window.localStorage.getItem("screenUid") && this.meetinfo.identity == 1 ) {
          this.$message({
            message: "请先取消用户焦点视图！",
            type: "error"
          });
        } else {
          this.VoiceIncentive = 1;
          this.subscribeUser.forEach((item, index) => {
            if (this.uid == item.uid) {
              this.subscribeUser.splice(index, 1);
              if (data.type == 0 || index) {
                this.fixedUid = "";
                window.localStorage.removeItem("fixedUid");
              } else {
                this.fullScreenUid = "";
                window.localStorage.removeItem("fullScreenUid");
              }
            }
          });
          this.subscribeUser.forEach(item => {
            if (item.uid != ITEM.uid && item.hasVideo) {
              this.setRemoteVideoStreamType(item.uid, 1);
              item.videoTrack.stop();
              this.$nextTick(() => {
                item.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror: false });
              })
            }
          });
          this.subscribeUser.push({ uid: this.uid });
          if (data.type == 0 || index == 1) {
            this.fixedUid = ITEM.uid;
            window.localStorage.setItem("fixedUid", ITEM.uid);
          } else {
            this.fullScreenUid = ITEM.uid;
            window.localStorage.setItem("fullScreenUid", ITEM.uid);
            this.fullScreenMode = true;
            this.fullScreen();
          }
          if (window.localStorage.getItem("homeViewShow") == 3 && data.type == 0) this.qiehuan(1);
          this.$nextTick(() => {
            this.rtc.videoTrack.stop();
            this.rtc.videoTrack.play(document.getElementById(this.uid), { fit: "contain", mirror: true });
          })
          
          this.subscribeUser.forEach(item => {
            if (item.uid != ITEM.uid && item.hasVideo) {
              this.$nextTick(() => {
                item.videoTrack.stop();
                item.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror: false });
              })
            }
          });
          if (index) {
            // console.log(ITEM,index)
            if (index == 2) {
              this.fullScreenUid = ITEM.uid;
              window.localStorage.setItem("fullScreenUid", ITEM.uid);
              this.fullScreenMode = true;
              this.fullScreen();
            }
            ITEM.uid != this.uid ? (this.fullScreenIndex = index) : (this.fullScreenIndex = null);
            this.subscribeUser.forEach((i, j) => {
              if (i.uid == ITEM.uid && i.hasVideo) {
                this.setRemoteVideoStreamType(ITEM.uid, 0);
                i.videoTrack.play("videoMain", { fit: "contain", mirror: false });
                index == 2 ? (this.fullScreenIndex = j) : "";
              }
            });
          } else {
            ITEM.hasVideo && this.setRemoteVideoStreamType(ITEM.uid, 0);
            ITEM.hasVideo && ITEM.videoTrack.play("videoMain", { fit: "contain", mirror: false });
            this.subscribeUser.forEach((i, j) => {
              if (i.uid == ITEM.uid) this.fullScreenIndex = j;
            });
          }
          this.userListSet.forEach((i, j) => {
            i.uid == ITEM.uid ? (this.userListSet[j].More = false) : "";
          });
        }
      }
    },
    // 还原本地焦点视图
    setScreenOut(data, status) {
      // console.log(1111);
      // console.log(data);
      let ITEM = data != undefined ? (data.item ? data.item : data) : null;
      this.VoiceIncentive = 0;
      if(ITEM != null){
        this.subscribeUser.forEach((item, index) => {
          if (this.uid == item.uid) {
            status != 2 || !window.localStorage.getItem("fixedUid") ? this.subscribeUser.splice(index, 1) : '';
            if (ITEM.type == 0 || status != 2) {
              this.fixedUid = "";
              window.localStorage.removeItem("fixedUid");
            } else if (ITEM.type == 1 || status == 2) {
              this.fullScreenUid = "";
              window.localStorage.removeItem("fullScreenUid");
              this.jsvideoMainWidth();
              this.fullScreenMode = false;
            }
          }
        });
      }
      if (!status) {
        this.subscribeUser.forEach(item => {
          if (item.uid == ITEM.uid && item.hasVideo) {
            item.videoTrack.stop();
            this.setRemoteVideoStreamType(ITEM.uid, 1);
            this.$nextTick(() => {
              item.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror: false });
              this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
            })
          }
        });
      } else {
        if (ITEM != null) {
         this.subscribeUser.forEach((i, j) => {
          if (i.uid == ITEM.uid && i.hasVideo && i.uid != window.localStorage.getItem("fixedUid")) {
            i.videoTrack.stop();
            this.setRemoteVideoStreamType(i.uid, 1);
            this.$nextTick(() => {
              i.videoTrack.play(document.getElementById(i.uid), { fit: "contain", mirror: false });
              this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
            })
          }else if(i.uid == window.localStorage.getItem("fixedUid") && i.hasVideo) {
            i.videoTrack.stop();
            this.$nextTick(() => {
              i.videoTrack.play("videoMain", { fit: "contain", mirror: false });
              this.rtc.videoTrack.play(document.getElementById(this.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
            })
          }
         });

         this.userListSet.forEach((i, j) => {
            i.uid == ITEM.uid ? (this.userListSet[j].More = false) : "";
         });

        } else {
          this.fullScreenUid = "";
          window.localStorage.removeItem("fullScreenUid");
          this.jsvideoMainWidth();
          this.fullScreenMode = false;
        }
      }

      this.fullScreenIndex = null;
    },
    findElem(arrayToSearch, attr, val) {
      for (var i = 0; i < arrayToSearch.length; i++) {
        if (arrayToSearch[i][attr] == val) {
          return i;
        }
      }
      return -1;
    },
    // 接收通知 共享屏幕
    scoket_web_meeting_share(data) {
      this.qiehuan(2);
      if (window.localStorage.getItem("homeViewShow") == 3) {
        window.localStorage.setItem("homeViewShow", 1);
        this.homeTopShow = true;
        this.homeRightShow = this.homeGalleryShow = false;
      }
      this.VoiceIncentive = 1;
      if (this.fixedUid == data.uid) {
        this.fixedUid = "";
        window.localStorage.removeItem("fixedUid");
      }
      if (this.screenUid == data.uid) {
        // 判断焦点人发起的共享
        this.publicNotice = {
          type: "agora_public_notice",
          meeting_id: this.options.channel,
          data: {
            type: "main_screen_false",
            data: {
              uid: this.screenUid
            }
          }
        };
      }
      this.shareText = "正在准备共享...";
      if (data.uid != this.uid && data.share_id) {
        window.localStorage.setItem("oldUid", data.uid);
        this.streamShareUid = data.uid;
        this.share_uesrname = data.name;
        this.share_uid = parseInt(data.share_id);
        this.isShare = 1;
        this.localLiveStatus = 0;
        this.shareloading = true;
      } else {
        this.localLiveStatus = 1;
      }
      this.liveUid = data.uid;
      this.isLive && window.localStorage.setItem("liveUid", data.uid);
      this.userListSet.forEach((i, j) =>
        i.uid == data.uid ? (this.userListSet[j].live = 1) : ""
      );
    },
    // 接收通知 取消共享
    scoket_web_meeting_share_out(data) {
      this.qiehuan(1);
      this.fullScreenMode && this.unfullScreen();
      data.uid == this.uid ? (this.localLiveStatus = 0) : "";
      this.userListSet.forEach(item => item.uid == data.uid ? (item.live = 0) : "" );
      this.liveUid = "";
      window.localStorage.removeItem("liveUid");
      window.localStorage.removeItem("oldUid");
      this.VoiceIncentive = 0;
      this.rtc.videoTrack.setEnabled(false);
      this.share_uid = "";
      this.share_uesrname = "";
      this.isShare = 0;
      this.isShowShareName = false;
      this.ScreenShare = false;
      this.shareText = "正在结束共享...";
      this.shareloading = true;
      clearInterval(this.shareTimer);
      setTimeout(()=>{
        this.shareloading = false;
        if (this.localCameraShow) {
          this.rtc.videoTrack.stop();
          this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
          this.rtc.videoTrack.setEnabled(true);
        }
      },300)

      this.subscribeUser = this.subscribeUser.filter(i => i.uid != this.uid);

      if (this.subscribeUser.length == 0) {
        this.homeTopShow = false;
        this.homeRightShow = false;
        this.homeGalleryShow = false;
      }
      if (data.user_id == this.uid) {
        this.localScreenTrack.close();
        this.screenClient.leave();
      }
    },
    // 接收通知 远端video组件公共广播类型
    getpublicNotice(data) {
      this.publicNotice = data;
    },
    // 接收通知 移除参会者
    scoket_remove_participants(data) {
      if (data.uid == this.uid) {
        // this.out_meeting('leave');
        this.$store.dispatch("out_meeting", {
          id:window.localStorage.getItem('meetinfoId'),
          meeting_id: this.options.channel,
          uid: this.options.uid,
          type: "leave"
        }).then(res => {
          if (res.data.code == 200) {
            this.rtc.client.leave();
            this.clearLocal();
            this.$router.push({ name: "loginout" });
          }
        });
      }
      this.userList.forEach((item, index) => {
        if (item.uid == data.uid)  this.userList.splice(index, 1);
      });
    },
    // 接收通知 结束
    scoket_over_live() {
      window.localStorage.removeItem("screenUid");
      this.rtc.client.leave();
      this.clearLocal();
      this.$router.push({ name: "loginout" });
    },
    // 接收 scoket 加入成功client_id
    getClientId(data) {
      this.client_id = data;
      if(this.$route.fullPath.indexOf("?") != -1 && this.$route.fullPath.indexOf("?c=") != -1){
        this.host_join_info(this.$route.query.c)
      }else{
        this.get_auth_info();
      }
    },
    // 更改视频状态
    video_change(uid, status) {
      this.$store.dispatch("video_change", {
        meeting_id: this.options.channel,
        video: status, // 1 开启 0 关闭
        uid: uid
      }).then(res => {});
    },
    // 更改音频状态
    scene_change(uid, status) {
      this.$store.dispatch("scene_change", {
        meeting_id: this.options.channel,
        scene: status, // 1 开启 0 关闭
        uid: uid
      }).then(res => {});
    },
    // 更改焦点
    focus_change(uid, status) {
      this.$store.dispatch("focus_change", {
        meeting_id: this.options.channel,
        focus: status, // 1 开启 0 关闭
        uid: uid
      }).then(res => {});
    },
    // 获取入会信息
    get_auth_info() {
      this.$store.dispatch("get_auth_info", {
        id:window.localStorage.getItem('meetinfoId'),
        name: this.meetinfo.name,
        uid: this.meetinfo.uid,
        meeting_id: this.meetinfo.meeting_id,
        identity: this.meetinfo.identity,
        client_id: this.client_id,
        video: this.localCameraShow ? 1 : 0,
        scene: this.localAudioShow ? 1 : 0
      }).then(res => {
        if (res.data.code == 200) {
          this.join_status.auth_info_status = 1;
          // this.pushRtmp = res.data.data.push_url;
          this.authInfo = res.data.data.meeting_info;
          this.dual_stream = res.data.data.meeting_info.dual_stream;
          document.title = this.authInfo.meeting_title;
          this.authInfo["watch_url"] = res.data.data.watch_url;
          this.share_info = res.data.data.share_info;
          this.share_uesrname = this.share_info.name;
          this.share_info.share == 1 ? (this.isShare = 1) : (this.isShare = 0);
          if (this.share_info.share == 0 && window.localStorage.getItem("homeViewShow") == 2) {
            this.homeTopShow = true;
            this.homeRightShow = this.homeGalleryShow = false;
            window.localStorage.setItem("homeViewShow", 1);
          }
          window.localStorage.setItem("shareUid", this.share_info.share_id);
          this.get_participant_list(1);
          this.participant_waiting_room(1);
          res.data.data.focus_uid != null ? this.focusStatus(res.data.data.focus_uid) : "";
        }
      });
    },
    focusStatus(uid) {
      this.VoiceIncentive = 1;
      this.subscribeUser.push({ uid: this.uid });
      this.screenUid = uid;
      window.localStorage.setItem("screenUid", uid);
      let num = 0;
      let Timer = setInterval(() => {
        num += 1;
        if (num > 5) {
          clearInterval(Timer);
        }
        this.subscribeUser.forEach(item => {
          if (item.uid == uid && this.uid != uid && item.hasVideo) {
            this.rtc.videoTrack.stop();
            this.$nextTick(() => {
              item.videoTrack.play("videoMain", { fit: "contain" });
              this.rtc.videoTrack.play(document.getElementById(this.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
            })
            this.setRemoteVideoStreamType(uid, 0);
            clearInterval(Timer);
            return;
          }
        });
      }, 1000);
    },
    // 接收通知 进入等待室，等待主持同意
    scoket_waiting(data){
      if(this.meetinfo.identity == 1){
        this.$message({
          showClose: true,
          message: data.name+'已进入等待室'
        });
        this.participant_waiting_room(1)
      }
    },
    // 移除等待室
    waiting_room_remove(id){
      this.$store.dispatch("waiting_room_remove",{id:id}).then(res=>{
        if(res.data.code == 200){
          this.waiting_room_list.length !=0 ? this.waiting_room_list = this.waiting_room_list.forEach(item=> item.id != id) : '';
          this.participant_waiting_room(1)
          this.$message({
            message:res.data.message,
            type:"success"
          })
        }else{
          this.$message({
            message:res.data.message,
            type:"error"
          })
        }
      })
    },
    // 允许等待室入会
    waiting_room_adopt(id){
      this.$store.dispatch("waiting_room_adopt",{id:id}).then(res=>{
        if(res.data.code == 200){
          this.waiting_room_list.length !=0 ? this.waiting_room_list = this.waiting_room_list.forEach(item=> item.id != id) : '';
          this.participant_waiting_room(1);
          this.get_participant_list(1);
          this.$message({
            message:res.data.message,
            type:"success"
          })
        }else{
          this.$message({
            message:res.data.message,
            type:"error"
          })
        }
      })
    },
    // 获取等待室列表
    participant_waiting_room(current){
      let params = {
        meeting_id: this.options.channel,
        page: current,
        page_num: 20
      };
      this.$store.dispatch("participant_waiting_room",params).then(res => {
        if (res.data.code == 200) {
          this.waiting_room_list = res.data.data.data;
          this.waiting_room_total = res.data.data.total;
          this.waiting_room_list.forEach(item=>{
            item["name_sub"] = item.name.substr(0, 1);
            item["bg_color"] = "#" + Math.random().toString(16).substr(2, 6).toUpperCase();
          })
        }else{
          this.waiting_room_total = 0;
        }
      })
    },
    // 获取参会者列表
    get_participant_list(current) {
      let params = {
        meeting_id: this.options.channel,
        page: current,
        page_num: 20
      };
      this.$store.dispatch("get_participant_list", params).then(res => {
        if (res.data.code == 200) {
          this.attendeeTotal = res.data.data.total;
          this.user_list_last_pages = res.data.data.last_page;
          this.userList = [];
          this.userListSet = [];
          let obj = {};
          res.data.data.data.forEach(item => {
            obj = {
              uid: item.uid,
              admin: item.identity,
              avatar: item.avatar,
              channel_id: item.meeting_id,
              scene: item.scene,
              video: item.video,
              focus: item.focus,
              username: item.name,
              name_sub: item.name.substr(0, 1),
              bg_color: "#" + Math.random().toString(16).substr(2, 6).toUpperCase()
            };
            this.userList.push(obj);
          });
          this.userList.forEach(item => {
            let obj = {
              uid: item.uid,
              AudioShow: item.scene,
              VideoShow: item.video,
              BlowUp: JSON.parse(window.localStorage.getItem("screenUid")) == item.uid ? 0 : 1,
              Hands: JSON.parse(window.localStorage.getItem("fixedUid")) == item.uid ? 0 : 1,
              fullScreen: JSON.parse(window.localStorage.getItem("fullScreenUid")) == item.uid ? 0 : 1,
              live: window.localStorage.getItem("liveUid") == item.uid &&  window.localStorage.getItem("liveUid") ? 1 : 0,
              More: false
            };
            this.userListSet.push(obj);
          });
          this.subscribeUser.forEach(i=>{
            this.userList.forEach(item => {
              if(i.uid == item.uid && i.hasAudio && i.audioTrack!=undefined){
                // i.audioTrack.setEnabled(false); 
              } 
            })
          })
          // console.log(this.userListSet)
          if (this.isShare) {
            let obj = { uid: this.share_uid, username: this.share_uesrname + "的共享" };
            let obj2 = {
              AudioShow: 1,
              VideoShow: 1,
              BlowUp: 1,
              Hands: 1,
              More: false
            };
            this.userList.push(obj);
            this.userListSet.push(obj2);
          }
        }
      });
    },
    // 参会者列表滚动加载
    userlistScroll() {
      if (this.userList.length < 20) {
        return;
      } else if (this.user_list_page >= this.user_list_last_pages) {
        return;
      } else {
        this.user_list_page += 1;
        this.get_participant_list(this.user_list_page);
      }
    },
    qiehuan(top) {
      this.viewDomShow = false;
      this.get_participant_list(1);
      if (this.subscribeUser.length != 0) {
        if (top == 1) {
          this.homeTopShow = true;
          this.homeRightShow = this.homeGalleryShow = false;
          window.localStorage.setItem("homeViewShow", 1);
          this.jsvideoMainWidth();
        } else if (top == 2) {
          this.homeRightShow = true;
          this.homeTopShow = this.homeGalleryShow = false;
          window.localStorage.setItem("homeViewShow", 2);
          this.jsvideoMainWidth();
        } else if (top == 3) {
          if (!window.localStorage.getItem("screenUid") && !this.isShare) {
            this.homeGalleryShow = true;
            this.homeTopShow = this.homeRightShow = false;
            window.localStorage.setItem("homeViewShow", 3);
          } else {
            this.$alert( "（焦点视图、共享状态中）不允许切换画廊视图模式。", "提示", {
                confirmButtonText: "确定"
              }
            );
          }
        }
        this.showStatus(1);
      } else {
        this.$message({
          message: "还没有远端用户加入！",
          type: "error"
        });
      }
    },

    showStatus(status) {
      let showStatus = window.localStorage.getItem("homeViewShow");
      if (!showStatus) {
        this.homeTopShow = true;
        window.localStorage.setItem("homeViewShow", 1);
      }
      if (showStatus == 1) {
        this.homeTopShow = true;
        this.homeRightShow = this.homeGalleryShow = false;
      }
      if (showStatus == 2) {
        this.homeTopShow = this.homeGalleryShow = false;
        this.homeRightShow = true;
      }
      if (showStatus == 3) {
        this.homeGalleryShow = true;
        this.homeTopShow = this.homeRightShow = false;
        this.largeVolumeUid = "";
        window.localStorage.removeItem("IncentiveOldUid");
      }
      if (showStatus == 3) {
        window.localStorage.removeItem("fixedUid");
        this.fixedUid = "";
        this.subscribeUser = this.subscribeUser.filter(i => i.uid != this.uid);
        var index = this.findElem(this.subscribeUser, "uid", this.uid);
        index == -1 ? this.subscribeUser.push({ uid: this.uid }) : "";
        // this.subscribeUser.push({uid:this.uid});
        setTimeout(()=>{
          if (status == 1) {
            this.rtc.videoTrack.stop();
            this.rtc.videoTrack.play(document.getElementById(this.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
            this.subscribeUser.forEach(item => {
              if (item.uid != this.uid && item.hasVideo) {
                item.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror: false });
              }
            });
          }
        },300)
      } else if ( !window.localStorage.getItem("IncentiveOldUid") && !window.localStorage.getItem("fixedUid") && !window.localStorage.getItem("screenUid") && !this.isShare ) {
        this.subscribeUser = this.subscribeUser.filter(i => i.uid != this.uid);
        if (status == 1) {
          this.rtc.videoTrack.stop();
          this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
          this.$nextTick(() => {
            this.subscribeUser.forEach(item => {
              if(item.hasVideo && document.getElementById(item.uid) != null){
                item.videoTrack.stop();
                item.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror: false });
              }
            });
          })
        }
      }
    },

    fullScreen() {
      this.videoMainHeight = this.screenHeight;
      this.videoMainWidth = (this.screenHeight * 16) / 9;
    },
    unfullScreen(){
      this.fullScreenUid = "";
      window.localStorage.removeItem("fullScreenUid");
      this.jsvideoMainWidth();
      this.fullScreenMode = false;
    },

    /** * 计算主视频区域的宽高 */
    jsvideoMainWidth() {
      if (this.screenWidth < 1024) this.screenWidth = 1024;
      // if (this.screenHeight < 768) this.screenHeight = 768;
      let h = this.homeTopShow ? 160 : 0;
      let videoMainH = this.homeRightShow ? ((this.screenWidth - 290) * 9) / 16 : (this.screenWidth * 9) / 16;
      if (videoMainH > this.screenHeight - h - 45) {
        this.videoMainHeight = this.screenHeight - h - 45;
        this.videoMainWidth = ((this.screenHeight - h - 45) * 16) / 9;
      } else if (this.attendeesDomShow && this.homeRightShow) {
        // 同时存在参会列表和右侧视图
        this.videoMainWidth = this.screenWidth - 310 - 290;
        this.videoMainHeight = ((this.screenWidth - 310 - 290) * 9) / 16;
      } else if (this.homeRightShow) {
        this.videoMainWidth = this.screenWidth - 290;
        this.videoMainHeight = videoMainH;
      } else {
        this.videoMainWidth = this.screenWidth;
        this.videoMainHeight = videoMainH;
      }
    },

    /** 本地客户端初始化开始 */
    init() {
      //检测浏览器的兼容性
      const system = AgoraRTC.checkSystemRequirements();
      if (!system) {
        console.log("该浏览器不支持！");
        return;
      }
      //设置日志级别
      AgoraRTC.setLogLevel(4);
      //创建本地客户端 vp8
      this.rtc.client = AgoraRTC.createClient({ mode: "live", codec: "h264" });
      this.rtc.client.setClientRole(this.options.role);
      if (this.rtc.client != null) this.join_status.client_join = 1;
      // this.clientJoin();
    },
    clientJoin() {
      this.rtc.client.join( this.options.appId, this.options.channel, this.options.token, this.uid ).then(res => {
        console.log("client join true");
        this.back();
        this.getRTCStats();
        this.get_participant_list(1);
        this.participant_waiting_room(1);
        if (this.role === "watch") {
          return;
        }
        this.getDevices();
        setTimeout(() => {
          this.streamingConfig();
        }, 5000);
      });
    },

    /** 处理共享过程中加入 */
    shareInfo() {
      if (this.share_info.share == 1) {
        // 1共享中 0无人共享
        this.qiehuan(2);
        this.VoiceIncentive = 1;
        this.share_uid = parseInt(this.share_info.share_id);
        this.isShare = 1;
        var index = this.findElem(this.subscribeUser, "uid", this.uid);
        index == -1 ? this.subscribeUser.push({ uid: this.uid }) : "";
        let num = 0;
        this.shareingTimer = setInterval(() => {
          num += 1;
          if (num > 3) {
            clearInterval(this.shareingTimer);
          } else {
            this.subscribeUser.forEach(item => {
              if ( item.uid == this.share_info.share_id && this.uid != this.share_info.uid && item.hasVideo) {
                this.streamShareUid = this.share_info.uid;
                console.log( "Screen shareing join", this.share_info.uid, item.uid, num );
                this.rtc.videoTrack.stop();
                this.setRemoteVideoStreamType(item.uid, 0);
                item.videoTrack.play("videoMain", { fit: "contain" });
                clearInterval(this.shareingTimer);
              }
              if (item.uid == this.uid  ) {
                this.$nextTick(() => {
                  this.rtc.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
                })
                return;
              }
            });
          }
        }, 1000);
      }
    },

    /** * 初始化回调 */
    back() {
      var that = this;
      //用户加入会议的回调
      this.rtc.client.on("user-joined", async user => {
        console.log(user, "Users join meetings");
        this.liveUid = window.localStorage.getItem("liveUid");
        let oldUid = window.localStorage.getItem("oldUid");
        if (  oldUid && this.uid == JSON.parse(oldUid) && user.uid == JSON.parse(oldUid) ) {
          // console.log("用户加入uid == 共享人uid");
          return;
        }
        if (user.uid == this.uid) {
          // console.log("用户加入uid == 本地uid");
          return;
        }
        window.localStorage.getItem("homeViewShow") == 3 ? that.subscribeUser.splice(that.subscribeUser.length,0,user) 
        : that.subscribeUser.push(user);
        if (window.localStorage.getItem("homeViewShow") == 3) { // 处理画廊视图 播放画面
          // this.subscribeUser = this.subscribeUser.filter( i => i.uid != that.uid );
          // that.subscribeUser.push({ uid: that.uid });
            this.subscribeUser.forEach(item => {
              if(item.uid == this.uid){
                setTimeout(()=>{
                  that.rtc.videoTrack.stop();
                  that.rtc.videoTrack.play(document.getElementById(that.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
                },300)
              }
              if (item.uid != this.uid && item.hasVideo) {
                item.videoTrack.stop();
                item.videoTrack.play(document.getElementById(item.uid), { fit: "contain" })
              }
            });
        }
        that.showStatus();
        that.streamingConfig();
        that.get_participant_list(1);
        if (this.share_info.share == 1) that.shareInfo();
      });
     
     //用户离开会议回调
      this.rtc.client.on("user-left", async (user, reason) => {
        console.log(user.uid, reason, "User leaving the meeting");
        window.localStorage.removeItem("IncentiveOldUid");
        let oldUid = window.localStorage.getItem("oldUid");

        if(user.uid == window.localStorage.getItem("liveUid") && this.meetinfo.identity == 1) {
          this.liveUid = this.uid;
          this.localLiveStatus = 1;
          window.localStorage.setItem("liveUid",this.uid);
        }
        if(user.uid == oldUid){
          window.localStorage.removeItem("oldUid");
          this.subscribeUser = this.subscribeUser.filter(i => i.uid != oldUid);
          this.cancel_screen_sharing(0);
        }
        !window.localStorage.getItem("screenUid") && that.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
        this.subscribeUser = this.subscribeUser.filter( i => i.uid != that.share_uid );
        !window.localStorage.getItem("screenUid") ? this.subscribeUser = this.subscribeUser.filter(i => i.uid != that.uid) : '';

        if (window.localStorage.getItem("homeViewShow") == 3 || this.isShare == 1) {
          // 处理画廊视图用户离开不播放本地   处理共享中不播放自己
          that.subscribeUser.push({ uid: that.uid });
          this.subscribeUser = this.subscribeUser.filter( i => i.uid != user.uid );
          this.$nextTick(() => {
            that.rtc.videoTrack.stop();
            that.rtc.videoTrack.play(document.getElementById(that.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
          })
        }
        that.subscribeUser.forEach(item => {
          if (user.uid == item.uid) { // 判断离开用户是否是 选定视图 和 焦点视图 和 语音激励
            if ( item.uid == that.fixedUid || item.uid == that.screenUid || item.uid == window.localStorage.getItem("IncentiveOldUid") ) {
              console.log("离开用户是 选定视图 或 焦点视图")
              that.fixedUid = "";
              that.screenUid = "";
              window.localStorage.removeItem("fixedUid");
              window.localStorage.removeItem("screenUid");
              window.localStorage.removeItem("IncentiveOldUid");
              this.subscribeUser = this.subscribeUser.filter(  i => i.uid != user.uid );
              this.userList = this.userList.filter(i => i.uid != user.uid);
              that.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
            }
            if (user.uid == item.uid) {  // 清除离开用户
              this.subscribeUser = this.subscribeUser.filter( i => i.uid != user.uid );
              this.userList = this.userList.filter(i => i.uid != user.uid);
              // console.log("清除离开用户",that.subscribeUser)
            }
            that.get_participant_list(1);
            window.localStorage.getItem("shareUid")!=user.uid && this.leave(user.uid);
          }
        });
        
        that.subscribeUser.forEach(item => { // 处理用户离开有用户不播放
          if (item.uid != that.uid && item.uid != user.uid && item.hasVideo && item.uid != window.localStorage.getItem("screenUid")) {
            item.videoTrack.stop();
            item.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror: false });
          }
          if(window.localStorage.getItem("screenUid")){
            that.rtc.videoTrack.play(document.getElementById(that.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
          }
        });

        that.streamingConfig();
        that.showStatus();
        if (that.subscribeUser.length == 0) that.homeTopShow = that.homeRightShow = that.homeGalleryShow = false;
      });
      
      //发布音视频回调
      this.rtc.client.on("user-published", async (user, mediaType) => {
        console.log(user.uid, mediaType);
        let oldUid = window.localStorage.getItem("oldUid");
        if ( oldUid && this.uid == JSON.parse(oldUid) && user.uid == this.share_uid ) {
          this.shareloading = false;
          return;
        }
        if (user.uid == this.uid) {
          return;
        }
        that.subscribe(user, mediaType);
        console.log(user.uid,window.localStorage.getItem("shareUid"))
        if (user.uid == window.localStorage.getItem("shareUid")) {
          let num = 0;
          var index = this.findElem(this.subscribeUser, "uid", this.uid);
          index == -1 ? this.subscribeUser.push({ uid: this.uid }) : "";
          var sIndex = this.findElem(this.subscribeUser, "uid", this.share_uid);
          this.shareTimer = setInterval(() => {
            num += 1;
            if (num > 5 && sIndex == 1) {
              clearInterval(this.shareTimer);
            }
            this.subscribeUser.forEach(item => {
              if (item.uid == parseInt(this.share_uid) && item.hasVideo) {
                console.log( "Notification screen sharing", this.share_uid, item.uid );
                this.setRemoteVideoStreamType(item.uid, 0);
                this.rtc.videoTrack.stop();
                item.videoTrack.stop();
                item.videoTrack.play("videoMain", { fit: "contain" });
                this.isShowShareName = true;
                this.shareloading = false;
                clearInterval(this.shareTimer);
              } else if (item.uid == this.uid) {
                this.$nextTick(() => {
                  this.rtc.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
                })
                return;
              } else if(item.hasVideo){
                item.videoTrack.stop();
                setTimeout(()=>{
                // this.$nextTick(() => { 
                  item.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror: false }); 
                // })
                },200)
              }
            });
          }, 1000);
        }
      });
      
      //取消发布音视频回调
      this.rtc.client.on("user-unpublished", async (user, mediaType) => {
        console.log(user.uid, mediaType, "取消");
        let oldUid = window.localStorage.getItem("oldUid");
        if ( oldUid && this.uid == JSON.parse(oldUid) && user.uid == this.share_uid ) {
          this.shareloading = false;
          return;
        }
      });
      
      // 监听推流中途的错误, 错误码列表可以参考 API 文档
      this.rtc.client.on("live-streaming-error", (url, err) => console.log("url", url, "live streaming error!", err.code) );
      
      // 本地用户加入离开回调
      this.rtc.client.on( "connection-state-change", (curState, revState, reason) => {
          console.log(curState, revState, reason);
          if (curState == "DISCONNECTED" || curState == "DISCONNECTING") {
            if (this.meetinfo.identity != 3) {
              this.rtc.videoTrack.close();
              this.rtc.audioTrack.close();
            }
            window.localStorage.removeItem("fixedUid");
            window.localStorage.removeItem("liveUid");
            this.rtc.videoTrack.close();
            this.rtc.audioTrack.close();
            // this.$router.push({name:'loginout'});
          }
        }
      );
      
      //token过期回调
      this.rtc.client.on("token-privilege-did-expire", () => {
        this.get_agoralo_token();
        console.log("toke已过期");
      });

      // 语音激励
      // this.enableAudioVolumeIndicator();

      // 检测有摄像头被添加或移除。
      AgoraRTC.onCameraChanged = async info => {
        console.log("camera changed!", info.state, info.device);
        this.Cameras = await AgoraRTC.getCameras(); 
        // 插入设备时，切换到新插入的设备。
        if (info.state === "ACTIVE") { 
          this.setCurrentCameraDeviceId(info.device.deviceId);
          // 拔出设备为当前设备时，切换到一个已有的设备。
        } else if (info.device.label === this.rtc.videoTrack.getTrackLabel()) {
          const oldCameras = await AgoraRTC.getCameras();
          this.Cameras = oldCameras;
          // console.log(this.rtc.videoTrack.getTrackLabel(), oldCameras);
          oldCameras[0] && this.setCurrentCameraDeviceId(oldCameras[0].deviceId);
        }
      };

      // 检测有麦克风被添加或移除。
      AgoraRTC.onMicrophoneChanged = async info => {
        console.log("microphone changed!", info.state, info.device);
        this.Microphones = await AgoraRTC.getMicrophones();
        // 插入设备时，切换到新插入的设备。
        if (info.state === "ACTIVE") {
          this.setCurrentMicDeviceId(info.device.deviceId);
          // 拔出设备为当前设备时，切换到一个已有的设备。
        } else if (info.device.label === this.rtc.audioTrack.getTrackLabel()) {
          const oldMicrophones = await AgoraRTC.getMicrophones();
          this.Microphones = oldMicrophones;
          // console.log(this.rtc.audioTrack.getTrackLabel(), oldMicrophones);
          oldMicrophones[0] && this.setCurrentMicDeviceId(oldMicrophones[0].deviceId);
        }
      };

    },

    /** 定期返回正在说话的远端用户及其音量 */
    enableAudioVolumeIndicator() {
      this.rtc.client.enableAudioVolumeIndicator();
      let arr = [];
      let oldUid = "";
      window.localStorage.setItem("IncentiveOldUid", oldUid);
      this.rtc.client.on("volume-indicator", volumes => {
        volumes.forEach((volume, index) => {
          /* console.log(`${index} UID ${volume.uid} Level ${volume.level}`); */
        });
        if (this.VoiceIncentive == 0) {
          arr.push(volumes.sort(this.compare("level"))[0].uid);
          if (window.localStorage.getItem("IncentiveOldUid") != arr[0]) {
            oldUid = arr[0];
            console.log(oldUid, "替换语音激励用户");
          }
          if (arr.length > 2) {
            this.largeVolumeUid = oldUid;
            if ( arr.every(item => item === oldUid) && oldUid != window.localStorage.getItem("IncentiveOldUid") && window.localStorage.getItem("homeViewShow") != 3 ) {
              window.localStorage.setItem("IncentiveOldUid", oldUid);
              arr = [];
              this.subscribeUser.forEach(item => {
                if (this.uid == oldUid) {
                  this.setRemoteVideoStreamType(item.uid, 1);
                  this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
                  this.subscribeUser = this.subscribeUser.filter( i => i.uid != this.uid );
                  this.subscribeUser.forEach(i => {
                    i.hasVideo && i.videoTrack.stop();
                    i.hasVideo && this.$nextTick(() => { i.videoTrack.play(document.getElementById(i.uid), { fit: "contain" }); })
                  });
                } else if (item.uid == oldUid) {
                  this.subscribeUser = this.subscribeUser.filter( i => i.uid != this.uid );
                  this.subscribeUser.forEach(i => {
                    if (i.uid != item.uid && i.hasVideo) {
                      i.videoTrack.stop();
                      this.$nextTick(() => { i.videoTrack.play(document.getElementById(i.uid), { fit: "contain" }); })
                    }
                  });
                  this.subscribeUser.push({ uid: this.uid });
                  this.$nextTick(() => {
                    this.rtc.videoTrack.play( document.getElementById(this.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true } );
                  })

                  this.rtc.videoTrack.stop();
                  this.subscribeUser.forEach(i => {
                    if (i.uid == item.uid && i.hasVideo) {
                      this.setRemoteVideoStreamType(item.uid, 0);
                      this.$nextTick(() => { i.videoTrack.play("videoMain", { fit: "contain" }); })
                    }
                  });
                }
              });
              return;
            } else {
              arr = [];
            }
          }
        }
      });
    },
    compare(property) {
      return function(a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value2 - value1;
      };
    },

    /** 获取声网token */
    get_agoralo_token() {
      this.$store.dispatch("get_agoralo_token", {
        channel_id: this.options.channel,
        uid: this.uid
      }).then(res => {
        if (res.data.code == 200) {
          this.options.token = res.data.data.token;
        }
      });
    },

    /** 开始/结束直播 */
    liveing() {
      if(this.pushRtmp  == "" && !this.isLive){
        this.dialogStudioVisible = true;
      }else{
        this.isLive ? this.pushStop() : this.pushStart();
      }
    },

    //  获取轻直播推流地址
    get_live_studio_push_url(){
      this.$store.dispatch("get_live_studio_push_url",{studio_id:this.studio_id,meeting_id:this.options.channel}).then(res=>{
        if(res.data.code == 200){
          this.pushRtmp = res.data.data.push_url;
          console.log(this.pushRtmp)
          this.dialogStudioVisible = false;
          this.pushStart();
          this.$message({
            message: res.data.message,
            type: "success"
          });
        }else{
          this.$message({
            message: res.data.error,
            type: "error"
          });
          if(res.data.error == '您暂未绑定轻直播账号!'){
            this.bindingShow = true;
          }else{
             this.dialogStudioVisible = false;
          }
        }
      }).catch(err=>{})
    },

    /** 开始CDN推流 */
    pushStart() {
      var _this = this;
      _this.$confirm("此操作将开启直播，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
          // console.log(_this.pushRtmp)
          _this.rtc.client.startLiveStreaming(_this.pushRtmp, true).then(() => {
            console.log("start live streaming success");
            _this.$message({
              message: "直播成功！",
              type: "success"
            });
            _this.isLive = true;
            _this.live_btn_text = "停止直播";
            _this.liveStatus = 1;
          }).catch(err => {
            console.log(err);
          });
        },() => {});
    },

    /** 停止CDN推流 */
    pushStop() {
      var _this = this;
      _this.$confirm("此操作将停止直播，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
          _this.rtc.client.stopLiveStreaming(_this.pushRtmp).then(() => {
            console.log("stop live streaming success");
            _this.$message({
              message: "直播已停止！",
              type: "success"
            });
            _this.isLive = false;
            _this.live_btn_text = "开始直播";
            _this.liveStatus = 0;
          });
        },() => {});
    },

    /** CDN推流配置设置 */
    streamingConfig() {
      // console.log(this.subscribeUser,window.localStorage.getItem("liveUid"))
      let liveUid = window.localStorage.getItem("liveUid");
      let oldUid = window.localStorage.getItem("oldUid"); // 发起共享人uid
      var arr = JSON.stringify(this.subscribeUser);
      arr = JSON.parse(arr);
      this.userListSet.forEach(item => {
        arr.forEach((i, j) => {
          if (item.uid == i.uid && item.VideoShow == 0) arr.splice(j, 1);
        });
      });
      // console.log(arr,this.subscribeUser,this.userListSet)
      if (this.isShare) {
        console.log("CDN Shared screen configuration ", arr, oldUid);
        if (liveUid) {
          console.log(liveUid,"CDN Shared screen configuration personal screen");
          arr = arr.filter(i => i.uid == liveUid);
          let obj = { uid: this.share_uid };
          arr.unshift(obj);
        } else {
          arr = [{ uid: this.share_uid }, { uid: oldUid }];
        }
      } else {
        if (liveUid) {
          arr = arr.filter(i => i.uid == liveUid);
          var index = this.findElem(this.subscribeUser, "uid", this.uid);
          liveUid == this.uid && index == -1 ? arr.push({ uid: this.uid }) : "";
          console.log(liveUid, "CDN configuration a personal screen", arr);
        } else {
          var index = this.findElem(this.subscribeUser, "uid", this.uid);
          index == -1 ? arr.push({ uid: this.uid }) : "";
        }
      }
      var config = [];
      // console.log(arr)
      if (arr.length > 16) {
        return;
      }
      if (this.isShare) {
        switch (this.live_share_view) {
          case 0: // 左右中
            config = liveUserConfig.live_share_view_0;
            break;
          case 1: // 左中右
            config = liveUserConfig.live_share_view_1;
            break;
          case 2: // 上中下
            config = liveUserConfig.live_share_view_2;
            break;
          case 3: // 上下中
            config = liveUserConfig.live_share_view_3;
            break;
        }
        // switch (arr.length){
        //   case 2 :
        //     config = liveUserConfig.shareUserConfig_2;
        //     break;
        //   case 3 :
        //     config = liveUserConfig.shareUserConfig_3;
        //     break;
        //   case 4 :
        //     config = liveUserConfig.shareUserConfig_4;
        //     break;
        //   case 5 :
        //     config = liveUserConfig.shareUserConfig_5;
        //     break;
        //   case 6 :
        //     config = liveUserConfig.shareUserConfig_6;
        //     break;
        //   case 7 :
        //     config = liveUserConfig.shareUserConfig_7;
        //     break;
        //   case 8 :
        //     config = liveUserConfig.shareUserConfig_8;
        //     break;
        //   case 9 :
        //     config = liveUserConfig.shareUserConfig_9;
        //     break;
        //   case 10 :
        //     config = liveUserConfig.shareUserConfig_10;
        //     break;
        // }
        for (var i = 0; i < arr.length; i++) config[i].uid = String( arr[i].uid );
      } else {
        if (this.live_default_view != 0 || liveUid != null) {
          switch (arr.length) {
            case 1:
              config = liveUserConfig.UserConfig_1;
              break;
            case 2:
              config = liveUserConfig.UserConfig_2;
              break;
            case 3:
              config = liveUserConfig.UserConfig_3;
              break;
            case 4:
              config = liveUserConfig.UserConfig_4;
              break;
            case 5:
              config = liveUserConfig.UserConfig_5;
              break;
            case 6:
              config = liveUserConfig.UserConfig_6;
              break;
            case 7:
              config = liveUserConfig.UserConfig_7;
              break;
            case 8:
              config = liveUserConfig.UserConfig_8;
              break;
            case 9:
              config = liveUserConfig.UserConfig_9;
              break;
            case 10:
              config = liveUserConfig.UserConfig_10;
              break;
            case 11:
              config = liveUserConfig.UserConfig_11;
              break;
            case 12:
              config = liveUserConfig.UserConfig_12;
              break;
            case 13:
              config = liveUserConfig.UserConfig_13;
              break;
            case 14:
              config = liveUserConfig.UserConfig_14;
              break;
            case 15:
              config = liveUserConfig.UserConfig_15;
              break;
            case 16:
              config = liveUserConfig.UserConfig_16;
              break;
          }
        } else if (this.live_default_view == 0) {
          if (window.localStorage.getItem("screenUid")) {
            arr = arr.filter(
              i => i.uid != window.localStorage.getItem("screenUid")
            );
            arr.unshift({
              uid: window.localStorage.getItem("screenUid")
            });
            // console.log(arr)
          } else {
            arr = arr.filter(i => i.uid != this.uid);
            arr.unshift({ uid: this.uid });
          }
          switch (arr.length) {
            case 1:
              config = liveUserConfig.UserConfig_1;
              break;
            case 2:
              config = liveUserConfig.live_default_view_2;
              break;
            case 3:
              config = liveUserConfig.live_default_view_3;
              break;
            case 4:
              config = liveUserConfig.live_default_view_4;
              break;
            case 5:
              config = liveUserConfig.live_default_view_5;
              break;
            case 6:
              config = liveUserConfig.live_default_view_6;
              break;
            case 7:
              config = liveUserConfig.live_default_view_7;
              break;
            case 8:
              config = liveUserConfig.live_default_view_8;
              break;
            case 9:
              config = liveUserConfig.live_default_view_9;
              break;
            case 10:
              config = liveUserConfig.live_default_view_10;
              break;
            case 11:
              config = liveUserConfig.live_default_view_11;
              break;
            case 12:
              config = liveUserConfig.live_default_view_12;
              break;
            case 13:
              config = liveUserConfig.live_default_view_13;
              break;
            case 14:
              config = liveUserConfig.live_default_view_14;
              break;
            case 15:
              config = liveUserConfig.live_default_view_15;
              break;
            case 16:
              config = liveUserConfig.live_default_view_16;
              break;
          }
        }
        for (var i = 0; i < arr.length; i++) config[i].uid = String( arr[i].uid );
      }
      LiveTranscoding.transcodingUsers = config;
      LiveTranscoding.userCount = arr.length;
      this.rtc.client.setLiveTranscoding(LiveTranscoding).then(() => {
        console.log("set live transcoding success");
      });
    },

    /** 获取客户端媒体设备 */
    getDevices() {
      var that = this;
      let hasV = false;
      let hasA = false;
      AgoraRTC.getCameras().then(Cameras => {
        that.Cameras = Cameras;
        hasV = true;
        that.currentCameraDeviceId = Cameras[this.CameraUse].deviceId;
      }).catch(e => {
        console.log("get Cameras error!", e);
      });
      AgoraRTC.getMicrophones().then(Microphones => {
        hasA = true;
        that.Microphones = Microphones;
        that.currentMicDeviceId = Microphones[this.MicUse].deviceId;
      }).catch(e => {
        console.log("get Microphones error!", e);
      });
      AgoraRTC.getPlaybackDevices().then(PlaybackDevices => {
        that.PlaybackDevices = PlaybackDevices;
        that.currentPlaybackDevices = PlaybackDevices[this.SpeakerUse].deviceId;
      }).catch(e => {
        console.log("get PlaybackDevices error!", e);
      });
      let num = 0;
      this.DevTimer = setInterval(()=>{
        num += 1;
        if(hasV && hasA && this.rtc.videoTrack == null && this.rtc.audioTrack == null){
          this.createCameraVideoTrack();
          clearInterval(this.DevTimer);
          num = 0;
        }else if(num >= 10){
          this.$alert("请检查摄像头和麦克风权限是否打开！", "提示", {
            confirmButtonText: "确定",
            type:"warning"
          });
          this.video_change(this.uid,0);
          this.scene_change(this.uid,0);
          this.viewLoading = false;
          this.localCameraShow = false;
          this.localAudioShow = false;
          clearInterval(this.DevTimer);
          num = 0;
        }
      },500)
      // setTimeout(() => {
      //   if(hasV && hasA && this.rtc.videoTrack == null && this.rtc.audioTrack == null){
      //     this.createCameraVideoTrack();
      //   }else{
      //     this.$alert("请检查摄像头和麦克风权限是否打开！", "提示", {
      //       confirmButtonText: "确定",
      //       type:"warning"
      //     });
      //     this.video_change(this.uid,0);
      //     this.scene_change(this.uid,0);
      //     this.viewLoading = false;
      //     this.localCameraShow = false;
      //     this.localAudioShow = false;
      //   }
      // }, 500);
    },

    /** 通过摄像头和麦克风创建视频/音频轨道对象 */
    async createCameraVideoTrack(domId = "videoMain") {
      var that = this;
      if (!this.rtc.audioTrack || !this.rtc.videoTrack) {
        [this.rtc.audioTrack, this.rtc.videoTrack] = await Promise.all([
          // 使用麦克风和摄像机创建本地轨道   720p_3
          AgoraRTC.createMicrophoneAudioTrack({ microphoneId: that.currentMicDeviceId }),
          AgoraRTC.createCameraVideoTrack({ cameraId: that.currentCameraDeviceId, encoderConfig: "720p_3"})
        ]);
      }

      if(this.dual_stream == 1){
        // 开启双流模式。
        this.rtc.client.enableDualStream().then(() => {
          console.log("Enable Dual stream success!");
        }).catch(err => console.log(err) );
      }
      // this.rtc.client.setLowStreamParameter(910,15,960,720) // bitrate framerate width height 小流配置
      // this.rtc.videoTrack.setBeautyEffect(true); // 美颜
      // this.rtc.videoTrack.setVideoProfile("320p_3");
      //   视频传输优化模式：
      //     "balanced": 使用默认的传输优化模式：
      //          对于屏幕共享视频流，SDK 默认的优化策略为清晰优先。
      //          对于其他视频流，SDK 默认的优化策略为兼顾清晰和流畅，弱网条件下，帧率和分辨率都会被调整。
      //     "detail": 清晰优先。
      //          SDK 会自动根据你的采集分辨率和帧率设定一个最小码率。即使遭遇网络波动，发送码率也不会低于这个值，从而确保清晰的视频画面。
      //          大部分情况下，SDK 不会降低发送分辨率，但是可能会降低帧率。
      //     "motion": 流畅优先。
      //          SDK 不会启用最小码率策略。遭遇网络波动时，发送端会降低码率来确保接收端的视频画面不会出现中断和卡顿。
      //          大部分情况下，SDK 不会降低帧率，但是可能会降低发送分辨率。
      // this.rtc.videoTrack.setOptimizationMode("motion");

      this.rtc.client.publish([this.rtc.videoTrack, this.rtc.audioTrack]);
      this.setRtc = this.rtc;

      let oldUid = window.localStorage.getItem("oldUid");
      if(window.localStorage.getItem('unloadStatus') == 1){
        oldUid == this.uid && this.cancel_screen_sharing(1);
        oldUid == this.uid && this.screenClient.leave();
        this.rtc.client.leave();
        this.leave(this.uid);
        this.clearLocal();
        window.localStorage.removeItem("screenUid");
        window.localStorage.removeItem('unloadStatus');
        this.$router.push({ name: "loginout" });
        return
      }

      // 登录为开始摄像头 或 麦克风 禁用
      setTimeout(()=>{
        this.viewLoading = false;
        if (window.localStorage.getItem("homeViewShow") == 3 && this.localCameraShow) {
          // 处理画廊视图 播放画面（加入用户位置和本地位置）
          // this.subscribeUser = this.subscribeUser.filter( i => i.uid != that.uid );
          // that.subscribeUser.push({ uid: that.uid });
          this.rtc.videoTrack.setEnabled(true); 
          setTimeout(()=>{
            this.rtc.videoTrack.play(document.getElementById(this.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
          },200)
        }
        if (!this.localCameraShow ) {
          this.rtc.videoTrack.stop();
          this.rtc.videoTrack.setEnabled(false); 
        } else {
          this.rtc.videoTrack.setEnabled(true); 
          this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
        }
        if (!this.localAudioShow) {
          this.rtc.audioTrack.stop();
          this.rtc.audioTrack.setEnabled(false); 
        }
      },300)

      if(window.localStorage.getItem("liveSetInfo")){
        let obj = JSON.parse(window.localStorage.getItem("liveSetInfo"));
        this.live_share_view = obj.liveShareView;
        this.live_default_view = obj.liveDefaultView;
        this.getchangeLiveSet(obj);
      }

      that.homeInterval1 = setInterval(function() {
        let num = that.rtc.audioTrack.getVolumeLevel();
        that.homeVolumeLevel = parseInt(num * 2000);
      }, 500);
    },
    // 参会者点击事件
    participantClick() {
      this.attendeesDomShow = !this.attendeesDomShow;
      this.attendeesDomShow ? (this.userList_left = 0) : (this.userList_left = -310);
      this.MicListDomShow = this.cameraListDomShow = this.leaveMeetShow = this.viewDomShow = false;
      this.userList = [];
      this.user_list_page = 1;
      this.jsvideoMainWidth();
    },
    // 开启关闭 参会者列表 摄像头
    openCloseRemoteView(item, index) {
      let obj = {
        type: "agora_public_notice",
        meeting_id: this.options.channel,
        data: {
          type: "",
          data: {
            uid: item.uid,
            type: item.uid == this.uid ? 0 : 1,
          }
        }
      };
      if (this.userListSet[index].VideoShow == 0) {
        obj.data.type = "videoTrack_play";
        this.publicNotice = obj;
      } else {
        obj.data.type = "videoTrack_setEnabled";
        this.publicNotice = obj;
      }
      this.userListSet[index].More = false;
    },
    //开启关闭 参会者列表 音频
    openCloseRemoteAudio(item, index) {
      let obj = {
        type: "agora_public_notice",
        meeting_id: this.options.channel,
        data: {
          type: "",
          data: {
            uid: item.uid,
            type: item.uid == this.uid ? 0 : 1,
          }
        }
      };
      if (this.userListSet[index].AudioShow == 0) {
        obj.data.type = "audioTrack_setEnabled_true";
        this.publicNotice = obj;
      } else {
        obj.data.type = "audioTrack_setEnabled_false";
        this.publicNotice = obj;
      }
      this.userListSet[index].More = false;
    },
    //开启关闭 参会者列表 焦点视图
    mainRemoteScreenTrue(item, index) {
      this.userListSet[index].BlowUp = this.userListSet[index].BlowUp == 1 ? 0 : 1;
      let obj = {
        type: "agora_public_notice",
        meeting_id: this.options.channel,
        data: {
          type: "",
          data: {
            uid: item.uid,
            data: item
          }
        }
      };
      if (this.userListSet[index].BlowUp == 0) {
        obj.data.type = "main_screen_true";
        this.publicNotice = obj;
      } else {
        obj.data.type = "main_screen_false";
        this.publicNotice = obj;
      }
      this.userListSet[index].More = false;
    },
    //参会者列表 移除参会者
    removeRemoteParticipants(item, index) {
      let obj = {
        type: "agora_public_notice",
        meeting_id: this.options.channel,
        data: {
          type: "remove_participants",
          data: {
            uid: item.uid
          }
        }
      };
      this.publicNotice = obj;
      this.userListSet[index].More = false;
    },
    // 参会者列表 更多
    moreSet(item, index) {
      this.userListSet.forEach((i, j) => (index != j ? (i.More = false) : ""));
      this.userListSet[index].More = !this.userListSet[index].More;
    },

    // 设为直播画面
    setLiveCoverage(uid, index) {
      if (uid == window.localStorage.getItem("liveUid")) {
        if (uid == this.uid) {
          this.localLiveStatus = 0;
          this.userListSet[0].live = 0;
        }
        index ? (this.userListSet[index].live = 0) : "";
        window.localStorage.removeItem("liveUid");
        this.liveUid = "";
        this.streamingConfig();
      } else {
        index ? this.userListSet.forEach(item => (item.live = 0)) : "";
        index ? (this.userListSet[index].live = 1) : "";
        if (uid == this.uid) {
          this.localLiveStatus = 1;
          this.userListSet[0].live = 1;
        } else {
          this.localLiveStatus = 0;
          this.userListSet[0].live = 0;
        }
        window.localStorage.setItem("liveUid", uid);
        this.liveUid = uid;
        this.streamingConfig();
        console.log( "Set the list to live users index", index, this.userListSet[index] );
      }
    },

    // 开启关闭本地视频预览
    openCloseView(domId = "videoMain") {
      if(this.rtc.videoTrack == null){
        this.$alert("请检查摄像头和麦克风权限是否打开！", "提示", {
          confirmButtonText: "确定",
          type:"warning"
        });
      }else{
        if (this.localCameraShow) {
          this.localCameraShow = false;
          this.$router.push({
            query: merge(this.$route.query, { Camera: 1 })
          });
        } else {
          this.localCameraShow = true;
          this.$router.push({
            query: merge(this.$route.query, { Camera: 0 })
          });
        }
        this.cameraListDomShow = false;
        let obj = {
          type: "agora_public_notice",
          meeting_id: this.options.channel,
          data: {
            type: "",
            data: {
              uid: this.uid,
              type:0
            }
          }
        };
        domId = this.ScreenShare ? this.uid : domId;
        if (this.localCameraShow) {
          this.$nextTick(() => {
            this.rtc.videoTrack.play(domId, { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true }); 
            this.rtc.videoTrack.setEnabled(true);
          })
          obj.data.type = "videoTrack_play";
          this.publicNotice = obj;
        } else {
          this.rtc.videoTrack.stop();
          this.rtc.videoTrack.setEnabled(false);
          obj.data.type = "videoTrack_setEnabled";
          this.publicNotice = obj;
        }
      }
    },

    // 开启关闭本地音频预览
    openCloseAudio() {
      if(this.rtc.audioTrack == null){
        this.$alert("请检查摄像头和麦克风权限是否打开！", "提示", {
          confirmButtonText: "确定",
          type:"warning"
        });
      }else{
        if (this.localAudioShow) {
          this.localAudioShow = false;
          this.$router.push({
            query: merge(this.$route.query, { Mic: 0 })
          });
        } else {
          this.localAudioShow = true;
          this.$router.push({
            query: merge(this.$route.query, { Mic: 1 })
          });
        }
        
        this.MicListDomShow = false;
        let obj = {
          type: "agora_public_notice",
          meeting_id: this.options.channel,
          data: {
            type: "",
            data: {
              uid: this.uid,
              type:0
            }
          }
        };
        if (this.localAudioShow) {
          this.rtc.audioTrack.setEnabled(true);
          obj.data.type = "audioTrack_setEnabled_true";
          this.publicNotice = obj;
        } else {
          this.rtc.audioTrack.setEnabled(false);
          obj.data.type = "audioTrack_setEnabled_false";
          this.publicNotice = obj;
        }
      }
    },
    fixMouseEnter(){
      clearInterval(this.fixTimer)
      this.controlField = true;
    },
    fixMouseLeave() {
      this.fixTimer = setInterval(()=>{
        this.menuStatusShow == 1 ? this.controlField = false : this.controlField = true;
        this.cameraListDomShow = this.MicListDomShow = this.leaveMeetShow = false;
      },5000)
    },

    /** 底部摄像头快捷列表 */
    cameraList() {
      this.cameraListShow = !this.cameraListShow;
    },

    /** 底部麦克风快捷列表 */
    MicList() {
      this.MicListShow = !this.MicListShow;
    },

    /** 切换摄像头 */
    changeCamera(deviceId) {
      this.rtc.videoTrack.setDevice(deviceId);
    },

    /** 设置订阅视频 大小流 */
    setRemoteVideoStreamType(uid, status) {
      if(this.dual_stream == 1){
        // 1.小流 0 大流
        this.rtc.client.setRemoteVideoStreamType(uid, status).then(() => {
          // console.log("设置大小流成功", uid, status);
        });

        // 设置远端用户音视频流回退策略。
        // 2: 网络较弱时，先尝试订阅视频小流。如果网络环境无法显示视频，则再回退到订阅纯音频流。
        // 0: 关闭回退策略，弱网时不对音视频流作回退处理。
        // 1:（默认）网络条件较差的情况下，SDK 将自动订阅视频小流。
        this.rtc.client.setStreamFallbackOption(uid, 1);
      }
    },

    /** 开始订阅 */
    async subscribe(user, mediaType) {
      await this.rtc.client.subscribe(user, mediaType);
      user.uid != parseInt(this.share_uid) && this.setRemoteVideoStreamType(user.uid, 1);
      // 表示本次订阅的是视频。
      if (mediaType === "video") {
        // 订阅完成后，从 `user` 中获取远端视频轨道对象。
        const remoteVideoTrack = user.videoTrack;
        // 订阅完成，播放远端音视频。 也可以只传入该 DIV 节点的 ID。
        if (user.uid != this.uid) {
          // console.log(document.getElementById(user.uid),user);
          // clearInterval(this.subscribeTimer)
          // this.subscribeTimer = setInterval(()=>{
            setTimeout(()=>{
            // console.log(this.subscribeTimer)
            if(document.getElementById(user.uid) != null){
              // clearInterval(this.subscribeTimer);
               this.$nextTick(() => {
                remoteVideoTrack.play(document.getElementById(user.uid), { fit: "contain", mirror: false }); // mirror设置是否开启镜像模式：true / false
              })
            }
          // },0)
        },300)
        }
      }
      // 表示本次订阅的是音频。
      if (mediaType === "audio") {
        // 订阅完成后，从 `user` 中获取远端音频轨道对象。
        const remoteAudioTrack = user.audioTrack;
        // 播放音频因为不会有画面，不需要提供 DOM 元素的信息。
        remoteAudioTrack.play();
      }
    },

    /** 离开会议室 */
    leaveMeeting() {
      this.out_meeting("leave");
      // this.rtc.client.leave();
      // this.clearLocal();
    },
    /** 离开会议/结束会议 */
    out_meeting(type) {
      this.leaveMeetShow = false;
      let str = "";
      type == "leave" ? (str = "离开会议") : type == "over" ? (str = "结束会议") : "";
      if ( window.localStorage.getItem("oldUid") == this.uid && this.isShare == 1 ) {
        this.$alert("请先停止您的共享！", "提示", {
          confirmButtonText: "确定"
        });
      } else {
        this.$confirm("此操作将" + str + ", 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then( () => {
          this.$store.dispatch("out_meeting", {
            id:window.localStorage.getItem('meetinfoId'),
            meeting_id: this.options.channel,
            uid: this.options.uid,
            type: type,
          }).then(res => {
            if (res.data.code == 200) {
              if (type == "leave") {
                this.rtc.client.leave();
                this.clearLocal();
                this.$router.push({ name: "loginout" });
                window.localStorage.removeItem("screenUid");
              }
              if (type == "over") {
              let obj = {
                type: "agora_public_notice",
                meeting_id: this.options.channel,
                data: {
                  type: "over_live"
                }
              };
              this.publicNotice = obj;
              if ( window.localStorage.getItem("shareUid") != "" && window.localStorage.getItem("oldUid") )
                this.cancel_screen_sharing(1);
              }
            }
          });
        }, () => {});
      }
    },
    leave(uid) {
      this.$store.dispatch("out_meeting", {
        id:window.localStorage.getItem('meetinfoId'),
        meeting_id: this.options.channel,
        uid: uid,
        type: "leave"
      }).then(res => {});
    },
    clearLocal() {
      if(this.dual_stream == 1){
        this.rtc.client.disableDualStream(); // 关闭双流模式
      }
      window.localStorage.removeItem("oldUid");
      window.localStorage.removeItem("uid");
      // window.localStorage.setItem("token",'');
      window.localStorage.removeItem("meetinfo");
      window.localStorage.removeItem("homeViewShow");
      window.localStorage.removeItem("shareUid");
      window.localStorage.removeItem("IncentiveOldUid");
      window.localStorage.removeItem("liveUid");
      window.localStorage.removeItem("liveSetInfo");
      // this.rtc.videoTrack.close();
      // this.rtc.audioTrack.close();
    },
    /**
     * 获取当前通话的统计信息
     * Duration 在当前频道内的时长，单位为秒。
     * OutgoingAvailableBandwidth 上行可用带宽估计，单位为 Kbps
     * RTT SDK 到 Agora 边缘服务器的 RTT (Round-Trip Time)，单位 ms。
     * RecvBitrate 音视频总接收码率，单位为 bps，瞬间值。
     * RecvBytes 接收字节数，累计值。
     * SendBitrate 音视频总发送码率，单位为 bps，瞬间值。
     * SendBytes 发送字节数，累计值。
     * UserCount 通信场景下，该值为当前频道内的用户人数。直播场景下，如果本地用户为主播，该值为当前频道内的主播人数；如果本地用户为观众，该值为当前频道内的主播人数 + 1。
     */
    getRTCStats() {
      var that = this;
      setInterval(() => {
        let res = that.rtc.client.getRTCStats();
        that.RTCStats = res;
      }, 1000);
    },

    /** 设置当前使用的摄像头 */
    setCurrentCameraDeviceId(DeviceId) {
      this.cameraListDomShow = false;
      if (this.currentCameraDeviceId == DeviceId) {
        return;
      } else {
        let index = null;
        this.Cameras.forEach((i, j) => i.deviceId == DeviceId ? (index = j) : (index = 0) );
        this.$router.push({
          query: merge(this.$route.query, { CameraUse: index })
        });
        this.viewLoading = true;
        this.device_switch_text = "正在切换摄像头设备...";
        this.currentCameraDeviceId = DeviceId;
        this.rtc.videoTrack.setDevice(DeviceId).then(res => {
          this.viewLoading = false;
        });
      }
    },

    /** 设置当前使用的麦克风 */
    setCurrentMicDeviceId(DeviceId) {
      this.MicListDomShow = false;
      if (this.currentMicDeviceId == DeviceId) {
        return;
      } else {
        let index = null;
        this.Microphones.forEach((i, j) => i.deviceId == DeviceId ? (index = j) : (index = 0) );
        this.$router.push({
          query: merge(this.$route.query, { MicUse: index })
        });
        this.viewLoading = true;
        this.device_switch_text = "正在切换麦克风设备...";
        this.currentMicDeviceId = DeviceId;
        this.rtc.audioTrack.setDevice(DeviceId).then(res => {
          this.viewLoading = false;
        });
      }
    },

    /**  取消共享 */
    cancel_screen_sharing(type) {
      this.shareText = "正在结束共享...";
      this.shareloading = true;
      this.isShowShareName = false;
      this.$store.dispatch("cancel_screen_sharing", {
        meeting_id: this.options.channel
      }).then(res => {
        if (res.data.code == 200) {
          this.isShare = 0;
          this.ScreenShare = false;
          this.isShowShareName = false;
          this.shareloading = false;
          this.liveUid = "";
          this.localLiveStatus = 0;
          type == 1 ? this.localScreenTrack.close() : ''; 
          window.localStorage.removeItem("liveUid");
          window.localStorage.removeItem("oldUid");
          this.screenClient.leave();
        } else {
          this.shareloading = false;
          this.ScreenShare = false;
          this.isShowShareName = false;
        }
      });
    },

    /** 监听浏览器默认行为  停止屏幕共享  */
    screenClientBack() {
      this.localScreenTrack.on("track-ended", () => {
        this.cancel_screen_sharing(1);
      });
    },

    /** 创建屏幕共享视频轨道 * 开始共享 */
    createScreenTrack() {
      this.get_participant_list(1);
      if (!this.browserCompatibility) {
        this.$message({
          message: "当前浏览器版本不支持共享！",
          type: "error"
        });
      } else {
        // 判断 焦点或选定视图 过程中共享 还原主屏显示
        if (  window.localStorage.getItem("screenUid") || window.localStorage.getItem("fixedUid") ) {
          this.userList.forEach((item, index) => {
            if (item.uid == window.localStorage.getItem("screenUid")) this.mainRemoteScreenTrue(item, index);
            if (item.uid == window.localStorage.getItem("fixedUid")) this.setScreenOut(item, index);
          });
        }
        this.$store.dispatch("screen_sharing", {
          uid: this.uid,
          meeting_id: this.options.channel
        }).then(res => {
          if (res.data.code == 200) {
            this.shareloading = true;
            this.share_uid = res.data.data.share_id;
            this.share_uesrname = res.data.data.participant_info.name;
            this.isShare = 1;
            this.createScreen(
              res.data.data.agora_token,
              res.data.data.share_id
            );
            this.streamShareUid = res.data.data.participant_info.uid;
            window.localStorage.setItem("oldUid", res.data.data.participant_info.uid);
            window.localStorage.setItem("shareUid", res.data.data.share_id);
            // 处理焦点不在本地视图 共享
            if (this.screenUid != "" || this.fixedUid != "") {
              this.subscribeUser.forEach((item, index) => {
                this.rtc.videoTrack.stop();
                this.$nextTick(() => {
                  this.rtc.videoTrack.play("videoMain", { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
                })
                let uid = "";
                if (this.screenUid != "") {
                  this.publicNotice = {
                    type: "agora_public_notice",
                    meeting_id: this.options.channel,
                    data: {
                      type: "main_screen_false",
                      data: { uid: item.uid, data: item }
                    }
                  };
                  return;
                }
                if (this.fixedUid != "") {
                  uid = this.fixedUid;
                  this.setScreenOut(item, index);
                  return;
                }
                if(item.hasVideo){
                  item.videoTrack.stop();
                  this.setRemoteVideoStreamType(uid, 1);
                  this.$nextTick(() => {item.videoTrack.play(document.getElementById(uid), { fit: "contain", mirror: false}); })
                }
              });
            }
          } else {
            this.$message({
              message: res.data.message,
              type: "error"
            });
          }
        });
      }
    },

    /**  创建共享轨道 */
    createScreen(agoraloToken, shareUid) {
      var that = this;
      AgoraRTC.createScreenVideoTrack({
        // 可以在这里配置编码参数，详细参考 API 文档。
        encoderConfig: "1080p_3"
      }).then(localScreenTrack => {
          that.ScreenShare = true;
          //将本地视频放到远端用户区域
          var index = that.findElem(that.subscribeUser, "uid", that.uid);
          index == -1 ? that.subscribeUser.push({ uid: that.uid }) : "";

          window.localStorage.setItem("homeViewShow", 2)
          that.showStatus();

          that.localScreenTrack = localScreenTrack;
          this.$nextTick(() => {
            localScreenTrack.play("videoMain", { fit: "contain" });
          })
          that.screenClient = AgoraRTC.createClient({ mode: "rtc", codec: "h264" }); // vp8
          that.screenClientBack();
          console.log("Sharing the uid",shareUid);
          that.screenClient.join(that.options.appId,that.options.channel,agoraloToken,shareUid).then(res => {
            that.screenClient.publish(localScreenTrack);
          });

          that.subscribeUser.forEach((item,index) => {
            if (item.uid == that.uid  ) {
              that.rtc.videoTrack.stop();
              that.rtc.videoTrack.setEnabled(false);
              setTimeout(()=>{
                if (that.localCameraShow) {
                  that.rtc.videoTrack.play(document.getElementById(that.uid), { fit: "contain", mirror:this.mirrorStatus == 2 ? false : true });
                  that.rtc.videoTrack.setEnabled(true);
                }
              },300)
              return;
            }
            if(that.userListSet[index].VideoShow == 1 && that.userListSet[index].uid != that.uid && item.hasVideo){
              item.videoTrack.stop();
              that.$nextTick(() => {
                item.videoTrack.play(document.getElementById(item.uid), { fit: "contain", mirror: false });
              })
            }
          });
        }).catch(err => {
          if(err.code == 'PERMISSION_DENIED'){
            this.$alert( "设备不受支持、或用户未授予访问摄像头/麦克风的权限、或屏幕共享选择共享源时用户未选择共享源。", "共享异常提示", {
                confirmButtonText: "确定",
                type: 'warning'
              }
            );
            // this.$message({
            //   message: "此设备不受支持(或者用户未授予访问摄像头/麦克风的权限，或者屏幕共享选择共享源时用户未选择共享源)",
            //   type: "warning"
            // });
          }else{
            this.$message({
              message: "共享屏幕异常！",
              type: "warning"
            });
          }
          this.cancel_screen_sharing(0);
        });
    }
  },
  destroyed() {
    window.localStorage.removeItem("liveUid");
    window.localStorage.removeItem("unloadStatus");
    clearInterval(this.joinTimer);
    clearInterval(this.homeInterval1);
    clearInterval(this.fixTimer);
    clearInterval(this.DevTimer);
    window.onbeforeunload = null;
  },
};
</script>

<style scoped lang="less">
  @import '../style/home_css.less';
</style>
