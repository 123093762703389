import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './assets/element-#0099ff/index.css';
import VueWechatTitle from 'vue-wechat-title';
// import accesskey from './store/accesskey.js';
import { makeSign } from './js/singkey.js';

Vue.use(ElementUI);
Vue.use(VueWechatTitle)

Vue.config.productionTip = false

import axios from "axios";
Vue.prototype.$http = axios;
Vue.prototype.$url = '/api/v1/agora/qiniu/upload_images'                       // 上传图片至七牛

// qrcode
import VueQriously from "vue-qriously"
Vue.use(VueQriously)

axios.interceptors.request.use(
  config => {    //  接口签名
    config.headers.sign = makeSign(config.method == "get" ? config.params : config.data);
    // config = accesskey.get(config); 
    return config;
  },err => {
    return Promise.reject(err);
});

//路由守卫
router.beforeEach((to,from,next)=>{
  // console.log(window.localStorage.getItem('uid'))
  // 判断是否存在一键跳转登录
  if(to.fullPath.indexOf("?") != -1 &&  to.fullPath.indexOf("?c=") != -1){
    window.localStorage.setItem("OLkey",to.fullPath.substring(to.fullPath.indexOf("?") + 1,to.fullPath.length));
    next();
  }else{
    let public_path=['/detection','/host']
    if(public_path.includes(to.path) && window.localStorage.getItem('uid') == ''){
      next({name:'error'})  
    }else{ 
      next();
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
