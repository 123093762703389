<template>
  <div class="loginout-main">
    <div class="header">
      <div class="content">
        <img src="../assets/SVG/logo.svg" alt="">
        <div class="btn">
          <button class="first-btn" @click="shiyong">登录/注册</button>
          <button @click="shiyong">免费试用</button>
        </div>
      </div>
    </div>
    <div class="main">
        <p>您已离开轻会议，谢谢您的参与！</p>
        <div style="text-align: center;">
          <el-button  type="primary"  round @click="rejoin">重新加入</el-button>
          <el-button class="lose" type="primary"  round @click="jumpHome">返回官网</el-button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  created(){
  },
  methods:{
    shiyong(){
      window.location.href = 'http://meet.qingtv.com';
    },
    jumpHome(){
      window.location.href = 'http://www.qingtv.com';
    },
    rejoin(){
      let token = JSON.parse( window.localStorage.getItem('token') );
      window.localStorage.getItem("OLkey") ? this.$router.push({path:`/host?${window.localStorage.getItem("OLkey")}`}) : this.$router.push({path:`/join/${token}`});
      // this.$router.push({path:`/join/${token}`});
    },
  }
}
</script>

<style scoped lang='less'>
  .loginout-main{
    width: 100%;
    height: 100%;
    background: url("../assets/SVG/out_bg.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
  } 
  .header{
    position:fixed;
    top:0;
    width: 100%;
    height: 70px;
    z-index: 999;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.13);
    font-size: 18px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 70px;
    .content{
      max-width: 1300px;
      min-width: 1300px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      img{
        width: 120px;
      }
      .btn{
        button{
          width: 100px;
          height: 35px;
          background: #0099ff;
          opacity: 1;
          border-radius: 38px;
          color: #fff;
          border: none;
          font-size: 14px;
          margin-left: 30px;
        }
      }
      .first-btn{
        background: none!important;
        color: #0099ff!important;
        border:1px solid #0099ff!important;
      }
    }
  }
  .main{
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%,-50%);
    p{
      font-size: 46px;
    }
    button{
      width: 120px;
      height: 44px;
      background: #0099ff;
      opacity: 1;
      border-radius: 36px;
      font-size: 16px;
      margin: 40px;
    }
    .lose{
      background:#1C2C3B;
      color: #fff;
      border: none;
    }
  }
</style>
