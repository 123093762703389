import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import error from '../views/error.vue'
import Login from '../views/login.vue'
import detection from '../views/detection.vue'
import loginout from '../views/loginout.vue'
import unusual from '../views/unusual.vue'
import waiting from '../views/waiting.vue'
import serviceAgreement from '../views/serviceAgreement.vue'
import privacyAgreement from '../views/privacyAgreement.vue'


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

let is_mobile = navigator.userAgent.match(/(phone|pod|iPhone|iPod|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|Symbian|Windows Phone)/i)

const routes = [
  {
    path: '/join/:token',
    name: 'Login',
    component: is_mobile ? unusual : Login,
    meta:{
      title:'轻视云会议'
    }
  },
  {
    path: '/waiting',
    name: 'waiting',
    component: waiting
  },
  {
    path: '/host',
    name: 'Home',
    component: Home
  },
  {
    path: '/detection',
    name: 'detection',
    component: detection,
    meta:{
      title:'设备检测'
    }
  },
  {
    path: '/loginout',
    name: 'loginout',
    component: loginout
  },
  {
    path: '/serviceAgreement',
    name: 'serviceAgreement',
    component: serviceAgreement
  },
  {
    path: '/privacyAgreement',
    name: 'privacyAgreement',
    component: privacyAgreement
  },
  {
    path: '*',
    name: 'error',
    component: error
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
