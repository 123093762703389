<template>
    <div class="login-main" v-wechat-title="$route.meta.title" >
      <div class="bg" v-if="false">
        <!-- type == 1 -->
        <div class="logo"><img src="../assets/icon/logo-write.svg" alt=""></div>
        <div class="block"></div>
        <!-- <div class="copyright">©2019-2020 Copyright Bonwey. All rights reserved. 沪ICP备16029097号</div> -->
      </div>
      <div class="bg-two">
         <!-- v-if="type == 2" -->
        <div class="logo"><img src="../assets/icon/logo-write.svg" alt=""></div>
      </div>
      <!-- :class="{'main-two':type==2,'main':type==1}" -->
      <div class="main-two">
        <div class="main-count">
          <!-- <i class="line"></i><span v-if="type==1">主持人</span><span v-if="type==2">嘉宾</span>登录 -->
          <h1>轻会议</h1>
          <p class="title">{{title}}</p>
          <el-input class="input" v-model="userName" placeholder="用户名">
            <i slot="prefix" style="display: flex;align-items: center;">
              <img
                style="width:25px;margin:13px 8px 0 18px;"
                src="../assets/icon/ID.svg"
                alt
              />
            </i>
          </el-input>
          <el-input class="input" type="passWord" v-model="passWord" placeholder="密码" @keyup.enter.native="webMeetingLogin">
            <i slot="prefix" style="display: flex;align-items: center;">
              <img
                style="width:20px;margin:11px 10px  0 21px;"
                src="../assets/icon/pwd.svg"
                alt
              />
            </i>
          </el-input>
          <div style="display:flex;justify-content: space-between;">
            <div style="display:flex;margin-left:15px;">
              <div class="checkboxMain"><el-checkbox v-model="camera"></el-checkbox><span>打开摄像头</span></div>
              <div class="checkboxMain"><el-checkbox v-model="Mic"></el-checkbox><span>打开麦克风</span></div>
            </div>
          </div>
          <p style="font-size:12px;margin:4px 8px;text-align:left;"><span style="color:#ef5350;font-size:12px;padding: 0 5px;">*</span>浏览器将使用你的麦克风和摄像头设备</p>
          <div class="login" ><el-button type="primary" :disabled='disabled'  round @click="webMeetingLogin">加入会议</el-button></div>
          <p style="color:#ccc;font-size:12px;margin:3px;">点击“加入会议”即代表同意 <router-link to="/serviceAgreement">《轻视云会议服务协议》</router-link><router-link to="/privacyAgreement">《轻视云会议隐私保护协议》</router-link></p>
          <p style="color:#ccc;font-size:12px;margin:3px;">为了保证良好的使用体验，建议使用Chrome浏览器访问。</p>
          <p style="color:#ccc;font-size:12px;margin:3px;">建议浏览器分辨率不低于1366X768。</p>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  data() {
      return{
        userName:'',
        passWord:'',
        title:'',
        camera:true,
        Mic:true,
        token:'',
        id:'',
        // type:"",
        disabled:false,
        
      }
  },
  created() {
    let index = this.$route.fullPath.lastIndexOf("\/");
    let token = this.$route.fullPath.substring(index + 1,this.$route.fullPath.length);
    // let token = this.getQueryVariable("web_token");
    if(!token){
      this.$message({
        showClose: true,
        message: '无效链接！',
        type: 'error'
      });
      return;
    }
    console.log(token)
    this.token = token;
    // this.type = this.token.substr(this.token.length-1,1);
    this.meetingInfo();
  },
  methods:{
    /** 获取会议基本信息 */
    meetingInfo(){
      this.$store.dispatch("meeting_info",{
        web_token:this.token
      }).then(res=>{
        if(res.data.code == 200){
          this.title = res.data.data.info.meeting_title;
          this.id = res.data.data.info.id;

        }else{
          this.$message({
            message:res.data.message,
            type:"error"
          })
        }
      })
    },

    getQueryVariable(variable){
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
      }
      return(false);
    },

    /** 会议登录 */
    webMeetingLogin() {
      this.disabled = true;
      var _this = this;
      _this.$store.dispatch("web_meeting_login",{
        id:_this.id,
        web_token:_this.token,
        name:_this.userName,
        password:_this.passWord
      }).then(res=>{
          if(res.data.code == 200){
            window.localStorage.setItem('meetinfoId',_this.id);
            window.localStorage.setItem('meetinfo',JSON.stringify(res.data.data));
            window.localStorage.setItem('token',JSON.stringify(_this.token));
            window.localStorage.setItem('uid',JSON.stringify(res.data.data.uid));
            if(res.data.data.identity == 1){
              _this.$router.push({path:"/detection",query:{Camera:_this.camera ? 1 : 0,Mic:_this.Mic ? 1 : 0}})
            }else{
              res.data.data.limit_type !=3 ? _this.$router.push({path:"/detection",query:{Camera:_this.camera ? 1 : 0,Mic:_this.Mic ? 1 : 0}}) 
              : _this.$router.push({path:"/waiting",query:{Camera:_this.camera ? 1 : 0,Mic:_this.Mic ? 1 : 0}});
            }
            _this.disabled = false;
            _this.$message({
                message:res.data.message,
                type:"success"
            })
            return;
          }else{
            _this.disabled = false;
            _this.$message({
              message: res.data.message,
              type: 'error'
            });
          }
      }).catch(err => {
        _this.disabled = false;
          // _this.$message({
          //     message:"网络异常,请稍后重试！",
          //     type: 'warning'
          // })
      })
    },
  }
}
</script>

<style scoped lang="less">
.bg,.bg-two{
  background: #1C2C3B;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  zoom: 1;
  z-index: -999;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  .block{
    width: 100%;
    height: 30%;
    background: #fff;
    position: absolute;
    bottom: 14%;
  }
  .logo{
    position: absolute;
    img{
      width: 130px;
    }
    left: 45px;
    top:30px;
  }
}
.bg-two{
    background: url('../assets/icon/bg.png');
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
}
.login-main .main,.login-main .main-two{
  width: 580px;
  height: 480px;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px #666;
  border-radius: 20px;
  background: #7C8B9A;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.login-main .main-two{
  background: rgba(0, 0, 0, 0.3);
  box-shadow:none;
}
.login-main .main-count{
  width: 460px;
  margin: 0 auto;
  padding-top: 35px;
  .title{
    width:100%;
    height: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.login-main .main-count{
  h1{
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    text-align: center;
  }
  text-align: center;
  p{
    font-size: 26px;
    color: #fff;
    margin: 15px 0 25px 0;
  }
  a{color: #0099ff;text-decoration: none;}
  a:hover{text-decoration: underline;}
  .line{
    display: inline-block;
    width: 2px;
    height: 16px;
    background: #0099ff;
    margin: 0 20px;
  }
  /deep/.el-input__inner{
    width: 440px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid rgba(153, 153, 153, 0.5);
    opacity: 1;
    margin-bottom: 20px;
    padding-left: 50px;
    border-radius: 6px;
  }
  /deep/input::placeholder{
    font-size: 16px;
    margin-left: 18px;
    color: #999;
  }
  .checkboxMain{
    font-size: 14px;
    margin-right: 30px;
    color: #fff;
    span{padding-left:5px;}
  }
  .el-checkbox{
    color: #999999;
  }
  .login{
    text-align: center;
    margin: 40px 0 15px;
    button{
      width: 150px;
      height: 40px;
      background: #0099ff;
      opacity: 1;
      border-radius: 6px;
      font-size: 16px;
    }
  }
}


</style>
