import axios from "axios";

export default{
    // 等待室等待
    waiting_room(store,v){
        return axios.post("/api/v1/agora/web_meeting/waiting_room",v)
    },
    // 等待室列表
    participant_waiting_room(store,v){
        return axios.get("/api/v1/agora/web_meeting/participant_waiting_room",{
            params:{
                meeting_id:v.meeting_id
            }
        })
    },
    // 允许等待室入会
    waiting_room_adopt(store,v){
        return axios.post("/api/v1/agora/web_meeting/waiting_room_adopt",v)
    },
    // 移除等待室
    waiting_room_remove(store,v){
        return axios.post("/api/v1/agora/web_meeting/waiting_room_remove",v)
    },
    // 获取轻直播推流地址
    get_live_studio_push_url(store,v){
        return axios.get("/api/v1/agora/oa/get_live_studio_push_url",{
            params:{
                studio_id:v.studio_id,
                meeting_id:v.meeting_id
            }
        })
    },
    // 获取验证码
    send_sms_captcha(store,v){
        return axios.post("/api/v1/agora/common/send_sms_captcha",v)
    },
    // 验证轻直播账号
    verify_live_admin_info(store,v){
        return axios.post("/api/v1/agora/web_meeting/meet_verify_live_admin_info",v)
    },
    // 绑定轻直播
    bind_live_admin(store,v){
        return axios.post("/api/v1/agora/web_meeting/bind_live_admin",v)
    },

     // 获取会议基本信息
     meeting_info(store,v){
        return axios.get("/api/v1/agora/web_meeting/meeting_info",{
            params:{
                web_token:v.web_token
            }
        })
    },

    // 网页会议登录
    web_meeting_login(store,v){
        return axios.post("/api/v1/agora/web_meeting/web_meeting_login",v)
    },

    // 获取主持一键入会信息
    host_join_info(store,v){
        return axios.get("/api/v1/agora/web_meeting/host_join_info",{
            params:{
                fast_code:v.fast_code
            }
        })
    },

    // 获取入会信息
    get_auth_info(store,v){
        return axios.post("/api/v1/agora/web_meeting/get_auth_info",v)
    },
    // 获取参会者列表
    get_participant_list(store,v){
        return axios.get("/api/v1/agora/web_meeting/get_participant_list",{
            params:{
                meeting_id:v.meeting_id,
                page:v.page,
                page_num:v.page_num,
            }
        })
    },

    // 离开会议/结束会议
    out_meeting(store,v){
        return axios.post("/api/v1/agora/web_meeting/out_meeting",v)
    },

    // 屏幕共享操作
    screen_sharing(store,v){
        return axios.post("/api/v1/agora/web_meeting/screen_sharing",v)
    },
    
    // 取消屏幕共享
    cancel_screen_sharing(store,v){
        return axios.post("/api/v1/agora/web_meeting/cancel_screen_sharing",v)
    },
    
    // 获取声网token
    get_agora_token(store,v){
        return axios.post("/api/v1/agora/web_meeting/get_agora_token",v)
    },
    
    // 更改视频状态
    video_change(store,v){
        return axios.post("/api/v1/agora/web_meeting/video_change",v)
    },
    // 更改音频状态
    scene_change(store,v){
        return axios.post("/api/v1/agora/web_meeting/scene_change",v)
    },
    focus_change(store,v){
        return axios.post("/api/v1/agora/web_meeting/focus_change",v)
    },
}
