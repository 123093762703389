<template>
    <div>
      <div class="set-header">
        <p>设置</p>
      </div>
      <div class="set-main ">
        <div class="set-main-left">
          <p :style="CameraShow ? 'background:#1C2C3B;color:#fff;' : ''" @click="CameraShow = true,MicShow = PlayShow = liveSet = shortcutKeyShow = moreSetShow = false">摄像头</p>
          <p :style="MicShow ? 'background:#1C2C3B;color:#fff;' : ''" @click="startMic()">麦克风</p>
          <!-- <p :style="PlayShow ? 'background:#1C2C3B;color:#fff;' : ''" @click="startSpeak()">扬声器</p> -->
          <p v-if="adminType == 1" :style="liveSet ? 'background:#1C2C3B;color:#fff;text-align:left;padding-left:51px;' : 'text-align:left;padding-left:51px;'" @click="LiveSet()">直播</p>
          <p :style="shortcutKeyShow ? 'background:#1C2C3B;color:#fff;' : ''" @click="shortcutKey()">快捷键</p>
          <p :style="moreSetShow ? 'background:#1C2C3B;color:#fff;text-align:left;padding-left:51px;' : 'text-align:left;padding-left:51px;'" @click="moreSet()">更多</p>
        </div>
        <div class="set-main-right">
          <div class="camera-main" v-show="CameraShow">
            <div class="flex camera-main-top">
              <span>摄像头选择：</span>
              <el-select style="width: 60%;" v-model="currentCameraDeviceId" placeholder="默认选择第一个摄像头">
                  <el-option
                    v-for="item in Cameras"
                    :key="item.deviceId"
                    :label="item.label"
                    :value="item.deviceId">
                  </el-option>
              </el-select>
            </div>
            <div class="camera-main-center">
              <span>预览：</span>
              <div id="localvideo"></div>
            </div>
            <div class="text">
              <span>如果您无法看到视频，请按以下方式排查问题</span>
              <p>1.若杀毒软件或“电脑管家”类软件弹出提示信息，请选择“允许”;</p>
              <p>2.请确认您的摄像头已连接并开启;</p>
              <p>3.确认您的摄像头没有被其他应用程序占用;</p>
              <p>4.如以上方法无法解决，请重启电脑或更换一台电脑测试。</p>
            </div>
            <div class="flex">
              <p style="margin-right:8px;">是否镜像：</p>
              <el-radio v-model="mirror_switch" label="1" @change="mirrorChange()">否</el-radio>
              <el-radio v-model="mirror_switch" label="2" @change="mirrorChange()">是</el-radio>
            </div>
            <div class="btns">
              <button class="lose" @click="closeDia">取消</button>
              <button @click="saveCamera">保存</button>
            </div>
          </div>
          <div class="camera-main" v-show="MicShow">
            <div class="flex camera-main-top">
              <span>麦克风选择：</span>
              <el-select style="width: 60%;" v-model="currentMicDeviceId" placeholder="请选择">
                <el-option
                  v-for="item in Microphones"
                  :key="item.deviceId"
                  :label="item.label"
                  :value="item.deviceId">
                </el-option>
              </el-select>
            </div>
            <div class="flex" style="margin:20px 0;">
              <span>预览：</span>
              <el-progress style="width: 70%;"  :stroke-width="5" :show-text="false" :percentage="VolumeLevel*100"></el-progress>
            </div>
            <div class="text">
              <span>如果您无法看到音量波动条，请按以下方式排查问题</span>
              <p>1.若杀毒软件或“电脑管家”类软件弹出提示信息，请选择“允许”;</p>
              <p>2.请确认您的麦克风已连接并开启;</p>
              <p>3.确认麦克风已插入麦克风插孔中，并且麦克风声音已调整到最大;</p>
              <p>4.请选择正确的麦克风选项，选择禁用会导致麦克风不可用;</p>
              <p>5.如果麦克风仍然没有声音，换一个插口重新插入麦克风;</p>
              <p>6.如以上方法无法解决，请重启电脑或更换一台电脑测试。</p>
            </div>
            <div class="btns">
              <button class="lose" @click="closeDia">取消</button>
              <button @click="saveMic">保存</button>
            </div>
          </div>
          <div class="camera-main" v-show="PlayShow">
            <div class="flex camera-main-top">
                <span>扬声器选择：</span>
                <el-select style="width: 60%;" v-model="currentPlaybackDevices" placeholder="请选择">
                    <el-option
                      v-for="item in PlaybackDevices"
                      :key="item.deviceId"
                      :label="item.label"
                      :value="item.deviceId">
                    </el-option>
                  </el-select>
              </div>
              <div class="flex" style="margin:20px 0;">
                <div style="margin-right:15px;">
                  <img style="width:30px;margin-top:5px;cursor: pointer" @click="PlaybackDevicesPlayStop" :src="PlaybackIsPlay ? require('@/assets/SVG/play.svg') : require('@/assets/SVG/play1.svg')" alt="">
                </div>
                <div style="width:70%;">
                  <el-slider v-model="PlaybackLevel"></el-slider>
                </div>
              </div>
              <div class="text">
                <span>如果您无法听见声音，请按以下方式排查问题</span>
                <p>1.若杀毒软件或“电脑管家”类软件弹出提示信息，请选择“允许”;</p>
                <p>2.确认手机、扬声器已连接并开启;</p>
                <p>3.如果耳机、扬声器音量已经调整到最大;</p>
                <p>4.请选择正确的耳机选项，选择禁用会导致耳机、扬声器不可用;</p>
                <p>5.如果耳机、扬声器仍然没有声音，换一个插口重新插入耳机、扬声器;</p>
                <p>6.如以上方法无法解决，请重启电脑或更换一台电脑测试。</p>
              </div>
              <div class="btns">
                <button class="lose" @click="closeDia">取消</button>
                <button @click="saveSpaker">保存</button>
              </div>
          </div>
          <div class="camera-main" v-show="liveSet">
              <div class="flex camera-main-top">
                <span style="margin-left:23px;">分辨率：</span>
                <el-select style="width: 60%;" v-model="resolutionSelect" placeholder="请选择">
                  <el-option  label="高清" value="720p_3"></el-option>
                  <el-option  label="标清" value="480p_3"></el-option>
                  <el-option  label="流畅" value="360p_3"></el-option>
                </el-select>
              </div>
              <div style="margin:20px 0 0;">
                <span>Logo开关：</span>
                <el-switch
                  v-model="logoSwitch"
                  active-color="#0099ff"
                  inactive-color="#ccc">
                </el-switch>
              </div>
              <div class="flex logo-box" v-if="logoSwitch">
                <span style="margin-left:32px;">Logo：</span>
                <div class="Image">
                  <div id="logoImg">
                      <img src="@/assets/SVG/addImg.svg" style="width:20px;" v-if="logoUrl==''">
                      <img :src="logoUrl" v-if="logoUrl">
                  </div>
                  <input id="file" class="filepath" style="opacity: 0;" accept=".jpg,.jpeg,.png" @change="changepic($event)" type="file">
                </div>
                <div class="text">
                  <span style="color:#ef5350;">* 仅支持png格式</span><br>
                  <span>图片大小不超过2M</span><br>
                  <span>建议尺寸为200px*200px</span>
                </div>
              </div>
              <div class="flex camera-main-top" style="margin-top:40px;" v-if="logoSwitch">
                <span>Logo位置：</span>
                <el-select style="width: 60%;" v-model="logoPlace" placeholder="请选择">
                    <el-option  label="左上" value="0"></el-option>
                    <el-option  label="右上" value="1"></el-option>
                    <el-option  label="左下" value="2"></el-option>
                    <el-option  label="右下" value="3"></el-option>
                  </el-select>
              </div>
              <div class="flex bg-set">
                <span style="margin-left:6px;">背景选择：</span>
                <div class="bg-select">
                  <button :style="isbgColor ? 'background:#fff;' : ''" @click="isbgColor = true">选择背景色</button>
                  <button :style="!isbgColor ? 'background:#fff;' : ''" @click="isbgColor = false">上传背景图</button>
                </div>
              </div>
              <div class="bg_color" v-if="isbgColor">
                  <div class="color_value">
                      <el-color-picker v-model="bg_color" @change="select_bgcolor" color-format="hex"></el-color-picker>
                  </div>
                  <div class="color_value_text">
                      <span class="color_text">{{bg_clotext}}</span>
                  </div>
              </div>
               <div class="flex bgimg-box" v-else>
                <div class="Image">
                  <div id="bgImg">
                      <img src="@/assets/SVG/addImg.svg" style="width:25px;height:25px;margin-top:7px;" v-if="bgUrl==''">
                      <p v-if="bgUrl==''">图片大小不超过2M</p>
                      <p v-if="bgUrl==''">建议尺寸为1920 * 1080px</p>
                      <img :src="bgUrl" v-if="bgUrl">
                  </div>
                  <input id="file" class="filepath" style="opacity: 0;" accept=".jpg,.jpeg,.png" @change="changebgpic($event)" type="file">
                </div>
              </div>
              <div class="view-set">
                <span style="margin-left:6px;">布局模式：</span>
                <div class="bg-select">
                  <button :style="isDefaultView ? 'background:#fff;' : ''" @click="isDefaultView = true">普通模式</button>
                
                  <div class="view-mode flex">
                    <img :src="live_default_view == 0 ?require('@/assets/viewmode/6.png') : require('@/assets/viewmode/6_1.png')" @click="live_default_view = 0">
                    <img :src="live_default_view == 1 ?require('@/assets/viewmode/5.png') : require('@/assets/viewmode/5_1.png')" @click="live_default_view = 1">
                  </div>
                  <button :style="!isDefaultView ? 'background:#fff;' : ''" @click="isDefaultView = false">共享模式</button>
                  <div class="view-mode flex">
                    <img :src="live_share_view == 0 ?require('@/assets/viewmode/8.png') : require('@/assets/viewmode/8_1.png')" @click="live_share_view = 0" alt="左右中">
                    <img :src="live_share_view == 1 ?require('@/assets/viewmode/1.png') : require('@/assets/viewmode/1_1.png')" @click="live_share_view = 1" alt="左中右">
                    <img :src="live_share_view == 2 ?require('@/assets/viewmode/4.png') : require('@/assets/viewmode/4_1.png')" @click="live_share_view = 2" alt="上中下">
                    <img :src="live_share_view == 3 ?require('@/assets/viewmode/7.png') : require('@/assets/viewmode/7_1.png')" @click="live_share_view = 3" alt="上下中">
                  </div>
                </div>
              </div>
              <div class="btns">
                <button class="lose" @click="closeDia">取消</button>
                <button @click="saveLiveSet">保存</button>
              </div>
          </div>
          <div class="camera-main" v-show="moreSetShow">
            <div class="flex">
              <p style="margin-right:8px;">菜单显示：</p>
              <el-radio v-model="menu_switch" label="1">自动隐藏</el-radio>
              <el-radio v-model="menu_switch" label="2">常驻</el-radio>
            </div>
            <div class="btns">
              <button class="lose" @click="closeDia">取消</button>
              <button @click="saveLiveSet">保存</button>
            </div>
          </div>
          <div class="camera-main" v-show="shortcutKeyShow">
            <div class="flex shortcut-key-box" v-for="(i,j) in shortcutKeyArr" :key="j">
              <p>{{i.title}}</p>
              <p>{{i.value}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng";
import merge from 'webpack-merge';
export default{
    props:[
      "setDialogVisible",
      "setRtc",
      "adminType",
      ],
    data(){
        return{
            CameraShow:false,
            MicShow:false,
            PlayShow:false,
            liveSet:false,
            shortcutKeyShow:false,
            moreSetShow:false,
            Cameras:[],
            currentCameraDeviceId:'',
            Microphones:[],
            currentMicDeviceId:'',
            PlaybackDevices:[],
            currentPlaybackDevices:'',
            shortcutKeyArr:[
              // {
              //   title:"开始/停止屏幕共享",
              //   value:"Alt+S",
              // },
              {
                title:"开/关摄像头",
                value:"Alt+V",
              },
              {
                title:"开/关麦克风",
                value:"Ctrl+Alt+A",
              },
              // {
              //   title:'切换到演讲者视图',
              //   value:"Ctrl+Alt+F1"
              // },
              // {
              //   title:"切换到画廊视图",
              //   value:"Ctrl+Alt+F2",
              // },
              {
                title:"全屏当前画面",
                value:"Ctrl+Alt+F",
              },
              {
                title:"开启直播",
                value:"Ctrl+Alt+L",
              },
              {
                title:"结束直播",
                value:"Ctrl+Alt+C",
              },
              // {
              //   title:"强制隐藏菜单",
              //   value:"Ctrl+Alt+D",
              // }
            ],
            interval:null,

            rtc:{
            videoTrack:null,
            audioTrack:null,
            BufferSourceAudioTrack:null,
            },
            VolumeLevel:0,
            interval1:null,
            PlaybackLevel:100,
            PlaybackIsPlay:false,

            camera_use:0,    // 摄像头使用设备
            speaker_use:0,   // 扬声器使用设备
            mic_use:0,       // 麦克风使用设备

            start_status:0,
            loading:true,

            resolutionSelect:"720p_3",
            logoPlace:"0",
            logoUrl:"",
            bgUrl:"",
            bg_color:"#000000",
            bg_clotext:"#000000",
            isbgColor:true,

            logoSwitch:false,
            
            isDefaultView:true,
            live_default_view:0,  // 默认上大下小一排
            live_share_view:0,    // 默认左右 上下居中中

            menu_switch:'1',      // 1自动隐藏 2常驻
            mirror_switch:'1',    // 2镜像 1非镜像
        }
    },
    created(){
        this.startCamera();
        this.getDevices();
        //设置日志级别
        AgoraRTC.setLogLevel(4);

        this.camera_use = this.$route.query.CameraUse;
        this.Micmic_useUse = this.$route.query.MicUse;
        this.speaker_use = this.$route.query.SpeakerUse;

        if(window.localStorage.getItem("liveSetInfo")){
          let obj = JSON.parse(window.localStorage.getItem("liveSetInfo"));
          this.resolutionSelect = obj.resolution; // 分辨率
          this.logoUrl = obj.logoUrl;             // logo图片
          this.logoPlace = obj.logoPosition;      // logo位置
          obj.backgroundColor != null ? this.bg_color =  obj.backgroundColor.replace(/0x/,"#") : this.bg_color = '#000000',  // 背景颜色\
          obj.backgroundColor != null ? this.isbgColor = true : obj.backgroundImage != null ? this.isbgColor = false  : '';
          obj.backgroundImage != null ? this.bgUrl = obj.backgroundImage : this.bgUrl =  '',         // 背景图
          this.logoSwitch = obj.logoSwitch;
          this.live_share_view = obj.liveShareView;
          this.live_default_view = obj.liveDefaultView;
          this.menu_switch = obj.menuStatus;
          this.mirror_switch = obj.mirrorStatus;
          if(obj.mirrorStatus == 2  ) {
            this.rtc.videoTrack.stop();
            this.rtc.videoTrack.play("localvideo", { fit: "contain", mirror: false });
          }
          if(obj.mirrorStatus == 1  ){
            this.rtc.videoTrack.stop();
            this.rtc.videoTrack.play("localvideo", { fit: "contain", mirror: true });
          }
        }
    },
    watch:{
      $route(){
        if(this.camera_use != this.$route.query.CameraUse) {
          this.camera_use = this.$route.query.CameraUse;
          this.setCurrentCameraDeviceId(this.Cameras[this.camera_use].deviceId);
        }
        if(this.mic_use != this.$route.query.MicUse){
          this.Micmic_useUse = this.$route.query.MicUse;
          this.setCurrentMicDeviceId(this.Microphones[this.mic_use].deviceId);
        } 
        if(this.speaker_use != this.$route.query.SpeakerUse) {
          this.speaker_use = this.$route.query.SpeakerUse;
          this.setCurrentMicDeviceId(this.Microphones[this.speaker_use].deviceId);
        }
      },
      currentMicDeviceId(newv,oldv){
        if(!oldv){return;}
        this.setCurrentMicDeviceId(newv);
        this.Microphones.forEach((item,index)=>{
          if(item.deviceId == newv){
            this.mic_use = index;
          }
        })
      },

      PlaybackLevel(newv){
        this.setPlaybackDevicesVolume(newv);
      },

      currentPlaybackDevices(newv,oldv){
        if(!oldv){return;}
        this.setPlaybackDevicesId(newv);
        this.PlaybackDevices.forEach((item,index)=>{
          if(item.deviceId == newv){
            this.speaker_use = index;
          }
        })
      },

      currentCameraDeviceId(newv,oldv){
        if(!oldv){return;}
        this.setCurrentCameraDeviceId(newv);
        this.Cameras.forEach((item,index)=>{
          if(item.deviceId == newv){
            this.camera_use = index;
          }
        })
      },
      
      setDialogVisible(newv){
        if(newv){
          this.startCamera();
        }else{
          this.PlaybackIsPlay ? this.PlaybackDevicesPlayStop() : '';
        }
      },

      setRtc(){
        this.rtc = JSON.parse(setRtc)
      },

      SpeakerUse(newv){
          this.speaker_use = newv;
          this.currentPlaybackDevices = this.PlaybackDevices[this.speaker_use].deviceId;
      },

      CameraUse(newv){
        this.camera_use = newv;
        this.currentCameraDeviceId = this.Cameras[this.camera_use].deviceId;
      },

      MicUse(newv){
        this.mic_use = newv;
        this.currentMicDeviceId = this.Microphones[this.mic_use].deviceId;
      },
      
    },
    methods:{
      mirrorChange(){
        if(this.mirror_switch == 1){
          this.rtc.videoTrack.stop();
          this.rtc.videoTrack.play("localvideo", { fit: "contain", mirror: true });
        }
        if(this.mirror_switch == 2){
          this.rtc.videoTrack.stop();
          this.rtc.videoTrack.play("localvideo", { fit: "contain", mirror: false });
        }
      },
      select_bgcolor(e){
          this.bg_clotext = e
      },
      changebgpic(e){
        if(e.target.files[0].size/1024>2048 ){
            this.$message({
                message:"上传图片不能大于2M！",
                type: 'error'
            })
            e.target.value = ''
            return;
        }
        this.bgUrl = ''
        let files = e.target.files[0];
        let formData = new FormData();
        formData.append('images',files);
        formData.append("storage_space",1)
        let that=this;
        console.log(files)
        that.$http.post(that.$url,formData).then(function(res){
            if(res.data.code==200){
                that.bgUrl=res.data.data[files.name].url;
                e.target.value = ''
            }else{
                e.target.value = ''
                that.$message({
                message:res.data.error,
                type:"error"})
            }
        }).catch(err=>{
            e.target.value = ''
            that.$message({
                message:"网络异常,请稍后重试！",
                type: 'warning'
            })
        })
      },
      changepic(e){
        if(e.target.files[0].size/1024>2048 ){
            this.$message({
                message:"上传图片不能大于2M！",
                type: 'error'
            })
            e.target.value = ''
            return;
        }
        this.logoUrl = ''
        let files = e.target.files[0];
        let formData = new FormData();
        formData.append('images',files);
        formData.append("storage_space",1)
        let that=this
        that.$http.post(that.$url,formData).then(function(res){
            if(res.data.code==200){
                that.logoUrl=res.data.data[files.name].url
                e.target.value = ''
            }else{
                e.target.value = ''
                that.$message({
                message:res.data.error,
                type:"error"})
            }
        }).catch(err=>{
            e.target.value = ''
            that.$message({
                message:"网络异常,请稍后重试！",
                type: 'warning'
            })
        })
      },
      shortcutKey(){
        this.shortcutKeyShow = true;
        this.CameraShow = this.MicShow = this.PlayShow  = this.liveSet = this.moreSetShow = false;
      },
      moreSet(){
        this.moreSetShow = true;
        this.CameraShow = this.MicShow = this.PlayShow  = this.liveSet = this.shortcutKeyShow = false;
      },
      LiveSet(){
        this.liveSet = true;
        this.CameraShow = this.MicShow = this.PlayShow  = this.shortcutKeyShow =  this.moreSetShow = false;
      },
      saveLiveSet(){
        let obj = {
          resolution:this.resolutionSelect,
          logoUrl: this.logoUrl,         // logo图片
          logoPosition:this.logoPlace,      // logo位置
          backgroundColor:this.isbgColor ? this.bg_color.replace(/#/,"0x") : null,  // 背景颜色
          backgroundImage:!this.isbgColor ? this.bgUrl : null,         // 背景图
          logoSwitch:this.logoSwitch,
          liveShareView:this.live_share_view,
          liveDefaultView:this.live_default_view,
          menuStatus:this.menu_switch,
          mirrorStatus:this.mirror_switch,
        }
        console.log(this.bg_color,obj)
        window.localStorage.setItem("liveSetInfo",JSON.stringify(obj));
        this.$emit("changeLiveSet",obj);
        this.closeDia();
      },
      saveCamera(){
        this.$router.push({
            query:merge(this.$route.query,{'CameraUse':this.camera_use})
        })
        this.saveLiveSet();
        this.closeDia();
      },
      saveMic(){
        this.$router.push({
            query:merge(this.$route.query,{'MicUse':this.mic_use})
        })
        this.closeDia();
      },
      saveSpaker(){
        this.$router.push({
            query:merge(this.$route.query,{'SpeakerUse':this.speaker_use})
        })
        this.closeDia();
      },
      closeDia(){
        this.$emit('closeSetDialog',false)
      },
      startCamera(){
        this.CameraShow = true;
        this.PlayShow = this.MicShow = this.liveSet = this.shortcutKeyShow = this.moreSetShow = false;
        this.interval = setInterval(()=>{
          this.rtc.videoTrack.play("localvideo",{mirror:this.mirror_switch == 1 ? true : false});
          clearInterval(this.interval)
        },300)
        if(this.PlaybackIsPlay){
          this.rtc.BufferSourceAudioTrack.stop();
        }
      },
      startSpeak(){
        this.PlayShow = true;
        this.CameraShow = this.MicShow = this.liveSet = this.shortcutKeyShow = this.moreSetShow = false;
        clearInterval(this.interval1)
      },
      startMic(){
        this.MicShow = true;
        this.CameraShow = this.PlayShow = this.liveSet = this.shortcutKeyShow = this.moreSetShow = false;
        let that = this;
        this.interval1 = setInterval(function (){
          that.getVolumeLevel()
        },200)
        if(this.PlaybackIsPlay){
          this.PlaybackDevicesPlayStop();
        }
      },
      //获取客户端媒体设备
      getDevices(){
      var that = this;
      AgoraRTC.getCameras().then(Cameras=>{
          that.Cameras = Cameras;
          that.currentCameraDeviceId = Cameras[that.camera_use].deviceId;
      }).catch(e=>{
          console.log("get Cameras error!", e);
      })
      AgoraRTC.getMicrophones().then(Microphones=>{
          that.Microphones = Microphones;
          that.currentMicDeviceId = Microphones[that.mic_use].deviceId;
      }).catch(e=>{
          console.log("get Microphones error!", e);
      })
      AgoraRTC.getPlaybackDevices().then(PlaybackDevices=>{
          that.PlaybackDevices = PlaybackDevices
          that.currentPlaybackDevices = PlaybackDevices[that.speaker_use].deviceId;
      }).catch(e=>{
          console.log("get PlaybackDevices error!", e);
      })
      setTimeout(()=>{
          this.createCameraVideoTrack();
      },1000)
      },

      //通过摄像头和麦克风创建视频/音频轨道对象
      async createCameraVideoTrack(){
      var that = this
      if (!this.rtc.audioTrack || !this.rtc.videoTrack || !this.rtc.BufferSourceAudioTrack) {
          [ this.rtc.audioTrack, this.rtc.videoTrack , this.rtc.BufferSourceAudioTrack] = await Promise.all([
          // create local tracks, using microphone and camera
          AgoraRTC.createMicrophoneAudioTrack({ microphoneId: that.currentMicDeviceId }),
          AgoraRTC.createCameraVideoTrack({ cameraId: that.currentCameraDeviceId , encoderConfig:'720p_3'}),
          AgoraRTC.createBufferSourceAudioTrack({cacheOnlineFile:false,encoderConfig:"music_standard",source:"https://images.innocomn.com/%E8%BD%BB%E7%9B%B4%E6%92%AD%E9%85%8D%E9%9F%B3_%E7%BC%A9%E6%B7%B7.mp3"})
          ]);
      }
      // this.rtc.videoTrack.setBeautyEffect(true);
      this.start_status = 1;
      this.loading = false;
      },

      //播放本地音频文件
      PlaybackDevicesPlayStop(){
      this.PlaybackIsPlay = !this.PlaybackIsPlay;
      if(this.PlaybackIsPlay){
          this.rtc.BufferSourceAudioTrack.startProcessAudioBuffer()
          this.rtc.BufferSourceAudioTrack.play();
      }else{
          this.rtc.BufferSourceAudioTrack.pauseProcessAudioBuffer();
          this.rtc.BufferSourceAudioTrack.stop();
      }
      },

      //设置当前音频播放设备音量
      setPlaybackDevicesVolume(number){
      this.rtc.BufferSourceAudioTrack.setVolume(number);
      },

      //设置音频播放器设备
      setPlaybackDevicesId(deviceId){
      this.rtc.BufferSourceAudioTrack.setPlaybackDevice(deviceId);
      },

      /**
       * 设置当前使用的摄像头
       */
      setCurrentCameraDeviceId(DeviceId){
      this.rtc.videoTrack.setDevice(DeviceId);
      },

      /**
       * 设置当前使用的麦克风
       */
      setCurrentMicDeviceId(DeviceId){
      this.rtc.audioTrack.setDevice(DeviceId)
      },

      getVolumeLevel(){
      this.VolumeLevel = this.rtc.audioTrack.getVolumeLevel();
      },
    }
}
</script>

<style scoped lang='less'>
.flex{
    display: flex;
    align-items: center;
}
.set-header{
    border-bottom:2px solid #DADADA;
    p{padding: 15px 50px;margin: 0;}
    font-size: 22px;
    color: #fff;
    background-color: #1C2C3B;
}
.set-main{
    height: 480px;
    display: flex;
}
.set-main-left{
    width: 190px;
    height: 100%;
    border-right:2px solid #DADADA;
    text-align: center;
    color: #666666;
    p{
        width:100%;
        height: 50px;
        margin:0;
        font-size: 16px;
        line-height: 50px;
        cursor: pointer;
    }
}
.set-main-right{
    width: 100%;
    height: 100%;
    position: relative;
    background: #fbfcfd;
    .camera-main::-webkit-scrollbar{
      // display: none;
    }
    .camera-main{
    margin:20px 30px 0px;
    height: 400px;
    overflow-y: scroll;
    span{
        font-size: 16px;
        color:#666;
    }
    .text{
        p{
        font-size: 14px;
        color: #999;
        }
    }
    .camera-main-top{
      /deep/.el-input__inner{
        height: 38px;
        border-radius: 10px;
        border: 1px solid #ccc;
      }
    }
    .camera-main-center{
        display: flex;
        margin: 20px 0;
    }
    .btns{
        text-align: center;
        position: absolute;
        bottom:20px;
        left:115px;
        button{
          width: 120px;
          height: 35px;
          background: #0099ff;
          color:#fff;
          opacity: 1;
          border-radius: 36px;
          border:none;
          margin:0 20px;
          outline: none;
          cursor: pointer;
        }
        .lose{
          background: #1C2C3B;
        }
    }
    #localvideo{
        width: 136px;
        height: 77px;
        background-image: url("../assets/videoHead.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -o-background-size: cover;
        background-position: center 0;
        border-radius: 3px;
        overflow: hidden;
    }
  }
  .shortcut-key-box{
    border-bottom: 1px solid #E4E7ED;
    justify-content: space-around;
    p{margin:12px 0 11px;width:200px;text-align: left;}
  }
}
.logo-box{
  position: relative;
  .Image{
    position: relative;
    height: 60px;
    input{
      width:60px;
      height:60px;
      position: absolute;
      top:20px;
      left: 0px;
    }
  }
  #logoImg{
    width:60px;
    height:60px;
    position: absolute;
    top:20px;
    left: 0px;
    background:rgba(255,255,255,1);
    border:1px solid #ccc;
    border-radius:4px;
    overflow: hidden;
    text-align: center;
    img{
        width:100%;
        height: 100%;
        background: #fff;
    }
  }
  .text{
    position: absolute;
    left: 170px;
    top:18px;
  }
  .text span{
    font-size: 12px;
    line-height: 22px;
  }
}
.bg_color{
  display: flex;
  align-items: center;
  margin-left:85px;
  .color_value_text{
      width:70px;
      height: 25px;
      background:rgba(255,255,255,1);
      border:1px solid #999;
      border-radius:3px;
      text-align: center;
      margin-bottom: 20px;
      line-height: 25px;
      .color_text{
          font-size:14px;
          font-weight:400;
          color:rgba(51,51,51,1);
      }
  }
  .color_value{
    margin-left:1px;
    margin-right: 10px;
      /deep/.el-color-picker__color{
          border: none;
      }
      /deep/.el-icon-arrow-down{
          display: none;
      }
      /deep/.el-color-picker__trigger{
          padding: 0;
          width: 85px;
          height: 48px;
          border-radius:4px;
          overflow: hidden;
      }
  }
  .reset{
      width: 40px;
      height: 20px;
      color: #fff;
      border:1px solid #ccc;
      color:#666;
      border-radius: 2px;
      text-align: center;
      font-size: 12px;
      margin-left: 5px;
      margin-bottom: 6px;
      cursor: pointer;
  }
}
.bg-set{
  margin: 15px 0;
  .bg-select{
    width: 200px;
    height: 30px;
    background: #F0F3F5;
    border: 1px solid #E4E7ED;
    opacity: 1;
    border-radius: 4px;
  }
  button{
    width: 100px;
    height: 30px;
    background: #F0F3F5;
    border:none;
    color: #666666;
    border-radius: 5px;
    cursor: pointer;
  }
}
.view-set{
  display: flex;
  margin: 15px 0;
  .view-mode{
    margin:10px 0 15px -7px;
  }
  button{
    width: 100px;
    height: 28px;
    color: #666666;
    background: #FFFFFF;
    border: 1px solid #E4E7ED;
    opacity: 1;
    border-radius: 4px;
    cursor: pointer;
  }
  img{
    margin:0 8px;
    width:80px;
    height: 55px;
    cursor: pointer;
  }
}
.bgimg-box{
  margin-left:86px;
  position: relative;
  .Image{
    position: relative;
    width: 160px;
    height: 90px;
    input{
      width: 160px;
      height: 90px;
      position: absolute;
      top:0px;
      left: 0px;
    }
  }
  #bgImg{
    width: 160px;
    height: 90px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    opacity: 1;
    border-radius: 4px;
    text-align: center;
    img{
        width:100%;
        height: 100%;
        background: #fff;
    }
    p{
      margin:5px 0;
      font-size: 12px;
      color: #999999;
    }
  }
}
</style>
