
<template>
    <div>
        <div class="bg-two">
            <!-- v-if="type == 2" -->
            <div class="logo"><img src="../assets/icon/logo-write.svg" alt=""></div>
            <div class="main" v-if="onlineStatus">
                <div class="title">
                    <p>{{meeting_title}}</p>
                </div>
                <div class="content">
                    <span><img src="../assets/icon/timer.svg" style="width:15px;margin-right:5px;">请稍后，会议主持人即将邀请您入会</span>
                </div>
            </div>
        </div>
        <broadcast 
            @client_id="getClientId"
            @scoket_waiting_allow="scoket_waiting_allow"
            @scoket_waiting_remove="scoket_waiting_remove"
        ></broadcast>
    </div>
</template>

<script>
import broadcast from "../components/broadcast";

export default {
    data(){
        return{
            client_id:"",
            meetinfo:"",
            meeting_title:"",
            onlineStatus:true,
        }
    },
    components: {
        broadcast,
    },
    created(){
        this.meetinfo = JSON.parse(window.localStorage.getItem("meetinfo"));
    },
    methods:{
        // 接收 scoket 加入成功client_id
        getClientId(data) {
            this.client_id = data;
            this.waiting_room(this.meetinfo)
        },

        // 接收通知 主持同意等待室入会
        scoket_waiting_allow(data){
            if(this.meetinfo.uid == data.uid){
                this.$router.push({path:"/detection",query:{Camera:this.$route.query.camera,Mic:this.$route.query.Mic}});
            }
        },

        // 接收通知 主持移除等待室人员 
        scoket_waiting_remove(data){
            console.log(data)
            if(this.meetinfo.uid == data.uid){
                let num = 5;
                let Timer = setInterval(()=>{
                    num -= 1;
                    if(num <= 0){
                         this.out_meeting(data);
                         clearInterval(Timer);
                    }
                },1000)
                this.$alert('主持人已将你从此会议中移除', '离开会议', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type: "warning",
                    callback: () => {
                        this.out_meeting(data);
                    }
                });
            }
        },

        out_meeting(data) {
            this.$store.dispatch("out_meeting", {
                meeting_id: data.meeting_id,
                uid: data.uid,
                type: "leave",
            }).then(res => {
                if (res.data.code == 200) {
                    this.$router.push({ path: "/loginout" });
                    window.localStorage.removeItem("meetinfo");
                    // window.localStorage.removeItem("token");
                    window.localStorage.removeItem("uid");
                    window.localStorage.removeItem("shareUid");
                }
            })
        },
        waiting_room(params){
            let obj = {
                id:window.localStorage.getItem('meetinfoId'),
                uid:params.uid,
                name:params.name,
                meeting_id:params.meeting_id,
                identity:params.identity,
                client_id:this.client_id,
                video:this.$route.query.Camera,
                scene:this.$route.query.Mic
            }
            this.$store.dispatch("waiting_room",obj).then(res=>{
                if(res.data.code == 200){
                    this.meeting_title = res.data.data.meeting_title;
                    this.$message({
                        message:res.data.message,
                        type:"success"
                    })
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.bg-two{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  zoom: 1;
  z-index: -999;
  background: url('../assets/icon/bg.png');
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-position: center 0;
  .block{
    width: 100%;
    height: 30%;
    background: #fff;
    position: absolute;
    bottom: 14%;
  }
  .logo{
    position: absolute;
    img{
      width: 130px;
    }
    left: 45px;
    top:30px;
  }
}
.main{
    width: 480px;
    height: 220px;
    background: #FFFFFF;
    box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.03);
    opacity: 1;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    .title{
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
        border-bottom:1px solid rgba(0, 0, 0, 0.1);
        p{margin: 15px;}
    }
    .content{
        font-size: 18px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        padding-top: 55px;
    }
}
</style>