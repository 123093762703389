<template>
  <div class="detection-main" v-wechat-title="$route.meta.title">
    <div class="bg">
      <div class="logo"><img src="../assets/icon/logo-write.svg" alt=""></div>
    </div>
    <div class="main">
      <div v-show="!over">
        <div v-show="ready" class="first">
          <p class="skip-inspection" v-if="start_status!=0 && !loading" @click="join()">跳过检测></p>
          <div class="title">设备检测</div>
          <div class="tip">设备检测前请务必给当前页面开放摄像头，麦克风权限哦~</div>
          <div class="equipment">
            <img src="@/assets/icon/camera.svg" alt="">
            <img class="dui" style="left:115px;" :src="camera1 ? require('@/assets/icon/dui.svg') : require('@/assets/icon/cuo.svg')" alt="" v-if="!loading">
            <img src="@/assets/icon/loudspeaker.svg" alt="">
            <img class="dui" style="left:285px;" :src="loudspeaker ? require('@/assets/icon/dui.svg') : require('@/assets/icon/cuo.svg')" alt="" v-if="!loading">
            <img src="@/assets/icon/mic.svg" alt="">
            <img class="dui" style="left:455px;" :src="mic ? require('@/assets/icon/dui.svg') : require('@/assets/icon/cuo.svg')" alt="" v-if="!loading">
          </div>
          <div class="btn">
            <p>欢迎使用轻视云会议</p>
            <button :style="start_status == 0 && !loading ? 'background:#ccc;cursor: default;' :''" :disabled='start_status==0 && !loading ? true : false' v-loading="loading"  @click="start_status != 0 ? startCamera() : ''">开始检测</button>
            <p style="color:#999;font-size:12px;margin:3px;">为了保证良好的使用体验，建议使用<span style="font-weight: bold;padding:0 2px;">Chrome浏览器</span>访问。</p>
            <p style="color:#999;font-size:12px;margin:3px;">建议浏览器分辨率不低于1366X768。</p>
          </div>
        </div>

        <div class="second" v-show="!ready">
          <div class="second-top flex">
            <p><img :src="camera ? require('@/assets/icon/camera_active.svg') : require('@/assets/icon/camera.svg')" alt=""><br><span>摄像头</span><span v-show="camera">检测中...</span></p>
            <span class="line"></span>
            <p><img :src="speak ? require('@/assets/icon/loudspeaker_active.svg') : require('@/assets/icon/loudspeaker.svg')" alt=""><br><span>扬声器</span><span v-show="speak">检测中...</span></p>
            <span class="line"></span>
            <p><img :src="Mic ? require('@/assets/icon/mic_avtive.svg') :require('@/assets/icon/mic.svg')" alt=""><br><span>麦克风</span><span v-show="Mic">检测中...</span></p>
          </div>
          <div v-show="camera" class="camera-main">
            <div class="flex camera-main-top">
              <span>摄像头选择：</span>
              <el-select style="width: 77%;" v-model="currentCameraDeviceId" placeholder="请选择">
                  <el-option
                    v-for="item in Cameras"
                    :key="item.deviceId"
                    :label="item.label"
                    :value="item.deviceId">
                  </el-option>
              </el-select>
            </div>
            <div class="camera-main-center">
              <div id="localvideo"></div>
              <div class="text">
                <span>如果您无法看到视频，请按以下方式排查问题</span>
                <p>1.若杀毒软件或“电脑管家”类软件弹出提示信息，请选择“允许”；</p>
                <p>2.请确认您的摄像头已连接并开启；</p>
                <p>3.确认您的摄像头没有被其他应用程序占用；</p>
                <p>4.如以上方法无法解决，请重启电脑或更换一台电脑测试。</p>
              </div>
            </div>
            <div class="camera-main-bottom" style="margin-top:17px;">
                <el-button class="lose" type="primary" plain round @click="startSpeak(false)">看不到</el-button>
                <el-button type="primary" round @click="startSpeak(true)">能看到</el-button>
              </div>
          </div>
          <div v-show="speak" class="camera-main">
            <div class="flex camera-main-top">
                <span>扬声器选择：</span>
                <el-select style="width: 77%;" v-model="currentPlaybackDevices" placeholder="请选择">
                    <el-option
                      v-for="item in PlaybackDevices"
                      :key="item.deviceId"
                      :label="item.label"
                      :value="item.deviceId">
                    </el-option>
                  </el-select>
              </div>
              <div style="margin: 0px 0;padding:0 10px;">
                <el-row>
                  <el-col :span="2">
                    <img style="width:30px;margin-top:5px;cursor: pointer" @click="PlaybackDevicesPlayStop" :src="PlaybackIsPlay ? require('@/assets/SVG/play.svg') : require('@/assets/SVG/play1.svg')" alt="">
                  </el-col>
                  <el-col :span="22">
                    <el-slider v-model="PlaybackLevel"></el-slider>
                  </el-col>
                </el-row>
              </div>
              <div class="text" style="margin:12px 15px 15px;">
                <span>如果您无法听见声音，请按以下方式排查问题</span>
                <p>1.若杀毒软件或“电脑管家”类软件弹出提示信息，请选择“允许”；</p>
                <p>2.确认手机、扬声器已连接并开启；</p>
                <p>3.如果耳机、扬声器音量已经调整到最大；</p>
                <p>4.请选择正确的耳机选项，选择禁用会导致耳机、扬声器不可用；</p>
                <p>5.如果耳机、扬声器仍然没有声音，换一个插口重新插入耳机、扬声器；</p>
                <p>6.如以上方法无法解决，请重启电脑或更换一台电脑测试。</p>
              </div>
                <div class="camera-main-bottom">
                  <el-button class="lose" type="primary" plain round @click="startMic(false)">听不到</el-button>
                  <el-button  type="primary" round @click="startMic(true)">能听到</el-button>
              </div>
          </div>
          <div v-show="Mic" class="camera-main">
            <div class="flex camera-main-top">
              <span>麦克风选择：</span>
              <el-select style="width: 77%;" v-model="currentMicDeviceId" placeholder="请选择">
                <el-option
                  v-for="item in Microphones"
                  :key="item.deviceId"
                  :label="item.label"
                  :value="item.deviceId">
                </el-option>
              </el-select>
            </div>
            <div style="margin: 17px 0;padding:0 10px;">
              <el-progress :stroke-width="5" :show-text="false" :percentage="VolumeLevel*100"></el-progress>
            </div>
            <div class="text" style="margin:6px 15px 25px;">
              <span>如果您无法看到音量波动条，请按以下方式排查问题</span>
              <p>1.若杀毒软件或“电脑管家”类软件弹出提示信息，请选择“允许”；</p>
              <p>2.请确认您的麦克风已连接并开启；</p>
              <p>3.确认麦克风已插入麦克风插孔中，并且麦克风声音已调整到最大；</p>
              <p>4.请选择正确的麦克风选项，选择禁用会导致麦克风不可用；</p>
              <p>5.如果麦克风仍然没有声音，换一个插口重新插入麦克风；</p>
              <p>6.如以上方法无法解决，请重启电脑或更换一台电脑测试。</p>
            </div>
            <div class="camera-main-bottom">
              <el-button class="lose" type="primary" plain round @click="overtest(false)">看不到波动</el-button>
              <el-button  type="primary" round @click="overtest(true)">能看到波动</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-show="over" class="first">
        <div class="title">检测报告</div>
        <div class="report-box">
          <div class="flex report">
            <p class="flex"><img src="@/assets/icon/camera.svg" alt=""><span style="padding-left:20px;">摄像头</span></p>
            <span style="color:#0099ff;" v-if="res.camera">正常</span>
            <span style="color:#ef5350;" v-else>异常</span>
          </div>
          <div class="flex report">
            <p class="flex"><img src="@/assets/icon/loudspeaker.svg" alt=""><span style="padding-left:20px;">扬声器</span></p>
            <span style="color:#0099ff;" v-if="res.speak">正常</span>
            <span style="color:#ef5350;" v-else>异常</span>
          </div>
          <div class="flex report">
            <p class="flex"><img src="@/assets/icon/mic.svg" alt=""><span style="padding-left:20px;">麦克风</span></p>
            <span style="color:#0099ff;" v-if="res.Mic">正常</span>
            <span style="color:#ef5350;" v-else>异常</span>
          </div>
        </div>
        <div class="camera-main-bottom">
          <el-button class="lose" type="primary" plain round @click="reset">重新检测</el-button>
          <el-button type="primary" round @click="join()">进入会议</el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import AgoraRTC from "agora-rtc-sdk-ng";
  export default{
    data(){
      return{
        activeShow:0,
        ready:true,
        Mic:false,
        speak:false,
        camera:false,
        over:false,
        Cameras:[],
        currentCameraDeviceId:'',
        Microphones:[],
        currentMicDeviceId:'',
        PlaybackDevices:[],
        currentPlaybackDevices:'',
        rtc:{
          videoTrack:null,
          audioTrack:null,
          BufferSourceAudioTrack:null,
        },
        VolumeLevel:0,
        interval1:null,
        PlaybackLevel:100,
        PlaybackIsPlay:false,
        res:{
          Mic:false,
          speak:false,
          camera:false,
        },
        // meetinfo:{},
        // token:"",
        camera_use:0,    // 摄像头使用设备
        speaker_use:0,   // 扬声器使用设备
        mic_use:0,       // 麦克风使用设备

        start_status:1,
        loading:true,

        loudspeaker:false,
        camera1:false,
        mic:false,
      }
    },

    watch:{
      currentMicDeviceId(newv,oldv){
        if(!oldv){return;}
        this.setCurrentMicDeviceId(newv);
        this.Microphones.forEach((item,index)=>{
          if(item.deviceId == newv){
            this.mic_use = index;
          }
        })
      },

      PlaybackLevel(newv){
        this.setPlaybackDevicesVolume(newv);
      },

      currentPlaybackDevices(newv,oldv){
        if(!oldv){return;}
        this.setPlaybackDevicesId(newv);
        this.PlaybackDevices.forEach((item,index)=>{
          if(item.deviceId == newv){
            this.speaker_use = index;
          }
        })
      },

      currentCameraDeviceId(newv,oldv){
        if(!oldv){return;}
        this.setCurrentCameraDeviceId(newv);
        this.Cameras.forEach((item,index)=>{
          if(item.deviceId == newv){
            this.camera_use = index;
          }
        })
      }

    },

    created() {
      if(!window.localStorage.getItem('uid')){
        this.$router.push({path:'/loginout'});
        return;
      }
      if(!window.localStorage.getItem('meetinfo')){
        this.$router.push({path:'/loginout'});
        return;
      }
      if(!window.localStorage.getItem('token')){
        this.$router.push({path:'/loginout'});
        return;
      }
      // this.meetinfo = JSON.parse(window.localStorage.getItem('meetinfo'));
      // this.token = JSON.parse(window.localStorage.getItem('token'));
      var that = this;
      this.getDevices();
      //设置日志级别
      AgoraRTC.setLogLevel(4);

      this.Changed();
    },

    methods:{
      Changed(){
        // 检测有摄像头被添加或移除。
        AgoraRTC.onCameraChanged = async (info) => {
          console.log("camera changed!");
          this.Cameras = await AgoraRTC.getCameras();
        };

        // 检测有麦克风被添加或移除。
        AgoraRTC.onMicrophoneChanged = async (info) => {
          console.log("microphone changed!");
          this.Microphones = await AgoraRTC.getMicrophones();
        };

        AgoraRTC.onPlaybackDeviceChanged = async (info) => {
          console.log("speaker changed!");
          this.PlaybackDevices = await AgoraRTC.getPlaybackDevices();
        };
      },
      startCamera(){
        this.camera = true;
        this.ready = false;
        this.rtc.videoTrack.play("localvideo");
        // this.res.speak= e;
      },
      startSpeak(e){
        clearInterval(this.interval1)
        this.camera = false;
        this.speak = true;
        this.res.camera = e;
      },
      startMic(e){
        this.speak = false;
        this.Mic = true;
        let that = this;
        this.interval1 = setInterval(function (){
          that.getVolumeLevel()
        },100)
        this.res.speak = e;
        if(this.PlaybackIsPlay){
          this.PlaybackDevicesPlayStop();
        }
      },


      //获取客户端媒体设备
      getDevices(){
        var that = this
        AgoraRTC.getCameras().then(Cameras=>{
          that.Cameras = Cameras;
          that.currentCameraDeviceId = Cameras[0].deviceId;
          that.camera1 = true;
        }).catch(e=>{
          console.log("get Cameras error!", e);
          that.camera1 = false;
        })
        AgoraRTC.getMicrophones().then(Microphones=>{
          that.Microphones = Microphones;
          that.currentMicDeviceId = Microphones[0].deviceId;
          that.mic = true;
        }).catch(e=>{
          console.log("get Microphones error!", e);
          that.mic = false;
        })
        AgoraRTC.getPlaybackDevices().then(PlaybackDevices=>{
          that.PlaybackDevices = PlaybackDevices
          that.currentPlaybackDevices = PlaybackDevices[0].deviceId;
          that.loudspeaker = true;
        }).catch(e=>{
          console.log("get PlaybackDevices error!", e);
          that.loudspeaker = false;
        })

        setTimeout(()=>{
          this.createCameraVideoTrack();
        },1000)
      },

      //通过摄像头和麦克风创建视频/音频轨道对象
      async createCameraVideoTrack(){
        var that = this
        if(!that.camera1 || !that.mic || !that.loudspeaker){
          that.loading = false;
          that.start_status = 0;
          this.$alert('未检测到可用设备（包含未启用设备），请检查您的设备！', '提示', {
            confirmButtonText: '确定',
          });
        } 
        if (!this.rtc.audioTrack || !this.rtc.videoTrack || !this.rtc.BufferSourceAudioTrack) {
          [ this.rtc.audioTrack, this.rtc.videoTrack , this.rtc.BufferSourceAudioTrack] = await Promise.all([
            // create local tracks, using microphone and camera
            AgoraRTC.createMicrophoneAudioTrack({ microphoneId: that.currentMicDeviceId }),
            AgoraRTC.createCameraVideoTrack({ cameraId: that.currentCameraDeviceId , encoderConfig:'720p_3'}),
            AgoraRTC.createBufferSourceAudioTrack({cacheOnlineFile:false,encoderConfig:"music_standard",source:"https://images.innocomn.com/%E8%BD%BB%E7%9B%B4%E6%92%AD%E9%85%8D%E9%9F%B3_%E7%BC%A9%E6%B7%B7.mp3"})
          ]);
        }
        // this.rtc.videoTrack.setBeautyEffect(true);
        this.start_status = 1;
        this.loading = false;
      },

      //播放本地音频文件
      PlaybackDevicesPlayStop(){
        this.PlaybackIsPlay = !this.PlaybackIsPlay;
        if(this.PlaybackIsPlay){
          this.rtc.BufferSourceAudioTrack.startProcessAudioBuffer()
          this.rtc.BufferSourceAudioTrack.play();
        }else{
          this.rtc.BufferSourceAudioTrack.pauseProcessAudioBuffer();
          this.rtc.BufferSourceAudioTrack.stop();
        }
      },

      //设置当前音频播放设备音量
      setPlaybackDevicesVolume(number){
        this.rtc.BufferSourceAudioTrack.setVolume(number);
      },

      //设置音频播放器设备
      setPlaybackDevicesId(deviceId){
        this.rtc.BufferSourceAudioTrack.setPlaybackDevice(deviceId);
      },

      /**
       * 设置当前使用的摄像头
       */
      setCurrentCameraDeviceId(DeviceId){
        this.rtc.videoTrack.setDevice(DeviceId);
      },

      /**
       * 设置当前使用的麦克风
       */
      setCurrentMicDeviceId(DeviceId){
        this.rtc.audioTrack.setDevice(DeviceId)
      },

      getVolumeLevel(){
        this.VolumeLevel = this.rtc.audioTrack.getVolumeLevel();
      },

      //结束测试
      overtest(e){
        this.rtc.videoTrack.stop();
        this.Mic = false;
        this.over = true;
        this.res.Mic = e;
      },

      //重新检查
      reset(){
        this.res.camera = false;
        this.res.speak = false;
        this.res.Mic = false;
        this.over = false;
        this.ready = true;
        this.activeShow = 0;
      },

      //进入会议
      join(){
        this.$router.push({path:'/host',params:{
            currentCameraDeviceId:this.currentCameraDeviceId,
            currentMicDeviceId:this.currentMicDeviceId,
            currentPlaybackDevices:this.currentPlaybackDevices,
            ok:true
        },query:{
          Camera:this.$route.query.Camera,
          Mic:this.$route.query.Mic,
          CameraUse:this.camera_use,
          SpeakerUse:this.speaker_use,
          MicUse:this.mic_use,
        }});
      },
    },
  }
</script>

<style scoped lang='less'>
  .flex{
    display: flex;
    align-items: center;
  }
  .bg{
    background: url('../assets/icon/bg.png');
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    zoom: 1;
    z-index: -999;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    .logo{
      position: absolute;
      left: 45px;
      top:30px;
      img{
        width: 130px;
      }
    }
  }
  .detection-main{
    height: 100%;
  }
  .detection-main .main{
    width: 650px;
    height: 530px;
    background: #FFFFFF;
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.08);
    opacity: 1;
    border-radius: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .first{
      text-align: center;
      padding: 48px 60px;
      position: relative;
      .skip-inspection{
        position: absolute;
        right: 30px;
        top:10px;
        font-size: 14px;
        color: #999;
        cursor: pointer;
      }
      .skip-inspection:hover{
        color: #0099ff;
        text-decoration: underline;
      }
      .title{font-size: 36px;color: #333333;}
      .tip{font-size: 20px;color: #666666;margin: 40px 0;}
      .equipment{
        display: flex;
        justify-content: space-around;
        position: relative;
        img{
          width: 60px;
          height: 60px;
          // border-radius: 50%;
        }
        .dui{
          position: absolute;
          width: 30px;
          left: 0;
          bottom:-20px;
        }
      }
      .btn{
        p{color: #0099ff;font-size: 22px;font-weight: bold;margin: 75px 0 40px;}
        button{
          width: 130px;
          height: 40px;
          background: #0099ff;
          opacity: 1;
          border-radius: 36px;
          font-size: 18px;
          cursor: pointer;
          margin-bottom: 35px;
        }
      }
      .report-box{
        .report{
          // width:75%;
          display: flex;
          justify-content: space-between;
          margin: 30.3px 90px;
          padding: 0 30px 0 20px;
        }
        img{
          width: 45px;
          height: 45px;
        }
        span{
          font-size: 20px;
        }
      }
      .camera-main-bottom{
          text-align: center;
          button{
            width: 130px;
            height: 40px;
            border-radius: 36px;
            font-size: 16px;
            margin:4px 50px 0;
          }
          .lose{
            background: #1C2C3B;
            color:#fff;
            border:none;
          }
      }
    }
    .second{
      .second-top{
        justify-content: center;
        border-bottom:1px solid #ccc;
        padding: 25px 0 10px;
        img{
          width: 40px;
          height: 40px;
        }
        .line{
          display: inline-block;
          width: 80px;
          height: 3px;
          background: #1C2C3B;
          border-radius: 2px;
          margin:0 10px;
        }
        p{
          width:139px;
          text-align: center;
          span{
              font-size: 19px;
              color: #333333;
              margin: 5px 0;
              display: inline-block;
          }
        } 
      }
      .camera-main{
        padding: 30px 35px;
        .camera-main-top{
          justify-content: center;
          span{
            font-size: 22px;
            color:#333;
          }
          /deep/.el-input__inner{
            height: 40px;
            border-radius: 10px;
            border: 1px solid #999999;
            margin-bottom: 5px;
          }
        }
        .camera-main-center{
          display: flex;
          justify-content: center;
          padding: 35px 0 29px;
        }
        /deep/.el-slider{
          .el-slider__runway,.el-slider__bar{
            height:5px!important;
          }
          .el-slider__button-wrapper{
            top:-16px;
          }
        }
      }
      .camera-main-bottom{
          text-align: center;
          button{
            width: 130px;
            height: 40px;
            border-radius: 36px;
            font-size: 16px;
            margin:0px 50px 0;
          }
          .lose{
            background: #1C2C3B;
            color:#fff;
            border:none;
          }
      }
      .text{
        margin-left:30px;
        margin-top:-10px;
        span{
          font-size: 16px;
        }
        p{
          font-size: 14px;
          color: #999;
        }
      }
    }
  }
  .detection-main .check-tab{
    width: 100%;
  }
  .detection-main .check-tab p{
    text-align: center;
    color: rgba(0,0,0,.4);
  }
  .detection-main .check-tab .active{
    color: rgba(0,0,0,.7);
    font-weight: bold;
  }
  .detection-main .check-tab span{
    width: 23px;
    height: 23px;
    display: inline-block;
    background-color: rgba(0,0,0,.4);
    border-radius: 23px;
    text-align: center;
    margin-right: 10px;
    color: white;
  }
  .detection-main .meetImg{
    height: 150px;
    margin-top: 50px;
    text-align: center;
  }
  .detection-main .meetImg img{
    height: 100%;
  }
  .detection-main .title1{
      text-align: center;
      margin: 40px 0;
      font-size: 20px;
  }
  .detection-main .title2{
      text-align: center;
      margin: 20px 0;
      font-size: 16px;
      color: rgba(0,0,0,.5);
  }
  .detection-main .btn{
    text-align: center;
    margin: 30px 0;
  }
  .detection-main .btn button{
    width: 160px;
    height: 40px;
    border: none;
    background-color: #0099ff;
    color: white;
    border-radius: 20px;
  }
  button:focus{
    outline: none;
  }
  .detection-main .count{
    margin-top: 30px;
  }
  .detection-main .text p{
    font-size: 14px;
    color: rgba(0,0,0,.4);
    margin: 5px 0;
  }
  #localvideo{
    width: 230px;
    height: 129px;
    margin: 0 auto 15px;
    background-image: url("../assets/videoHead.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    border-radius: 5px;
    overflow: hidden;
  }
</style>
