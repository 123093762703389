<template>
<div @click="cancel($event)">
    <div class="subscribeVideo-main" style="height:160.3px;" v-if="homeTopShow" >
      <div class="model" @click="left()"> 
        <img :src=" leftScroll >0 && subscribeUser.length * 285 >= screenWidth -70 ? require('../assets/SVG/zuo.svg') : require('../assets/left.svg')" />
      </div>
      <div class="Mvideo" id="Mvideo" :style="subscribeUser.length * 285 >= screenWidth -70 ? '' : 'display: flex;justify-content: center;'">
        <div class="videos" v-for="(item,index) in subscribeUser" :key="index" :id="item.uid" 
        @mouseleave="show[index].More = false"
        @dblclick="isShare!=1 && screenUid=='' ? show[index].Fixed == 1 ? setScreenFocus(item,index,0) : setScreenOut(item,index,0) : ''" 
        :style="{backgroundImage: fixedUid == item.uid || screenUid == item.uid || largeVolumeUid == item.uid ? 'url(' + videobg + ')' : ''}"
        v-show="item.uid != share_uid">
        <!-- item.uid != uid &&  -->
          <!-- && i.uid != share_uid           || i.uid == share_uid-->
          <p class="videos-bg-name" v-for="a in userList" :key="a.uid+1" v-show="a.uid == item.uid && !item.hasVideo">{{a.username}}</p>
          <div class="user-name" v-for="(i,j) in userList" :key="j" v-show="i.uid == item.uid">
            <img style="width:21px;" v-if="show[index].VideoShow == 0" src="../assets/SVG/cameraClose.svg"/>
            <img v-if="show[index].AudioShow == 0" style="width:11px;margin-left:10px;" src="../assets/SVG/MicClose.svg"/>
            <span :style="show[index].VideoShow == 0 || show[index].AudioShow == 0 ? 'border-left: 1px solid #666;padding-left: 8px;margin-left:10px;' : ''" v-if="show[index].VideoShow == 1">{{i.username}}</span>
          </div>
          <div class="live-status" v-if="liveStatus == 1" @click="setLive(item)"><img :src="show[index].live == 1 ? require('../assets/SVG/hong.svg') :  require('../assets/SVG/bai.svg')" /></div>
          <div class="set-box">
            <div class="user-set" v-if="adminType == 1 && item.uid != uid && item.uid != share_uid">
              <el-tooltip content="摄像头" placement="bottom" :open-delay='200'>
              <img class="icon-img" style="width:21px;" @click="openCloseView(item,index)" :src="show[index].VideoShow == 1 ? require('../assets/SVG/cameraOpen.svg') : require('../assets/SVG/cameraClose.svg')"/>
              </el-tooltip>
              <el-tooltip content="麦克风" placement="bottom" :open-delay='200'>
              <img class="icon-img" style="width:11px;" @click="openCloseAudio(item,index)" :src="show[index].AudioShow == 1 ? require('../assets/SVG/MicOpen.svg') : require('../assets/SVG/MicClose.svg')"/>
              </el-tooltip>
              <!-- <el-tooltip content="焦点视图" placement="bottom" :open-delay='200'>
              <img class="icon-img" style="width:15px;" v-if="adminType == 1 && item.uid != uid && isShare != 1" @click="mainScreenTrueOrFalse(item,index)" :src="show[index].BlowUp == 1 ? require('../assets/SVG/magnify.svg') : require('../assets/SVG/unMagnify.svg')"/>
              </el-tooltip> -->
              <el-tooltip content="选定视图" placement="bottom" :open-delay='200'>
              <img class="icon-img" :style="show[index].Fixed == 1 ? 'width:15px;' : 'width:13px'" v-if="isShare != 1" @click="show[index].Fixed == 1 ? setScreenFocus(item,index,0) : setScreenOut(item,index,0) " :src="show[index].Fixed == 1 ? require('../assets/SVG/select.svg') : require('../assets/SVG/unSelect.svg')"/>
              </el-tooltip>
              <div class="more" :id="`more-dialog-btn-${index}`" v-if="item.uid != share_uid && item.uid != uid && !isShare" v-show="adminType == 2 ? screenUid=='' : true" @click="moreSet(item,index)"><img class="icon-img"  style="width:16px;height:14px;" :src="!show[index].More ? require('../assets/SVG/more.svg') : require('../assets/SVG/active_more.svg')"/></div>
            </div>
          </div>
          <transition name="morefade">
            <div class="more-dialog" :id="`more-dialog-${index}`" v-show="show[index].More">
              <p @click="openCloseView(item,index)" v-if="item.uid != uid && adminType == 1" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].VideoShow != 1">开启</span><span v-else>关闭</span>摄像头</p>
              <p @click="openCloseAudio(item,index)" v-if="item.uid != uid && adminType == 1" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].AudioShow != 1">开启</span><span v-else>关闭</span>麦克风</p>
              <p v-if="item.uid != uid && adminType == 1" @click="removeParticipants(item)" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''">移除参会者</p>
              <!-- <p v-if="adminType == 1">设为主持人</p> -->
              <p v-if="adminType == 1 && item.uid != uid && isShare != 1" @click="mainScreenTrueOrFalse(item,index)" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].BlowUp == 0">取消</span>焦点视图</p>
              <p v-if="isShare != 1 " @click="show[index].Fixed == 1 ? setScreenFocus(item,index,0) : setScreenOut(item,index,0)" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].Fixed == 0">取消</span>选定视图</p>
              <p v-if="isShare != 1 " @click="show[index].fullScreen == 1 ? setScreenFocus(item,index,1) : setScreenOut(item,index,1)" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].fullScreen == 0">取消</span>全屏显示</p>
              <div class="popper__arrow"></div> 
            </div>
          </transition>
        </div>
      </div>
      <div class="model" @click="right()">
        <img :src="leftScroll <=0 && subscribeUser.length * 285 >= screenWidth -70 ? require('../assets/SVG/zuo.svg') : require('../assets/right.svg')" :style="leftScroll <=0 && subscribeUser.length * 285 >= screenWidth -70 ? 'transform:rotate(180deg)' : ''" />
      </div>
    </div>
    <div class="subscribeVideo-main-sidebar" v-if="homeRightShow">
      <div class="model top" @click="top()">
        <img src="../assets/left.svg" />
      </div>
      <div class="Mvideo" id="Mvideo">
        <div class="videos" v-for="(item,index) in subscribeUser" :key="index" :id="item.uid" 
        @mouseleave="show[index].More = false"
        @dblclick="isShare!=1 ? show[index].Fixed == 1 ? setScreenFocus(item,index,0) : setScreenOut(item,index,0) : ''" 
        :style="{backgroundImage: fixedUid == item.uid || screenUid == item.uid || largeVolumeUid == item.uid? 'url(' + videobg + ')' : '',
          border:streamShareUid == item.uid ? '1px solid #0099ff' : ''}"
        v-show="item.uid != share_uid">
        <!-- item.uid != uid &&  -->
          <!-- && i.uid != share_uid           || i.uid == share_uid-->
          <p class="videos-bg-name" v-for="a in userList" :key="a.uid+1" v-show="a.uid == item.uid && !item.hasVideo">{{a.username}}</p>
          <div class="user-name" v-for="(i,j) in userList" :key="j" v-show="i.uid == item.uid">
            <img style="width:21px;" v-if="show[index].VideoShow == 0" src="../assets/SVG/cameraClose.svg"/>
            <img v-if="show[index].AudioShow == 0" style="width:11px;margin-left:10px;" src="../assets/SVG/MicClose.svg"/>
            <span :style="show[index].VideoShow == 0 || show[index].AudioShow == 0 ? 'border-left: 1px solid #666;padding-left: 8px;margin-left:10px;' : ''" v-if="show[index].VideoShow == 1">{{i.username}}</span>
          </div>
          <div class="live-status" v-if="liveStatus == 1" @click="setLive(item)"><img :src="show[index].live == 1 ? require('../assets/SVG/hong.svg') :  require('../assets/SVG/bai.svg')" /></div>
          <div class="set-box">
            <div class="user-set" v-if="adminType == 1 && item.uid != uid && item.uid != share_uid">
              <el-tooltip content="摄像头" placement="bottom" :open-delay='200'>
              <img class="icon-img" style="width:21px;" @click="openCloseView(item,index)" :src="show[index].VideoShow == 1 ? require('../assets/SVG/cameraOpen.svg') : require('../assets/SVG/cameraClose.svg')"/>
              </el-tooltip>
              <el-tooltip content="麦克风" placement="bottom" :open-delay='200'>
              <img class="icon-img" style="width:11px;" @click="openCloseAudio(item,index)" :src="show[index].AudioShow == 1 ? require('../assets/SVG/MicOpen.svg') : require('../assets/SVG/MicClose.svg')"/>
              </el-tooltip>
              <!-- <el-tooltip content="焦点视图" placement="bottom" :open-delay='200'>
              <img class="icon-img" style="width:15px;" v-if="adminType == 1 && item.uid != uid && isShare != 1" @click="mainScreenTrueOrFalse(item,index)" :src="show[index].BlowUp == 1 ? require('../assets/SVG/magnify.svg') : require('../assets/SVG/unMagnify.svg')"/>
              </el-tooltip> -->
              <el-tooltip content="选定视图" placement="bottom" :open-delay='200'>
              <img class="icon-img" style="width:15px;" v-if="isShare != 1" @click="show[index].Fixed == 1 ? setScreenFocus(item,index,0) : setScreenOut(item,index,0) " :src="show[index].Fixed == 1 ? require('../assets/SVG/select.svg') : require('../assets/SVG/unSelect.svg')"/>
              </el-tooltip>
              <div class="more" :id="`more-dialog-btn-${index}`" v-if="item.uid != share_uid && item.uid != uid && !isShare" v-show="adminType == 2 ? screenUid=='' : true" @click="moreSet(item,index)"><img class="icon-img"  style="width:18px;height:14px;" :src="!show[index].More ? require('../assets/SVG/more.svg') : require('../assets/SVG/active_more.svg')"/></div>
            </div>
          </div>
          <transition name="morefade">
            <div class="more-dialog" :id="`more-dialog-${index}`" v-show="show[index].More">
              <p @click="openCloseView(item,index)" v-if="item.uid != uid && adminType == 1" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].VideoShow != 1">开启</span><span v-else>关闭</span>摄像头</p>
              <p @click="openCloseAudio(item,index)" v-if="item.uid != uid && adminType == 1" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].AudioShow != 1">开启</span><span v-else>关闭</span>麦克风</p>
              <p v-if="item.uid != uid && adminType == 1" @click="removeParticipants(item)" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''">移除参会者</p>
              <!-- <p v-if="adminType == 1">设为主持人</p> -->
              <p v-if="adminType == 1 && item.uid != uid && isShare != 1" @click="mainScreenTrueOrFalse(item,index)" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].BlowUp == 0">取消</span>焦点视图</p>
              <p v-if="isShare != 1" @click="show[index].Fixed == 1 ? setScreenFocus(item,index,0) : setScreenOut(item,index,0)" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].Fixed == 0">取消</span>选定视图</p>
              <div class="popper__arrow"></div>
            </div>
          </transition>
        </div>
      </div>
      <div class="model bottom" @click="bottom()">
        <img src="../assets/right.svg"/>
      </div>
    </div>
    <div class="gallery-view" v-if="homeGalleryShow" :style="{width:screenWidth,height:Height+'px'}">
      <div class="videos-box" :style="{height:subscribeUser.length > 2 && subscribeUser.length < 7 ? (videoHeight*2+10)+'px'  : 
      subscribeUser.length >= 7 && subscribeUser.length < 13 ? (videoHeight*3+15)+'px' : 
      subscribeUser.length >= 13 && subscribeUser.length < 17 ? (videoHeight*4+20)+'px' : Height+'px'}">
        <div class="videos" v-for="(item,index) in subscribeUser" :key="index" :id="item.uid" 
        @mouseleave="show[index].More = false"
        @dblclick="isShare!=1 ? show[index].Fixed == 1 ? setScreenFocus(item,index,0) : setScreenOut(item,index,0) : ''" 
        :style="{backgroundImage: fixedUid == item.uid || screenUid == item.uid ? 'url(' + videobg + ')' : '',
          width:videoWidth+'px',
          height:videoHeight+'px',
          marginBottrm:videoMTop+'px',
          border:largeVolumeUid == item.uid ? '5px solid #0099ff' :''
        }"
        v-show="item.uid != share_uid">
        <!-- item.uid != uid &&  -->
          <!-- && i.uid != share_uid           || i.uid == share_uid-->
          <p class="videos-bg-name" v-for="a in userList" :key="a.uid+1" v-show="a.uid == item.uid && !item.hasVideo">{{a.username}}</p>
          <div class="user-name" v-for="(i,j) in userList" :key="j" v-show="i.uid == item.uid">
            <img style="width:21px;" v-if="show[index].VideoShow == 0" src="../assets/SVG/cameraClose.svg"/>
            <img v-if="show[index].AudioShow == 0" style="width:11px;margin-left:10px;" src="../assets/SVG/MicClose.svg"/>
            <span :style="show[index].VideoShow == 0 || show[index].AudioShow == 0 ? 'border-left: 1px solid #666;padding-left: 8px;margin-left:10px;' : ''" v-if="show[index].VideoShow == 1">{{i.username}}</span>
          </div>
          <div class="live-status" v-if="liveStatus == 1" @click="setLive(item)"><img :src="show[index].live == 1 ? require('../assets/SVG/hong.svg') :  require('../assets/SVG/bai.svg')" /></div>
          <div class="set-box">
            <div class="user-set" v-if="adminType == 1 && item.uid != uid && item.uid != share_uid">
              <el-tooltip content="摄像头" placement="bottom" :open-delay='200'>
              <img class="icon-img" style="width:21px;" @click="openCloseView(item,index)" :src="show[index].VideoShow == 1 ? require('../assets/SVG/cameraOpen.svg') : require('../assets/SVG/cameraClose.svg')"/>
              </el-tooltip>
              <el-tooltip content="麦克风" placement="bottom" :open-delay='200'>
              <img class="icon-img" style="width:11px;" @click="openCloseAudio(item,index)" :src="show[index].AudioShow == 1 ? require('../assets/SVG/MicOpen.svg') : require('../assets/SVG/MicClose.svg')"/>
              </el-tooltip>
              <!-- <el-tooltip content="焦点视图" placement="bottom" :open-delay='200'>
              <img class="icon-img" style="width:15px;" v-if="adminType == 1 && item.uid != uid && isShare != 1" @click="mainScreenTrueOrFalse(item,index)" :src="show[index].BlowUp == 1 ? require('../assets/SVG/magnify.svg') : require('../assets/SVG/unMagnify.svg')"/>
              </el-tooltip> -->
              <el-tooltip content="选定视图" placement="bottom" :open-delay='200'>
              <img class="icon-img" style="width:15px;" v-if="isShare != 1" @click="show[index].Fixed == 1 ? setScreenFocus(item,index,0) : setScreenOut(item,index,0) " :src="show[index].Fixed == 1 ? require('../assets/SVG/select.svg') : require('../assets/SVG/unSelect.svg')"/>
              </el-tooltip>
              <div class="more" :id="`more-dialog-btn-${index}`" v-if="item.uid != share_uid && item.uid != uid && !isShare" v-show="adminType == 2 ? screenUid=='' : true" @click="moreSet(item,index)"><img class="icon-img"  style="width:18px;height:14px;" :src="!show[index].More ? require('../assets/SVG/more.svg') : require('../assets/SVG/active_more.svg')"/></div>
            </div>
          </div>
          <transition name="morefade">
            <div class="more-dialog" :id="`more-dialog-${index}`" v-show="show[index].More">
              <p @click="openCloseView(item,index)" v-if="item.uid != uid && adminType == 1" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].VideoShow != 1">开启</span><span v-else>关闭</span>摄像头</p>
              <p @click="openCloseAudio(item,index)" v-if="item.uid != uid && adminType == 1" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].AudioShow != 1">开启</span><span v-else>关闭</span>麦克风</p>
              <p v-if="item.uid != uid && adminType == 1" @click="removeParticipants(item)" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''">移除参会者</p>
              <!-- <p v-if="adminType == 1">设为主持人</p> -->
              <p v-if="adminType == 1 && item.uid != uid && isShare != 1" @click="mainScreenTrueOrFalse(item,index)" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].BlowUp == 0">取消</span>焦点视图</p>
              <p v-if="isShare != 1" @click="show[index].Fixed == 1 ? setScreenFocus(item,index,0) : setScreenOut(item,index,0)" :style="show[index].Fixed == 0 || show[index].BlowUp == 0 ? ' margin-left: 8px;' : ''"><span v-if="show[index].Fixed == 0">取消</span>选定视图</p>
              <div class="popper__arrow"></div>
            </div>
          </transition>
        </div>
      </div>
    </div>
</div>
</template>

<script>
  export default {
    props:[
      'adminType',     // 参会者身份 1主持 2嘉宾 3观众
      'subscribeUser', // 订阅列表
      'homeTopShow',   // 上视图
      'homeRightShow', // 右视图
      'homeGalleryShow',// 画廊视图
      'channel_id',    // 频道id
      'screenUid',     // 主屏显示uid
      'fixedUid',      // 本地焦点视图uid
      'fullScreenUid', // 全屏显示uid
      'openAudioUid',  // 开启音频uid
      'closeAudioUid', // 关闭音频uid
      'openVideoUid',  // 开启摄像头uid
      'closeVideoUid', // 关闭摄像头uid
      'largeVolumeUid',// 语音激励说话者uid
      'liveUid',       // 直播画面用户uid
      'liveStatus',    // 直播状态
      'uid',           // 本地当前uid
      'userList',      // 参会者列表
      'attendeesDomShow',// 参会列表展开状态
      'share_uid',     // 共享uid
      'streamShareUid',  // 共享人uid
      'isShare',       // 共享状态
      'screenWidth', 
      'screenHeight',
      'subscribeSet',  // 远端用户设置展开状态
    ],
    data(){
      return{
        show:[],
        scoket:"",
        videobg:require('../assets/videobg.jpg'),
        leftScroll:0,     // 上视图滚动位置
        videoWidth:285,
        videoHeight:160.31,
        videoMTop:5,
        Height:0,
      }
    },
    watch:{
      homeGalleryShow(newv){
        newv && this.streamingConfig();
      },
      attendeesDomShow(newv){
        this.attendeesDomShow = newv;
        this.streamingConfig();
      },
      liveStatus(newv){
        this.liveStatus = newv;
      },
      screenWidth(newv){
        this.screenWidth = newv;
        console.log(newv)
        this.streamingConfig();
      },
      screenHeight(newv){
        this.screenHeight = newv;
        this.Height = newv - 45;
        this.streamingConfig();
      },
      largeVolumeUid(newv){
        this.largeVolumeUid = newv;
      },
      subscribeUser(newv){
        // console.log(this.subscribeUser,newv)
        this.streamingConfig();
        this.matchingUserList();
      },
      userList(){
        // console.log(this.userList)
        this.matchingUserList();
      },
      screenUid(){
        this.show.forEach((item,index)=> this.screenUid == item.uid ? this.show[index].BlowUp = 0 : this.show[index].BlowUp = 1 );
        console.log(this.show)
      },
      fixedUid(){
        this.show.forEach((item,index)=> this.fixedUid == item.uid ? this.show[index].Fixed = 0 : this.show[index].Fixed = 1 );
      },
      fullScreenUid(){
        this.show.forEach((item,index)=> this.fullScreenUid == item.uid ? this.show[index].fullScreen = 0 : this.show[index].fullScreen = 1 );
      },
      openAudioUid(){
        this.subscribeUser.forEach((item,index)=>{ if(this.openAudioUid == item.uid) this.show[index].AudioShow = 1; })
      },
      closeAudioUid(){
        this.subscribeUser.forEach((item,index)=> { if(this.closeAudioUid == item.uid) this.show[index].AudioShow = 0; })
      },
      openVideoUid(){
        this.subscribeUser.forEach((item,index)=>{ if(this.openVideoUid == item.uid) this.show[index].VideoShow = 1; })
      },
      closeVideoUid(){
        this.subscribeUser.forEach((item,index)=>{ if(this.closeVideoUid == item.uid) this.show[index].VideoShow = 0; })
      },
      share_uid(newv){
        this.share_uid = newv
      },
      streamShareUid(newv){
        this.streamShareUid = newv;
      },
      liveUid(newv){
        this.subscribeUser.forEach((item,index)=> this.liveUid == item.uid  ? this.show[index].live = 1 : this.show[index].live = 0 )
      },
      isShare(newv){
        this.isShare = newv;
      },
      subscribeSet(){
        if(!this.subscribeSet) this.show.forEach(item=> item.More = false );
        this.$emit('subscribeSet',true)
      },
    },
    /**
     * that.subscribeUser.forEach(item=>{
          item['AudioShow'] = true;
          item['VideoShow'] = true;
          item['BlowUp'] = true;
          item['Fixed'] = false;
        })
     */
    created(){
      this.Height = document.body.clientHeight - 45;
    },
    methods:{
      // 匹配远端用户和参会列表
      matchingUserList(){
        this.show = []
        this.subscribeUser.forEach(i=>{
          // console.log(document.getElementById(i.uid),i.uid)
          this.userList.forEach(item=>{
            if(item.uid == i.uid){
              // console.log(item,i)
              let obj = {
                uid:item.uid,
                AudioShow:item.scene,
                VideoShow:item.video,
                BlowUp:JSON.parse(window.localStorage.getItem('screenUid')) == i.uid ? 0 : 1,
                Fixed:JSON.parse(window.localStorage.getItem('fixedUid')) == i.uid ? 0 : 1,
                fullScreen:JSON.parse(window.localStorage.getItem('fullScreenUid')) == i.uid ? 0 : 1,
                live:window.localStorage.getItem('liveUid') == i.uid && window.localStorage.getItem('liveUid') ? 1 : 0,
                More:false,
              }
              this.show.push(obj)
            }
          })
        })
        // this.show.forEach((item,index)=>{
        //   item.AudioShow == undefined || item.VideoShow == undefined ? this.show.splice(index,1) : '';
        // })
        // console.log(this.show)
      },
      cancel(event){
        this.subscribeUser.forEach((item,index)=>{
          var vt = document.getElementById("more-dialog-"+index);
          var vt2 = document.getElementById("more-dialog-btn-"+index);
          if(vt !=null && vt2 !=null &&!vt.contains(event.target) && !vt2.contains(event.target)) this.show[index].More = false;
        })
      },
      // 设为直播画面
      setLive(item){
        this.$emit('set_live_status',item.uid)
      },
      // 移除参会者
      removeParticipants(item){
        let obj={
          type:"agora_public_notice",
          meeting_id:parseInt(this.channel_id),
          data:{
            type:'remove_participants',
            data:{
              uid:item.uid,
            }
          }
        }
        this.$emit('publicNotice',obj)
      },
      // 开启关闭视频
      openCloseView(item,index){
        if(this.show[index].BlowUp == 0){
          this.mainScreenTrueOrFalse(item,index)
        }
        setTimeout(()=>{
          let obj={
            type:"agora_public_notice",
            meeting_id:parseInt(this.channel_id),
            data:{
              type:'',
              data:{
                uid:item.uid,
              }
            }
          }
          if(this.show[index].VideoShow == 0){
            obj.data.type = 'videoTrack_play';
            this.$emit('publicNotice',obj)
          }else{
            obj.data.type = 'videoTrack_setEnabled';
            this.$emit('publicNotice',obj);
            this.show[index].VideoShow = 0;
          }
        },200)
      },
      // 开启关闭音频
      openCloseAudio(item,index){
        let obj={
          type:"agora_public_notice",
          meeting_id:parseInt(this.channel_id),
          data:{
            type:'',
            data:{
              uid:item.uid,
            }
          }
        }
        if(this.show[index].AudioShow == 0){
          obj.data.type = 'audioTrack_setEnabled_true';
          this.$emit('publicNotice',obj);
        }else{
          obj.data.type = 'audioTrack_setEnabled_false';
          this.$emit('publicNotice',obj);
          this.show[index].AudioShow = 0;
        }
      },
      // 设置 or 还原远端焦点视图
      mainScreenTrueOrFalse(item,index){
        if(this.show[index].VideoShow == 0){
          this.show[index].BlowUp = 1;
          this.$message({
            message: '对方摄像头未开启！',
            type: 'error'
          });
        }else{
          this.show[index].BlowUp = this.show[index].BlowUp == 1 ? 0 : 1;
          let obj={
            type:"agora_public_notice",
            meeting_id:parseInt(this.channel_id),
            data:{
              type:'',
              data:{
                uid:item.uid,
                data:item,
              }
            }
          }
          if(this.show[index].BlowUp == 0){
            obj.data.type = 'main_screen_true';
            this.$emit('publicNotice',obj)
          }else{
            obj.data.type = 'main_screen_false';
            this.$emit('publicNotice',obj)
          }
        }
      },
      //设置本地焦点视图
      setScreenFocus(item,index,full){
        if(this.show[index].VideoShow == 0){
          full == 0 ? this.show[index].Fixed = 1 : this.show[index].fullScreen = 1;
          this.$message({
            message: '对方摄像头未开启！',
            type: 'error'
          });
        }
        else if(window.localStorage.getItem('screenUid') && this.adminType == 1){
          this.$message({
            message: '请先取消用户焦点视图！',
            type: 'error'
          });
        }else{
          if(full == 0){
            this.show.forEach(i=>i.Fixed = 1);
            this.show[index].Fixed = 0;
          }else{
            this.show.forEach(i=>i.fullScreen = 1);
            this.show[index].fullScreen = 0;
          }
          let obj = {
            item:item,
            type:full,
          }
          this.$emit("setScreenFocus",obj)
        }
      },
      //还原本地焦点视图
      setScreenOut(item,index,full){
        if(full == 0){
          this.show.forEach(i=>i.Fixed = 1);
          this.show[index].Fixed = 1;
        }else{
          this.show.forEach(i=>i.fullScreen = 1);
          this.show[index].fullScreen = 1;
        }
        let obj = {
          item:item,
          type:full,
        }
        this.$emit("setScreenOut",obj)
      },
      // 更多
      moreSet(item,index){
        this.show.forEach((i,j)=> index!=j ?  i.More = false : '');
        this.show[index].More = !this.show[index].More;
      },
      left(){
        let scroll = $('#Mvideo').scrollLeft();
        $('#Mvideo').animate({scrollLeft:scroll-290},100)
        this.leftScroll = scroll-290;
      },
      right(){
        let scroll = $('#Mvideo').scrollLeft();
        $('#Mvideo').animate({scrollLeft:scroll+290},100);
        this.leftScroll = scroll+290;
      },
      top(){
        let scroll = $('#Mvideo').scrollTop();
        $('#Mvideo').animate({scrollTop:scroll-156},100)
      },
      bottom(){
        let scroll = $('#Mvideo').scrollTop();
        $('#Mvideo').animate({scrollTop:scroll+156},100)
        console.log($('#Mvideo').animate({scrollTop:scroll+156},100))
      },
      streamingConfig(){
        var arr = JSON.stringify(this.subscribeUser);
        arr = JSON.parse(arr);
        if(arr.length <=16){
          switch (arr.length){
            case 1 :
              this.videoWidth = (this.screenHeight - 45) / 9 * 16;
              this.videoHeight = this.screenHeight - 45;
              break;
            case 2 :
              this.jsvideoMainWidth(4);
              break;
            case 3 :
              this.jsvideoMainWidth(4);
              break;
            case 4 :
              this.jsvideoMainWidth(4);
              break;
            case 5 :
              this.jsvideoMainWidth(5);
              break;
            case 6 :
              this.jsvideoMainWidth(5);
              break;
            case 7 :
              this.jsvideoMainWidth(7);
              break;
            case 8 :
              this.jsvideoMainWidth(7);
              break;
            case 9 :
              this.jsvideoMainWidth(7);
              break;
            case 10 :
              this.jsvideoMainWidth(10);
              break;
            case 11 :
              this.jsvideoMainWidth(10);
              break;
            case 12 :
              this.jsvideoMainWidth(10);
              break;
            case 13 :
              this.jsvideoMainWidth(10);
              break;
            case 14 :
              this.jsvideoMainWidth(10);
              break;
            case 15 :
              this.jsvideoMainWidth(10);
              break;
            case 16 :
              this.jsvideoMainWidth(10);
              break;
          }
        }
      },
      jsvideoMainWidth(length){
        let column = length == 4 ? 2 : length == 5 || length == 7 ? 3 : length == 10 || length == 13 ? 4 : 0;
        if((this.screenWidth / 16 * 9) < this.screenHeight || this.attendeesDomShow){
          // console.log("kuan")
          let width = (this.screenWidth - 45) / column;
          if(this.attendeesDomShow) width = (this.screenWidth - 45  - 310) / column;
          this.videoWidth = width;
          this.videoHeight = this.videoWidth / 16 * 9;
        }else{
          // console.log("gao")
          let height = (this.screenHeight - 85 - (column*5)) / column;
          this.videoWidth = height / 9 * 16;
          this.videoHeight = height;
        }
      },
    }
  }
</script>

<style scoped lang="less">
  @keyframes morefadeIn {
    0% { opacity: 0;height: 0px;overflow: hidden;}
    100% { opacity: 1;height:auto;overflow: hidden;}
  }
  .morefade-enter-active {
    opacity: 1;
    animation: morefadeIn .3s;
  }
  .morefade-leave-active {
    opacity: 1;
    animation: morefadeIn .3s reverse;
  }

  .subscribeVideo-main{
    width: 100%;
    height: 100%;
    padding:3px 0;
  }
  .subscribeVideo-main .Mvideo{
    width: calc(100% - 10px - 30px - 30px);
    height: 100%;
    display: -webkit-box;
    overflow-x: auto;
    /*适应苹果*/
    -webkit-overflow-scrolling:touch;
    padding: 0 5px;
    float: left;
  }
  .subscribeVideo-main .Mvideo::-webkit-scrollbar , .subscribeVideo-main-sidebar .Mvideo::-webkit-scrollbar{
    display: none;
  }
  .subscribeVideo-main .Mvideo,.subscribeVideo-main-sidebar .Mvideo{
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .subscribeVideo-main,.subscribeVideo-main-sidebar,.gallery-view{ 
    background: #1B242C;
    .videos:hover{
      .set-box{opacity: 1;transition-duration: 0s;}
    }
    .videos{
      height: 160.3125px;
      width: 285px;
      margin-right: 5px;
      // border-radius: 4px;
      overflow: hidden;
      // background-image: url('../assets/videoHead.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -o-background-size: cover;
      background-position: center 0;
      background-color: #527291;
      position: relative;
    }
    button{
      position: absolute;
      z-index: 222;
    }
    .videos-bg-name{
      position: absolute;
      left: 50%;
      top: 32%;
      transform: translate(-50%);
      font-size: 18px;
      color:#fff;
      max-width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .user-name{
      height:22px;
      // line-height: 22px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 19px;
      padding:3px 10px;
      color:#fff;
      position: absolute;
      bottom:3px;
      left:3px;
      z-index: 222;
      font-size: 14px;
      display: flex;
      align-items: center;
      img{
        display: inline-block;
      }
      span{
        display: block;
        max-width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .live-status{
      position: absolute;
      right:3px;
      bottom:0px;
      z-index: 222;
      img{width: 40px;margin-top:13px;}
      cursor: pointer;
    }
    .set-box{
      opacity: 0;
      transition: opacity .5s;
      position: absolute;
      right:3px;
      top:3px;
      z-index: 222;
      display: flex;
      align-items: center;
    }
    .user-set{
      max-width: 160px;
      height:27px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 19px;
      display: flex;
      align-items: center;
      .icon-img{
        margin:3px 8px;
        cursor: pointer;
      }
    }
    .more{
      height: 20px;
      line-height: 21px;
      // background: rgba(0, 0, 0, 0.4);
      border-radius: 19px;
      // padding:3px 12px;
      // margin-left: 10px;
      cursor: pointer;
    }
    .more-dialog{
      position: absolute;
      width: 90px;
      max-height: 185px;
      min-height: 30px;
      background: rgba(28, 44, 59, .8);
      top:33px;
      right: 3px;
      z-index: 22222;
      border-radius: 8px;
      p{
        font-size: 12px;
        color: #fff;
        margin: 4px 0;
        cursor: pointer;
        margin-left: 15px;
      }
      p:hover{
        color:#0099ff;
      }
      .popper__arrow {
          position: absolute;
          width: 0;
          height: 0;
          border: 6px solid;
          border-color: transparent transparent rgba(28, 44, 59, .8);
          top: -10px;
          right: 12%;
          border-radius: 5px;
          z-index: 22222;
      }
    }
  }
  .subscribeVideo-main .model{
    /*background-color: #0099ff;*/
    width: 30px;
    height: 100%;
    float: left;
    text-align: center;
    cursor: pointer;
  }
  .subscribeVideo-main .model img{
    width: 10px;
    margin-top: 65px;
  }
  .subscribeVideo-main-sidebar .Mvideo{
    width: 290px;
    height:calc(100% - 70px);
    overflow-y: auto;
    overflow: hidden;
    .videos{
      border-radius: 4px;
      overflow: hidden;
      margin:0 auto 5px;
    }
  }
  .subscribeVideo-main-sidebar{
    height:calc(100% - 45px);
    position: absolute;
    right:0;
  }
  .subscribeVideo-main-sidebar .model{
    width: 100%;
    height: 30px;
    text-align: center;
    img{
      transform:rotate(90deg)
    }
  }
  .subscribeVideo-main-sidebar .bottom{
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .gallery-view{
    width:100%;
    height:calc(100% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    .videos-box{
      // height:calc(100% - 10px);
      display: flex;
      justify-content: center;
      align-content:space-around;
      flex-wrap: wrap;
      margin: 0 auto;
    }
    .videos{
      box-sizing: border-box;
    }
  }
</style>
