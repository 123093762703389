import Vue from 'vue'
import Vuex from 'vuex'
import actions from "./action";
Vue.use(Vuex)

const state = { 
  browserCompatibility:true,  // 浏览器兼容性
}

const mutations = {
  browserCompatibility(state, v) {
      state.browserCompatibility = v;
  },
}
export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
  }
})
