import md5 from 'js-md5'
import { Base64 } from 'js-base64'

const key_sort = 1

const signKey = 'HjYdRqLPE7sDXJtyU3MjU40n8xx7vWGfcyY31tHjYd0n='
/**
 * @desc 生成API验签所需要签名的公共函数(需要npm组件:'js-md5' 'js-base64')
 * @param {Object} params
 * @return string
 */
const debug_info = {}
const makeSign = function(params) {
  // console.log(params)
  const osKey = Base64.encode(signKey)
  // debug_info['osKey'] = osKey

  if (isEmptyObject(params)) {
    params = {}
  }
  if (key_sort === 1) {
    params = jsonKeySort(params)
  }
  let arg = ''
  // 将所有变量值作为字串接起来
  for (var key of Object.keys(params)) {
    if (key === 'sign') continue
    else arg += params[key]
  }
  // debug_info['arg'] = arg
  // debug_info['md5Sign'] = md5(arg + osKey)
  // console.log('sign_check::  ', debug_info)
  // 接上验签密钥后,用MD5加密返回
  return md5(arg + osKey)
}

/**
 * @desc 将JSON对像按KEY排序
 * @param {Object} jsonObj
 * @return object
 */
function jsonKeySort(jsonObj) {
  if (isEmptyObject(jsonObj)) {
    jsonObj = {}
    return jsonObj
  }
  const arr = []
  for (var key of Object.keys(jsonObj)) {
    arr.push(key)
  }
  arr.sort()
  debug_info['keys'] = arr.join(',')
  const return_obj = {}
  for (var i in arr) {
    return_obj[arr[i]] = jsonObj[arr[i]]
  }
  return return_obj
}

const isEmptyObject = function(obj) {
  for (var item in obj) {
    return false
  }
  return true
}
/* eslint-disable */
export { makeSign, isEmptyObject };

