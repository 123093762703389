<template>
  <div class="error">
    <div class="main">
        <img src="../assets/error.png">
        <div class="txt">{{message}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'error',
  data(){
    return{
      message:"错误的链接！"
    }
  },
  created(){
    let index = this.$route.fullPath.lastIndexOf("\/");
    let token = this.$route.fullPath.substring(index + 1,this.$route.fullPath.length);
    console.log(token,'参数',this.$route);
    if(token){
      // this.getMeetInfo(token);
    }
  },
  methods:{
    getQueryVariable(variable)
    {
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
    },

    // getMeetInfo(token){
    //   var that = this;
    //   var uid = this.randomNum(1,1000);
    //   $.post('/api/v1/getMeetingInfo',{token:token,uid:uid},function(res){
    //     console.log(res);
    //     if(res.status == 200){
    //       window.localStorage.setItem('meetinfo',JSON.stringify(res.data.meetinfo));
    //       window.localStorage.setItem('token',JSON.stringify(res.data.token));
    //       window.localStorage.setItem('uid',JSON.stringify(uid));
    //       that.$router.push({path:"/host"});
    //       return;
    //       if(res.data.meetinfo.role == 'host'){
    //         that.$router.push({path:"/host"});
    //       }else if(res.data.meetinfo.role == "watch"){
    //         that.$router.push({path:"/watch"});
    //       }else if(res.data.meetinfo.role == 'guest'){
    //         that.$router.push({path:"/guest"});
    //       }
    //     }else{
    //       that.message = "无效参数！"
    //     }
    //   })
    // },

    /**
     * 生成随机数
     */
    randomNum(minNum,maxNum){
      switch(arguments.length){
        case 1:
            return parseInt(Math.random()*minNum+1,10);
        break;
        case 2:
            return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10);
        break;
            default:
                return 0;
            break;
      }
    },

  },
  components: {
  }
}
</script>

<style scoped>
  .error .main{
    width: 500px;
    height: 600px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .error .main img{
    width: 100%;
  }
  .error .main .txt{
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    color: #0099ff;
  }
</style>
