const liveUserConfig = {

    /**
     * 不同数量用户的默认配置
     */
    UserConfig_1:[
        {
            x: 0,
            y: 0,
            width: 1280,
            height: 720,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    UserConfig_2:[
        {
            x: 4,
            y: 180,
            width: 632,
            height: 355,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 645,
            y: 180,
            width: 632,
            height: 355,
            zOrder: 1,
            alpha: 1.0,
        },
    ],

    UserConfig_3:[
        {
            x: 320,
            y: 0,
            width:  632,
            height: 355,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 4,
            y: 365,
            width:  632,
            height: 355,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 645,
            y: 365,
            width:  632,
            height: 355,
            zOrder: 0,
            alpha: 1.0,
        },
    ],

    UserConfig_4:[
        {
            x: 4,
            y: 0,
            width:  632,
            height: 355,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 645,
            y: 0,
            width:  632,
            height: 355,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 4,
            y: 365,
            width:  632,
            height: 355,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 645,
            y: 365,
            width:  632,
            height: 355,
            zOrder: 0,
            alpha: 1.0,
        },
    ],

    UserConfig_5:[
        {
            x: 0,
            y: 120,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 430,
            y: 120,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 860,
            y: 120,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 213,
            y: 365,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 643,
            y: 365,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
    ],

    UserConfig_6:[
        {
            x: 0,
            y: 120,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 430,
            y: 120,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 860,
            y: 120,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 365,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 430,
            y: 365,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 860,
            y: 365,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
    ],

    UserConfig_7:[
        {
            x: 0,
            y: 0,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 430,
            y: 0,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 860,
            y: 0,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 241,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 430,
            y: 241,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 860,
            y: 241,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 430,
            y: 484,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
    ],

    UserConfig_8:[
        {
            x: 0,
            y: 0,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 430,
            y: 0,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 860,
            y: 0,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 241,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 430,
            y: 241,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 860,
            y: 241,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 213,
            y: 484,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 643,
            y: 484,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
    ],

    UserConfig_9:[
        {
            x: 0,
            y: 0,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 430,
            y: 0,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 860,
            y: 0,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 241,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 430,
            y: 241,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 860,
            y: 241,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 484,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 430,
            y: 484,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 860,
            y: 484,
            width:  420,
            height: 236,
            zOrder: 0,
            alpha: 1.0,
        },
    ],
    // 十个以后待测
    UserConfig_10:[
        {
            x: 0,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 323,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 646,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 969,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 323,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 646,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 969,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 323,
            y: 450,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 646,
            y: 450,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    UserConfig_11:[
        {
            x: 0,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 323,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 646,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 969,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 323,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 646,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 969,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 150,
            y: 450,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 473,
            y: 450,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 796,
            y: 450,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    UserConfig_12:[
        {
            x: 0,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 323,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 646,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 969,
            y: 90,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 323,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 646,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 969,
            y: 270,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 450,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 323,
            y: 450,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 646,
            y: 450,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 969,
            y: 450,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    UserConfig_13:[
        {
            x: 0,
            y: 0,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 0,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 0,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 0,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 180,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 180,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 180,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 180,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 360,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 360,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 360,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 360,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 480,
            y: 540,
            width: 313,
            height: 176,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    UserConfig_14:[
        {
            x: 0,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 450,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 450,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    UserConfig_15:[
        {
            x: 0,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 160,
            y: 450,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 480,
            y: 450,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 800,
            y: 450,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    UserConfig_16:[
        {
            x: 0,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 450,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 450,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 450,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 450,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    /**
     * 共享数量用户的默认配置
     */
    shareUserConfig_2:[
        {
            x: 0,
            y: 90,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 270,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    shareUserConfig_3:[
        {
            x: 0,
            y: 90,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    shareUserConfig_4:[
        {
            x: 0,
            y: 90,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 90,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 270,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 450,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    shareUserConfig_5:[
        {
            x: 0,
            y: 90,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 540,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    shareUserConfig_6:[
        {
            x: 0,
            y: 180,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    shareUserConfig_7:[
        {
            x: 0,
            y: 180,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    shareUserConfig_8:[
        {
            x: 0,
            y: 180,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 320,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 0,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 180,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 360,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 960,
            y: 540,
            width: 320,
            height: 180,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    // 八个以后待测
    shareUserConfig_9:[
        {
            x: 0,
            y: 144,
            width: 1024,
            height: 576,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 0,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 256,
            y: 0,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 512,
            y: 0,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 768,
            y: 0,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1024,
            y: 0,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1024,
            y: 144,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1024,
            y: 288,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1024,
            y: 432,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    shareUserConfig_10:[
        {
            x: 0,
            y: 144,
            width: 1024,
            height: 576,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 0,
            y: 0,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 256,
            y: 0,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 512,
            y: 0,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 768,
            y: 0,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1024,
            y: 0,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1024,
            y: 144,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1024,
            y: 288,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1024,
            y: 432,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1024,
            y: 576,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    
    // 共享只显示两人  布局方式
    live_share_view_0:[
        {
            x: 27,
            y: 90,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 997,
            y: 270,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    live_share_view_1:[
        {
            x: 293,
            y: 90,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 27,
            y: 270,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    live_share_view_2:[
        {
            x: 160,
            y: 168,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 480,
            y: 14,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    live_share_view_3:[
        {
            x: 160,
            y: 12,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 480,
            y: 562,
            width: 256,
            height: 144,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    live_default_view_2:[
        {
            x: 160,
            y: 20,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_3:[
        {
            x: 160,
            y: 20,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 395,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_4:[
        {
            x: 160,
            y: 20,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_5:[
        {
            x: 160,
            y: 20,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 150,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 395,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 885,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_6:[
        {
            x: 160,
            y: 20,
            width: 960,
            height: 540,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 565,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    live_default_view_7:[
        {
            x: 256,
            y: 0,
            width: 768,
            height: 432,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_8:[
        {
            x: 256,
            y: 0,
            width: 768,
            height: 432,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 395,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_9:[
        {
            x: 256,
            y: 0,
            width: 768,
            height: 432,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_10:[
        {
            x: 256,
            y: 0,
            width: 768,
            height: 432,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 150,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 395,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 885,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_11:[
        {
            x: 256,
            y: 0,
            width: 768,
            height: 432,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 437,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 577,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],

    live_default_view_12:[
        {
            x: 376,
            y: 0,
            width: 528,
            height: 297,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_13:[
        {
            x: 376,
            y: 0,
            width: 528,
            height: 297,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 395,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_14:[
        {
            x: 376,
            y: 0,
            width: 528,
            height: 297,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_15:[
        {
            x: 376,
            y: 0,
            width: 528,
            height: 297,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 150,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 395,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 640,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 885,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
    live_default_view_16:[
        {
            x: 376,
            y: 0,
            width: 528,
            height: 297,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 302,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 442,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 30,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 275,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 520,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 765,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        },
        {
            x: 1010,
            y: 717,
            width: 240,
            height: 135,
            zOrder: 0,
            alpha: 1.0,
        }
    ],
}

export default liveUserConfig;