<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default{
  data(){
    return{
      browser:"",
    }
  },
  created(){
    this.getBrowerInfo();
  },
  methods:{
    getBrowerInfo() {
      let userAgent = window.navigator.userAgent.toLowerCase()
      let browserType = ''
      let browserVersion = ''
      let isIE = userAgent.match(/msie/) != null || userAgent.match(/trident/) != null
      // 浏览器类型-IE
      if (userAgent.match(/msie/) != null || userAgent.match(/trident/) != null) {
        browserType = 'IE'
        browserVersion = this.getIeVersion()
      }
      // 欧朋
      else if (window.opera || (userAgent.indexOf("opr") > 0)) {
        browserType = '欧朋'
        browserVersion =  this.getOperaVersion(userAgent)
      }
      // UC
      else if (userAgent.indexOf("ubrowser") > 0) {
        browserType = 'UC'
        browserVersion =  userAgent.match(/ubrowser\/([\d.]+)/)[1]
      }
      // 百度
      else if (userAgent.indexOf("bidubrowser") > 0) {
        browserType = '百度'
        browserVersion =  userAgent.match(/bidubrowser\/([\d.]+)/)[1]
      }
      // 搜狗
      else if (userAgent.indexOf("metasr") > 0 || userAgent.indexOf( "se 2.x" ) > 0 ) {
        browserType = '搜狗'
        // browserVersion =  userAgent.match(/metasr\/([\d.]+)/)[1]
      }
      // QQ
      else if (userAgent.indexOf("tencenttraveler") > 0) {
        browserType = 'QQ'
        browserVersion =  userAgent.match(/tencenttraveler\/([\d.]+)/)[1]
      }
      // QQ
      else if (userAgent.indexOf("qqbrowser") > 0) {
        browserType = 'QQ'
        browserVersion = userAgent.match(/qqbrowser\/([\d.]+)/)[1]
      }
      // 遨游
      else if (userAgent.indexOf("maxthon") > 0) {
        browserType = 'maxthon'
        browserVersion =  userAgent.match(/maxthon\/([\d.]+)/)[1]
      }
      // 火狐
      else if (userAgent.indexOf("firefox") > 0) {
        browserType = 'Firefox'
        browserVersion =  userAgent.match(/firefox\/([\d.]+)/)[1]
      }
      // edge
      else if (userAgent.indexOf("edge") > 0) {
        browserType = 'Edge'
        browserVersion =  userAgent.match(/edge\/([\d.]+)/)[1]
      }
      // 谷歌/360
      else if (userAgent.indexOf("chrome") > 0) {
        if (this.validate360('type', 'application/x360mmplugin')) {
          browserType = '360'
          // browserVersion =  userAgent.match(/chrome\/([\d.]+)/)[1]
        } else {
          browserType = 'Chrome'
          browserVersion =  userAgent.match(/chrome\/([\d.]+)/)[1]
        }
      }
      // 苹果
      else if (userAgent.indexOf("Safari") > -1) {
        browserType = 'Safari'
        browserVersion =  userAgent.match(/version\/([\d.]+)/)[1]
      }
      
      this.browser = {
        name:browserType,
        version:parseInt(browserVersion),
      }
      if(this.browser.name == 'Chrome' && this.browser.version >=58){
        this.$store.commit("browserCompatibility",true);
      }
      else if(this.browser.name == 'Firefox' && this.browser.version >=56){
        this.$store.commit("browserCompatibility",true);
      }
      else if(this.browser.name == "Edge" && this.browser.version >=80){
        this.$store.commit("browserCompatibility",true);
      }else{
        this.$store.commit("browserCompatibility",false);
      }
      
    },
    /*
    * 获取IE浏览器版本
    */
    getIeVersion() {
      let IEMode = document.documentMode
        let rMsie  = /(msie\s|trident.*rv:)([\w.]+)/;
        let ma = window.navigator.userAgent.toLowerCase()
        let  match  = rMsie.exec(ma);
        try {
          return match[2];
        } catch (e) {
          return IEMode;
        }
    },
    /*
    * 获取oper浏览器版本
    */ 
    getOperaVersion(userAgent) {
      try {
          if (window.opera) {
            return userAgent.match(/opera.([\d.]+)/)[1];
          } else if (userAgent.indexOf("opr") > 0) {
            return userAgent.match(/opr\/([\d.]+)/)[1];
          }
        } catch (e) {
          return 0;
        }
    },
    /*
    * 判断是否为360浏览器
    */ 
    validate360(option, value) {
        let mimeTypes = window.navigator.mimeTypes
        for (let mt in mimeTypes) {
          // console.log(mimeTypes[mt][option])
          if (mimeTypes[mt][option] == value) {
            return true;
          }
        }
      return false;
    },
  }
}
</script>


<style>

/* 禁止选中页面文字 */

*{ 
 -webkit-touch-callout:none; /*系统默认菜单被禁用*/
 -webkit-user-select:none; /*webkit浏览器*/
 -khtml-user-select:none; /*早期浏览器*/
 -moz-user-select:none;/*火狐*/
 -ms-user-select:none; /*IE10*/
 user-select:none; 
 font-family: "PingFangSC-Regular", "sans-serif","Microsoft YaHei", "微软雅黑", "STXihei" , "华文细黑" , "Heiti" , "黑体" ,  "Helvetica", "Tahoma", "Arial" , "SimSun", "宋体";
} 
input{ 
 -webkit-user-select:auto; /*webkit浏览器*/ 
}
textarea{
 -webkit-user-select:auto; /*webkit浏览器*/
}

#app {
  width:100%;
  height: 100%;
}

html,body{
  width: 100%;
  height: 100%;
  margin: 0;
  left: 0;
  top: 0;
}

</style>
