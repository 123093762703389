// CDN 推流参数设置。
/**
 * 0：推流成功
    1：推流失败
    2：参数错误。如果你在调用 addPublishStreamUrl 前没有调用 setLiveTranscoding 配置 LiveTranscoding，SDK 会返回该错误
    10：推流超时未成功
    19：推流地址已经在推流
    130：推流已加密不能推流
 */
const LiveTranscoding = {
     /** width of canvas  画布宽度*/
     width: 1280,
     /** height of canvas 画布高度*/
     height: 720,
     /** kbps value, for 1-1 mapping pls look at https://docs.agora.io/cn/Interactive%20Broadcast/API%20Reference/cpp/structagora_1_1rtc_1_1_video_encoder_configuration.html */
     /** 用于旁路直播的输出视频的码率，单位为 Kbps，默认值为 400 Kbps。用户可以根据码率参考表中的码率值进行设置；如果设置的码率超出合理范围， Agora 服务器会在合理区间内自动调整码率值。*/
     videoBitrate: 1000,
     /** fps, default 15 用于旁路直播的输出视频的帧率，单位为帧每秒，取值范围为 (0, 30]， 默认值为 15 fps。
         服务器会将将高于 30 的帧率设置改为 30。*/
     videoFrameRate: 30,
     /** 用于旁路直播的输出视频的 GOP，单位为帧。默认值为 30 帧 */
     videoGop: 30,
     /**
      * RGB hex value. Value only, do not include a #. For example, 0xC0C0C0.
      * number color = (A & 0xff) << 24 | (R & 0xff) << 16 | (G & 0xff) << 8 | (B & 0xff)
      */
     /** 用于旁路直播的输出视频的编解码规格  100：（默认）High 级别的视频编码规格，一般用于广播及视频碟片存储，高清电视 */
     videoCodecProfile: 100,
     userConfigExtraInfo: "",
     /** 直播视频上的背景 */
     backgroundColor: 0x000000,
   //   backgroundImage:{
   //       width:1280,
   //       height: 720,
   //       url: null,
   //       x:0,
   //       y:0,
   //   },
     /** The number of users in the live broadcast */
     /** 获取旁路直播中的用户人数 */
     userCount: 1,
     /** 用于旁路直播的输出音频的采样率 */
     audioSampleRate: 32000,
     /** 用于旁路直播的输出音频的声道数，取值范围为 [1, 5] 中的整型，默认值为 1。建议取 1 或 2，其余三个选项需要特殊播放器支持：
      1：单声道 2：双声道 3：三声道 4：四声道 5：五声道 */
     audioChannels: 1,
     audioBitrate: 48,
     /** transcodingusers array */
     transcodingUsers: [],
     watermark: {   // 水印配置
       url: "https://images.innocomn.com/fe261202105171000211194.png",
       x: 20,
       y:20,
       width: 80,
       height: 80
     }
  };
  
  export default LiveTranscoding