<template>
  <div class="error">
    <div class="main">
        <img src="../assets/error.png">
        <div class="txt">{{message}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'error',
  data(){
    return{
      message:"该链接不支持移动设备进入！"
    }
  },
}
</script>

<style>
  .error .main{
    margin: 0 auto;
    width: 80%;
    height: 60%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .error .main img{
    width: 100%;
  }
  .error .main .txt{
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    color: #0099ff;
  }
</style>
